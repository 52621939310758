// Use this import if you want to use "env.js" file
// Or just specify it directly like this:

import { DEFAULT_HEADERS, HEADERS } from "apisauce"

const {
  PARSE_SERVER_URL,
  PARSE_APPLICATION_ID,
  PARSE_REST_KEY,
  REVENUECAT_API_KEY,
  REVENUECAT_API_URL,
} = require("../../config/env")

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number

  /**
   * Headers to be added to the calls
   */
  headers: HEADERS
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: "",
  timeout: 10000,
  headers: DEFAULT_HEADERS,
}

export const DEFAULT_PARSE_API_CONFIG: ApiConfig = {
  url: PARSE_SERVER_URL,
  timeout: 10000,
  headers: {
    "X-Parse-Application-Id": PARSE_APPLICATION_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  },
}

export const DEFAULT_RC_API_CONFIG: ApiConfig = {
  url: REVENUECAT_API_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "X-Platform": "stripe",
    "Content-Type": "application/json",
    Authorization: "Bearer " + REVENUECAT_API_KEY,
  },
}
