import React from "react"
import {
  FlatList,
  Image,
  ImageStyle,
  Platform,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, typography } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"
import { useStores } from "../../models"
import { Icon } from "../icon/icon"
import { MyModal } from "../my-modal/my-modal"
import { RadioStation } from "../../models/radio-station/radio-station"
import { FLEX1 } from "../../utils/style"
import { View } from "@dripsy/core"

const WHITE_FIELD: ViewStyle = {
  backgroundColor: palette.white,
  flexDirection: "row",
  flexWrap: "wrap",
  margin: spacing[5],
  borderRadius: spacing[4],
  marginHorizontal: Platform.OS === "web" ? "auto" : null,
}

const RADIO_DISPLAY: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  marginLeft: 35,
}

const RADIO_NAME: TextStyle = {
  textAlign: "center",
  fontSize: Platform.OS === "web" ? typography.size[3] : typography.size[4],
  color: palette.alphawhite,
  fontFamily: typography.primary,
  fontWeight: "bold",
  marginRight: spacing[3],
}

const BLOCK: ViewStyle = {
  width: "50%",
  paddingHorizontal: spacing[3],
  paddingVertical: spacing[4],
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
}

const BLOCK_NAME: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 16,
  color: palette.lightGrey,
  textAlign: "center",
}

const LOGO: ImageStyle = {
  width: 120,
  height: 120,
  resizeMode: "contain",
}

export interface ProgramModalProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

const Item = ({ item, onPress }) => (
  <TouchableOpacity onPress={onPress} style={BLOCK}>
    <Image source={{ uri: item.logo_url }} style={LOGO} />
    <Text style={BLOCK_NAME} numberOfLines={2}>
      {`${item.name} 
      `}
    </Text>
  </TouchableOpacity>
)

export interface RadioPickerModalProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle[]
  onChoose: any
}

/**
 * Describe your component here
 */
export const RadioPickerModal = observer(function RadioPickerModal(props: RadioPickerModalProps) {
  const { style, onChoose } = props
  const [visible, setVisible] = React.useState(false)

  const { radioStore } = useStores()

  const showModal = () => {
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  const choose = (name) => {
    hideModal()
    onChoose(name)
  }

  const renderItem = ({ item }) => {
    return <Item item={item} onPress={() => choose(item.name)} />
  }

  return (
    <View style={style}>
      <MyModal visible={visible} dismiss={hideModal}>
        {/* <View style={WHITE_FIELD}> */}
        <View sx={{ flexDirection: "row", flexWrap: "wrap" }}>
          <FlatList
            keyExtractor={(radio: any) => radio.index}
            data={radioStore.radioStations.slice()}
            renderItem={renderItem}
            numColumns={2}
          />
        </View>
        {/* </View> */}
      </MyModal>
      <TouchableOpacity onPress={showModal}>
        <View style={RADIO_DISPLAY}>
          <Text style={RADIO_NAME} numberOfLines={1}>
            {radioStore.currentRadioStation.name}
          </Text>
          <Icon name="chevron-down" color={palette.lessalphawhite} />
        </View>
      </TouchableOpacity>
    </View>
  )
})
