import Bugsnag from "@bugsnag/react-native"
import { useEffect, useRef, useState } from "react"
import ReconnectingWebSocket from "reconnecting-websocket"
const { PARSE_APPLICATION_ID, PARSE_SERVER_WS_URL, PARSE_CLIENT_KEY } = require("../config/env")

interface query {
  className: string
  where: any
  fields?: string
}

const useWebSocket = (onCreate, onError, sessiontoken, id, query?: query) => {
  const ws = useRef(null)
  const [error, setError] = useState(false)
  const [state, setState] = useState("pending")

  useEffect(() => {
    console.log("state for ", query, " is ", state)
  }, [state])

  const subscribe = (ws: ReconnectingWebSocket, query: query) => {
    const fields = query.fields?.split(",") || undefined
    const subscribeRequest = {
      op: "subscribe",
      requestId: id,
      query: {
        className: query.className,
        where: query.where,
        fields,
      },
      sessionToken: sessiontoken,
    }
    try {
      ws.send(JSON.stringify(subscribeRequest))
    } catch (e) {
      Bugsnag.notify("Tried to subscribe", e)
    }
  }

  const unsubscribe = (ws: ReconnectingWebSocket) => {
    const unsubscribeRequest = {
      op: "unsubscribe",
      requestId: id,
    }
    try {
      ws.send(JSON.stringify(unsubscribeRequest))
    } catch (e) {
      Bugsnag.notify("Tried to unsubscribe", e)
    }
  }

  const createWebSocketClass = (options) => {
    return class extends WebSocket {
      constructor(url, protocols) {
        super(url, protocols, options)
      }
    }
  }

  const connect = () => {
    const socket = new ReconnectingWebSocket(PARSE_SERVER_WS_URL, null, {
      WebSocket: createWebSocketClass({
        headers: {
          "X-Parse-Application-Id": PARSE_APPLICATION_ID,
          "X-Parse-Client-Key": PARSE_CLIENT_KEY,
        },
      }),
      startClosed: true,
      connectionTimeout: 10000,
    })

    socket.onopen = () => {
      const connectRequest = {
        op: "connect",
        applicationId: PARSE_APPLICATION_ID,
        sessionToken: sessiontoken,
      }
      socket.send(JSON.stringify(connectRequest))
      setError(false)
    }

    socket.onmessage = (event) => {
      // a message was received
      let data = event.data
      if (typeof data === "string") {
        data = JSON.parse(data)
      }

      switch (data.op) {
        case "connected":
          subscribe(socket, query)
          setState("subscribing")
          break
        case "subscribed":
          console.log("subscription opened", JSON.stringify(data), query)
          setState("subscribed")
          break
        case "error":
          unsubscribe(socket)
          setState("unsusbscribing")

          break
        case "unsubscribed":
          console.log("subscription ended: ", JSON.stringify(data))
          setState("unsusbscribed")

          socket.close()
          // We have already deleted subscription in unsubscribe(), do nothing here
          break
        case "create":
          // console.log("created: ", JSON.stringify(data))
          onCreate(data.object)
          // eslint-disable-next-line no-case-declarations
          break
        default: {
          // do nothing
          // console.log(data)
        }
      }
    }

    socket.onclose = (e) => {
      console.log("Socket for query ", query, "is closed. ", e.reason)
      setState("closed")
    }

    socket.onerror = (err) => {
      console.error(
        "Socket for query ",
        query,
        " encountered an error: ",
        err.message,
        "Closing socket",
      )
      setState("closed")
    }
    ws.current = socket
  }

  useEffect(() => {
    connect()
    ws.current.reconnect()
    return () => {
      ws.current.close()
    }
  }, [])

  return { ws, state }
}

export default useWebSocket
