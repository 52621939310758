import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { RadioStationModel } from "../radio-station/radio-station"
import { TimeTableModel } from "../time-table/time-table"

/**
 * Model description here for TypeScript hints.
 */
export const RadioStationDetailModel = types.model("RadioStationDetail").props({
  objectId: types.identifier,
  title: types.string,
  time: types.string,
  info: types.string,
  timetable: types.array(TimeTableModel),
  radio_id: types.reference(RadioStationModel),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type RadioStationDetailType = Instance<typeof RadioStationDetailModel>
export interface RadioStationDetail extends RadioStationDetailType {}
type RadioStationDetailSnapshotType = SnapshotOut<typeof RadioStationDetailModel>
export interface RadioStationDetailSnapshot extends RadioStationDetailSnapshotType {}
