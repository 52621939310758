/* eslint-disable generator-star-spacing */
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { SubscriptionModel } from "../subscription/subscription"
import data from "./subscriptions.js"

/**
 * Model description here for TypeScript hints.
 */

export const SubscriptionStoreModel = types
  .model("SubscriptionStore")
  .props({
    subscriptions: types.optional(types.array(SubscriptionModel), data),
    state: types.enumeration("State", ["pending", "done", "error"]),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

type SubscriptionStoreType = Instance<typeof SubscriptionStoreModel>
export interface SubscriptionStore extends SubscriptionStoreType {}
type SubscriptionStoreSnapshotType = SnapshotOut<typeof SubscriptionStoreModel>
export interface SubscriptionStoreSnapshot extends SubscriptionStoreSnapshotType {}
export const createSubscriptionStoreDefaultModel = () => types.optional(SubscriptionStoreModel, {})
