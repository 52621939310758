/* eslint-disable generator-star-spacing */
import { destroy, flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { ParseApi } from "../../services/api/parse-api"
import { withEnvironment } from "../extensions/with-environment"
import { GetGeneralResults } from "../../services/api"
import { EventModel } from "../event/event"
import { EventVenueModel } from "../event-venue/event-venue"
import format from "date-fns/format"
import { withRootStore } from "../extensions/with-root-store"

const { HERE_API_URL, HERE_API_KEY } = require("../../config/env")

/**
 * Model description here for TypeScript hints.
 */
export const EventStoreModel = types
  .model("EventStore")
  .props({
    events: types.optional(types.array(EventModel), []),
    places: types.optional(types.array(EventVenueModel), []),
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    currentEvent: types.maybe(types.reference(EventModel)),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get futureEvents() {
      return self.events.filter((event) => event.startDate >= new Date())
    },
    get pastEvents() {
      return self.events.filter((event) => event.startDate < new Date())
    },
    // get currentEvent() {
    //   if (self.currentEvent) {
    //     return self.currentEvent
    //   } else {
    //     return null
    //   }
    // },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    load: flow(function* load() {
      console.log("Loading Events")
      self.state = "pending"
      const parseApi = new ParseApi()
      try {
        let result: GetGeneralResults = yield parseApi.getAllOfClass("Place")
        self.places = result.data.results.map((place) => {
          return {
            objectId: place.objectId,
            name: place.name,
            street: place.street,
            street_nr: place.street_nr,
            city: place.city,
            zipcode: place.zipcode,
            country: place.country || undefined,
            location: place.location || undefined,
            link: (place || {}).links || undefined,
            image: place.image || undefined,
          }
        })

        result = yield parseApi.getAllOfClass("Event")
        if (result.kind === "ok") {
          self.events = result.data.results.map((event) => {
            // hacking together the cloudinary transofmrtaion
            const thumb =
              event.image.substr(0, event.image.indexOf("upload/") + 7) +
              "w_200,c_scale/" +
              event.image.substr(event.image.indexOf("upload/") + 7)
            const end =
              typeof event.end_date === "undefined"
                ? null
                : new Date(((event || {}).end_date || {}).iso)
            return {
              objectId: event.objectId as string,
              name: event.name,
              image: event.image,
              thumb: thumb,
              startDate: new Date(((event || {}).start_date || {}).iso),
              endDate: end,
              data: event.data || [],
              desc: event.desc || "",
              isOnline: event.isOnline || false,
              isPublic: event.isPublic || false,
              links: event.links || [],
              tags: event.tags || [],
              place_id: ((event || {}).place_id || {}).objectId || undefined,
              user_id: ((event || {}).user_id || {}).objectId || undefined,
            }
          })
        }
        self.state = "done"
      } catch (error) {
        self.state = "error"
        console.log(`Failed to retrieve Events from Server, with error code: ${error}`)
      }
    }),
    setCurrentEvent(id) {
      self.currentEvent = id
    },
    afterCreate() {
      this.load()
    },
    getSectionedEvents(tagFilter?: any[]) {
      let prepEvents = []
      if (tagFilter && tagFilter.length > 0) {
        self.events.forEach((event) => {
          tagFilter.forEach((tag) => {
            event.tags.forEach((eventTag) => {
              if (tag.id === eventTag.id && !prepEvents.includes(event)) {
                prepEvents = [...prepEvents, event]
              }
            })
          })
        })
      } else {
        prepEvents = self.events
      }

      let events = []
      for (var i = 0; i < prepEvents.length; i++) {
        var obj = prepEvents[i]
        var date = new Date(obj.startDate)
        // only events that are today or in the future
        if (date.valueOf() > new Date(new Date(Date.now() - 86400000)).valueOf()) {
          // We try to do very compilicated stuff here
          if (tagFilter) {
            if (
              tagFilter.every((tag) => {
                obj.tags.every((eventTag) => eventTag.id === tag.id)
              })
            ) {
            }
          }
          const monthAndYear = format(date, "LLL yyyy")
          const section = events.findIndex((section) => section.title === monthAndYear)
          if (section > -1) {
            events[section].data.push(obj) // already have a list- append to it
          } else {
            events.push({ title: monthAndYear, date: date, data: [obj] }) // no list for this month yet - create a new one
          }
        }
      }

      events = events.map((section) => {
        return {
          title: section.title,
          data: section.data
            .slice()
            .sort((a, b) => (a.startDate.valueOf() > b.startDate.valueOf() ? -1 : 1))
            .slice()
            .reverse(),
          date: section.date,
        }
      })

      events = events
        .slice()
        .sort((a, b) => (a.date.valueOf() > b.date.valueOf() ? -1 : 1))
        .slice()
        .reverse()
      return events
    },
    getEventByUserId(userId) {
      let events = self.events.filter((event) => event.user_id === userId)
      events = events
        .slice()
        .sort((a, b) => (a.startDate.valueOf() > b.startDate.valueOf() ? -1 : 1))
        .slice()
        .reverse()
      return events
    },
    getPastEventByUserId(userId) {
      let events = self.events.filter(
        (event) =>
          event.user_id === userId &&
          event.startDate.valueOf() < new Date().valueOf() - 1000 * 60 * 60 * 25,
      )
      events = events
        .slice()
        .sort((a, b) => (a.startDate.valueOf() > b.startDate.valueOf() ? -1 : 1))
        .slice()
        .reverse()
      return events
    },
    getFutureEventByUserId(userId) {
      let events = self.events.filter(
        (event) =>
          event.user_id === userId &&
          event.startDate.valueOf() > new Date().valueOf() - 1000 * 60 * 60 * 24,
      )
      events = events
        .slice()
        .sort((a, b) => (a.startDate.valueOf() > b.startDate.valueOf() ? -1 : 1))
        .slice()
        .reverse()
      return events
    },
    getPlaces() {
      let places = self.places
        .slice()
        .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
        .slice()
      return places
    },
    deleteEvent: flow(function* deleteEvent(thisEvent) {
      const id = thisEvent.objectId
      destroy(thisEvent)
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.rootStore.sessiontoken)
      try {
        yield parseApi.deleteObject("Event", id)
      } catch (e) {
        console.log(e)
      }
    }),
    getPlace(Id) {
      return self.places.find((place) => place.objectId === Id)
    },
    createPlace: flow(function* createPlace(place) {
      self.state = "pending"
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.rootStore.sessiontoken)
      try {
        const response: Response = yield fetch(
          HERE_API_URL +
            new URLSearchParams({
              apiKey: HERE_API_KEY,
              street: place.street,
              housenumber: place.streetNr,
              city: place.city,
              zipcode: place.zipcode,
            }),
        )
        const data = yield response.json()
        console.log("HERE data: ", data)

        let createPlace
        if (data.Response.View.length > 0) {
          createPlace = {
            name: place.name,
            street: place.street,
            street_nr: place.streetNr,
            city: place.city,
            zipcode: place.zipcode,
            location: {
              __type: "GeoPoint",
              latitude: data.Response.View[0].Result[0].Location.DisplayPosition.Latitude,
              longitude: data.Response.View[0].Result[0].Location.DisplayPosition.Longitude,
            },
            country: data.Response.View[0].Result[0].Location.Country,
            link: [undefined],
            image: place.image === "" ? undefined : place.image,
          }
        } else {
          createPlace = {
            name: place.name,
            street: place.street,
            street_nr: place.streetNr,
            city: place.city,
            country: undefined,
            zipcode: place.zipcode,
            location: undefined,
            link: undefined,
            image: place.image,
          }
        }
        if (place.link.length > 0) {
          createPlace.link = [
            {
              type: "web",
              name: place.name,
              value: place.link,
            },
          ]
        }

        const parsePlace = yield parseApi.createObject("Place", createPlace)
        console.log(parsePlace)

        const newPlace: GetGeneralResults = yield parseApi.getSomeOfClass("Place", {
          objectId: parsePlace.data.objectId,
        })
        console.log(newPlace)
        self.places.push({
          objectId: newPlace.data.results[0].objectId,
          name: newPlace.data.results[0].name,
          street: newPlace.data.results[0].street,
          street_nr: newPlace.data.results[0].street_nr,
          city: newPlace.data.results[0].city,
          zipcode: newPlace.data.results[0].zipcode,
          country: newPlace.data.results[0].country || undefined,
          location: newPlace.data.results[0].location || undefined,
          link: (newPlace.data.results[0] || {}).links || undefined,
          image: newPlace.data.results[0].image || undefined,
        })

        self.state = "done"
        return newPlace.data.results[0]
      } catch (error) {
        self.state = "error"
        console.log(`Failed to create Place, with error code: ${error}`)
        return "error"
      }
    }),
    createEvent: flow(function* createEvent(event, user) {
      self.state = "pending"
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.rootStore.sessiontoken)
      const createEvent = {
        name: event.name,
        image: event.image,
        start_date: { __type: "Date", iso: event.startUTC.toISOString() },
        end_date: event.hasEndDate
          ? { __type: "Date", iso: event.endUTC.toISOString() }
          : undefined,
        data: event.data || [],
        desc: event.desc,
        isOnline: event.isOnline || false,
        isPublic: event.isPublic || false,
        links: event.links || [],
        tags: event.tags || [],
        place_id: undefined,
        user_id: { __type: "Pointer", className: "_User", objectId: user },
      }
      if (event.placeId) {
        createEvent.place_id = { __type: "Pointer", className: "Place", objectId: event.placeId }
      }

      try {
        const parseEvent = yield parseApi.createObject("Event", createEvent)
        console.log(parseEvent)
        self.state = "done"
        return "success"
      } catch (error) {
        self.state = "error"
        console.log(error)
        return error.message
      }
    }),
    updateEvent: flow(function* updateEvent(event, objectId) {
      self.state = "pending"
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.rootStore.sessiontoken)

      const updateEvent = {
        name: event.name,
        image: event.image,
        start_date: { __type: "Date", iso: event.startUTC.toISOString() },
        end_date: event.hasEndDate
          ? { __type: "Date", iso: event.endUTC.toISOString() }
          : undefined,
        data: event.data || [],
        desc: event.desc,
        isOnline: event.isOnline || false,
        isPublic: event.isPublic || false,
        links: event.links || [],
        tags: event.tags || [],
        place_id: undefined,
      }
      if (event.placeId) {
        updateEvent.place_id = { __type: "Pointer", className: "Place", objectId: event.placeId }
      }

      try {
        const parseEvent = yield parseApi.updateObject("Event", updateEvent, objectId)
        console.log(parseEvent)
        self.state = "done"
        return "success"
      } catch (error) {
        self.state = "error"
        console.log(error)
        return error.message
      }
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

type EventStoreType = Instance<typeof EventStoreModel>
export interface EventStore extends EventStoreType {}
type EventStoreSnapshotType = SnapshotOut<typeof EventStoreModel>
export interface EventStoreSnapshot extends EventStoreSnapshotType {}
