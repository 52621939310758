import React, { useEffect, useRef, useState } from "react"
import { Text, TextStyle, View, ViewStyle } from "react-native"
import InputScrollView from "react-native-input-scroll-view"
import { TextInput } from "react-native-paper"
import { spacing, typography } from "../../../theme"
import { palette } from "../../../theme/palette"
import { FLEX01, FLEX09, FLEX1, MB } from "../../../utils/style"
import { CollapsedError } from "../../collapsed-error/CollapsedError"
import { FormPageNavigator } from "../../form-page-navigator/form-page-navigator"
import { FormPageProps } from "../../form-page/form-page"
import { RegisterFormValues } from "../register-form"
import CountryPicker from "react-native-country-picker-modal"
import regionData from "../../../../node_modules/country-region-data/data"
import { translate } from "../../../i18n"
import Collapsible from "react-native-collapsible"
import { Picker } from "@react-native-picker/picker"

const REGION_ITEM: TextStyle = {
  padding: spacing[3],
  fontSize: typography.size[3],
}

const REGION_PICKER: ViewStyle = {
  padding: spacing[3],
  paddingVertical: spacing[5],
  backgroundColor: palette.textfieldgrey,
  borderWidth: 0,
  borderTopLeftRadius: spacing[1],
  borderTopRightRadius: spacing[1],
  borderBottomWidth: 2,
  borderColor: palette.textfieldunderlinegrey,
}

const REGION_PICKER_TEXT: TextStyle = {
  fontSize: typography.size[3],
}
export const RegPage2: React.FC<FormPageProps<RegisterFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  nextPage,
}) => {
  const [regions, setRegions] = useState([])
  const [valid, setValid] = useState(false)

  const ref = useRef()
  useEffect(() => {
    if (errors.countryCode || errors.regionCode || errors.city) {
      setValid(false)
    } else {
      if (values.countryCode && values.regionCode && values.city) {
        setValid(true)
      } else {
        setValid(false)
      }
    }
  }, [values, errors])
  console.log(values)

  return (
    <>
      <View style={FLEX09}>
        <InputScrollView>
          <CountryPicker
            countryCode={values.countryCode}
            placeholder={translate("register.pick.country")}
            withEmoji={true}
            onSelect={(country) => {
              setFieldValue("countryCode", country.cca2)
              // ref && ref.current.select(-1)
              setRegions(regionData.find((land) => land.countryShortCode === country.cca2).regions)
            }}
            preferredCountries={["CH", "FR", "DE", "IT"]}
            withFilter={true}
            withAlphaFilter={false}
            withFlagButton={true}
            withCountryNameButton={true}
            containerButtonStyle={{
              padding: spacing[3],
              paddingVertical: spacing[4],
              backgroundColor: palette.lessalphawhite,
              marginVertical: spacing[2],
              borderRadius: 5,
              borderBottomColor: palette.purple,
              borderBottomWidth: 1,
            }}
          />
          <CollapsedError error={touched.country && errors.country} />
          <Collapsible collapsed={regions.length === 0}>
            <Picker
              itemStyle={REGION_ITEM}
              ref={ref}
              enabled={regions.length > 0}
              onValueChange={(itemValue) => {
                console.log(JSON.stringify(itemValue))
                // console.log(regions.findIndex(itemValue))
                setFieldValue("regionCode", itemValue as string)
              }}
              style={[MB, REGION_PICKER]}
            >
              <Picker.Item key={"nothing"} value={null} label={translate("register.pick.region")} />
              {regions.map((region) => {
                return (
                  <Picker.Item
                    style={REGION_PICKER_TEXT}
                    key={region.shortCode}
                    value={region.name}
                    label={region.name}
                  />
                )
              })}
            </Picker>
          </Collapsible>

          <CollapsedError error={touched.countryCode && errors.countryCode} />
          <TextInput
            placeholder={translate("register.city")}
            value={values.city}
            onChangeText={handleChange("city")}
            returnKeyType="done"
            autoCapitalize="words"
            onBlur={handleBlur("city")}
          />
          <CollapsedError error={touched.city && errors.city} />
        </InputScrollView>
      </View>
      <View style={FLEX01}>
        <FormPageNavigator nextPage={nextPage} disabled={!valid} />
      </View>
    </>
  )
}
