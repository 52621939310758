import { DEFAULT_HEADERS } from "apisauce"
import { Api, ParseApi } from "../services/api"
import { RcApi } from "../services/api/rc-api"
const { MATRIX_HOMESERVER } = require("../config/env")

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    this.api = new Api()
    this.matrixApi = this.api = new Api({
      url: MATRIX_HOMESERVER,
      timeout: 10000,
      headers: DEFAULT_HEADERS,
    })
    this.parseApi = new ParseApi()
    // we only need this for web hence stripe
    this.rcApi = new RcApi()
  }

  async setup() {
    // allow each service to setup
    await this.api.setup()
    await this.matrixApi.setup()
    await this.rcApi.setup()
  }

  /**
   * Our apis.
   */
  api: Api
  matrixApi: Api
  parseApi: ParseApi
  rcApi: RcApi // revenucat
}
