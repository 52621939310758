/* eslint-disable react-native/no-inline-styles */
import React from "react"
import { ImageStyle, TextStyle, View, ViewStyle, Image, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, typography } from "../../theme"
import { Text } from "../"
import { MyModal } from "../my-modal/my-modal"
import { User } from "../../models/user/user"
import { palette } from "../../theme/palette"
import { DEVICE_WIDTH } from "../../utils/device"
import { ROW } from "../../utils/style"
import { Button } from "../button/button"
import usePlatform from "../../hooks/usePlatform"

const WHITE_FIELD: ViewStyle = {
  backgroundColor: palette.lighterGrey,
  borderRadius: spacing[4],
  shadowRadius: 0,
  shadowOpacity: 0,
}

const AVATAR: ImageStyle = {
  height: Platform.OS === "web" ? 100 : DEVICE_WIDTH / 4,
  width: Platform.OS === "web" ? 100 : DEVICE_WIDTH / 4,
  borderRadius: Platform.OS === "web" ? 50 : DEVICE_WIDTH / 8,
}

const AVATAR_WRAPPER: ViewStyle = {
  padding: spacing[1],
  borderTopLeftRadius: Platform.OS === "web" ? 60 : DEVICE_WIDTH / 7.5,
  borderTopRightRadius: Platform.OS === "web" ? 60 : DEVICE_WIDTH / 7.5,
  borderBottomRightRadius: Platform.OS === "web" ? 60 : DEVICE_WIDTH / 7.5,
  borderBottomLeftRadius: Platform.OS === "web" ? 60 : DEVICE_WIDTH / 7.5,
  backgroundColor: palette.morealphawhite,
  marginTop: Platform.OS === "web" ? -50 : -DEVICE_WIDTH / 8,
  marginLeft: spacing[6],
}

const INFO_BLOCK: ViewStyle = {
  paddingHorizontal: spacing[6],
  paddingVertical: spacing[3],
}

const NAME: TextStyle = {
  fontSize: typography.size[5],
  fontWeight: "bold",
  fontFamily: typography.primary,
}

const SUBNAME: TextStyle = {
  fontSize: typography.size[2],
  fontWeight: "normal",
  color: palette.lightGrey,
  fontFamily: typography.primary,
}

export interface HumanModalProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  user: User
  visible: boolean
  dismiss: any
  goto: any
}

/**
 * Describe your component here
 */
export const HumanModal = observer(function HumanModal(props: HumanModalProps) {
  const { user, visible, dismiss, goto } = props
  const { isBig } = usePlatform()

  return (
    <MyModal visible={visible} dismiss={dismiss} dismissable plain>
      <View
        style={[
          WHITE_FIELD,
          {
            width: isBig ? "20%" : "80%",
            marginHorizontal: Platform.OS === "web" && isBig ? "auto" : spacing[6],
          },
        ]}
      >
        <View style={ROW}>
          <View style={AVATAR_WRAPPER}>
            <Image
              style={AVATAR}
              source={{ uri: user.avatar || "https://placekitten.com/640/640" }}
            />
          </View>
          {/* <FollowButton userId={user.objectId} style={{ marginTop: spacing[2] }} /> */}
        </View>

        <View style={INFO_BLOCK}>
          <Text style={NAME}>
            {user.name} {user.lastName}
          </Text>
          <Text style={SUBNAME}>{(user.home || {}).city}</Text>
          <Text style={SUBNAME}>
            {user.home.region + ", "}
            {user.CountryString}
          </Text>
        </View>
        <View style={INFO_BLOCK}>
          {/* <FollowButton userId={user.objectId} /> */}
          <Text style={SUBNAME}>{user.bio}</Text>
        </View>
        <View style={INFO_BLOCK}>
          <Button tx="profile.goto" preset="secondary" onPress={goto} />
        </View>
      </View>
    </MyModal>
  )
})
