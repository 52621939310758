import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, FlatList, TextStyle, Pressable } from "react-native"
import { Container, Header, Icon, Screen, Text } from "../../components"
import { useStores } from "../../models"
import { spacing, typography } from "../../theme"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { palette } from "../../theme/palette"
import { HumanBubble } from "../../components/human-bubble/human-bubble"
import { PodcastPlayer } from "../../components/podcast-player/podcast-player"
import { MasterBubble } from "../../components/master-bubble/master-bubble"
import Svg, { Path } from "react-native-svg"
import { BLR, SHADOW } from "../../utils/style"
import usePlatform from "../../hooks/usePlatform"
import useMusic from "../../hooks/useMusic"
import { useIsFocused } from "@react-navigation/native"
import { View, Image } from "@dripsy/core"

const ROOT: ViewStyle = {
  flex: 1,
}

const NAME: TextStyle = {
  fontSize: typography.size[6],
  color: palette.morealphawhite,
}

const DESC: TextStyle = {
  paddingRight: spacing[3],
  fontSize: typography.size[3],
  color: palette.darkerpurple,
}

const POD_ITEM: ViewStyle = {
  backgroundColor: palette.white,
  marginVertical: spacing[1],
  marginHorizontal: spacing[2],
  padding: spacing[2],
  borderRadius: spacing[6],
}

const POD_HEADER: ViewStyle = {
  flexDirection: "row",
}

const POD_PLAY: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginTop: spacing[3],
  marginHorizontal: spacing[3],
}

const POD_TITLE: TextStyle = {
  fontSize: typography.size[3],
  color: palette.darkpurple,
  paddingRight: 30,
}

const POD_DATE: TextStyle = {
  fontSize: typography.size[2],
  color: palette.darkerpurple,
}

const HEADER: ViewStyle = {
  paddingLeft: 70,
  paddingVertical: spacing[2],
  width: "100%",
  minHeight: 60,
  borderBottomLeftRadius: 80,
  marginTop: -4,
}

const SWOOSH: ViewStyle = {
  zIndex: 1000,
  marginTop: -80,
  alignSelf: "flex-start",
  marginLeft: -1,
}

const POD_META: ViewStyle = {
  paddingVertical: spacing[3],
  paddingLeft: 70,
}

const Item = ({ item, onPress }) => (
  <View style={POD_ITEM}>
    <View style={POD_HEADER}>
      <Pressable onPress={onPress}>
        <View style={POD_PLAY}>
          <Icon name="play" color={palette.purple} />
        </View>
      </Pressable>
      <View>
        <Text style={POD_TITLE}>{item.title}</Text>
        <Text style={POD_DATE}>{new Date(item.release).toDateString()}</Text>
      </View>
    </View>
    {item.info.map((human, index) => (
      <HumanBubble key={index} human={human} small />
    ))}
  </View>
)

export const PodcastCategoryScreen = observer(function PodcastCategoryScreen() {
  // Pull in one of our MST stores
  const { podcastStore, programStore } = useStores()
  // const playbackState = usePlaybackState()
  const { headerImageWidth, headerImageHeight } = usePlatform()
  const { togglePlayPause, playMusic, stopMusic, status, seek } = useMusic()
  const [data, setData] = useState([])

  useEffect(() => {
    const setup = async () => {
      const pods = await podcastStore
        .getCurrentProgramDetailPodcasts(programStore.CurrentProgramDetailId)
        .slice()
        .sort((a, b) => a.release > b.release)
        .reverse()
      await setData(pods)
      podcastStore.setCurrentPodcast(pods[0].objectId)
    }
    setup()
  }, [])

  const playPodcast = async (item) => {
    playMusic(
      item.url, // Load media from the network
      true,
    )
    podcastStore.setIsPlaying(true)
    podcastStore.setCurrentPodcast(item.objectId)
    podcastStore.incrementPlayCount(item.objectId)
  }

  const stopPodcast = async () => {
    await stopMusic()
    podcastStore.setIsPlaying(false)
  }

  const renderItem = ({ item }) => (
    <Item
      item={item}
      onPress={() => {
        playPodcast(item)
      }}
    />
  )

  if (!programStore || programStore.state === "pending" || !programStore.currentProgramDetail) {
    // our pretty loading screen
    return <LoadingScreen />
  }

  if (!podcastStore || podcastStore.state === "pending" || !data) {
    // our pretty loading screen
    return <LoadingScreen />
  }

  return (
    <Screen style={ROOT} preset="fixed">
      <Container>
        <FlatList
          data={data}
          keyExtractor={(item) => item.objectId}
          // eslint-disable-next-line react-native/no-inline-styles
          renderItem={renderItem}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={<View sx={{ height: 200 }} />}
          ListHeaderComponent={
            <>
              <Image
                source={{ uri: programStore.currentProgramDetail.cover }}
                sx={{
                  width: "100%",
                  height: headerImageHeight,
                  borderBottomLeftRadius: 50,
                }}
                resizeMethod="auto"
                resizeMode="cover"
              />
              <View
                sx={{ ...SHADOW, ...BLR, marginBottom: "$l", backgroundColor: "$mainBackground" }}
              >
                <Svg height="80" width="80" style={SWOOSH}>
                  <Path
                    fill={palette.darkpurple}
                    x="0"
                    y="0"
                    d="m 0,0 c 1,80 1,80 1,80 0,0 0,0 80,0 C 40,80 0,40 0,0 Z"
                  />
                </Svg>
                <View style={[HEADER, { backgroundColor: palette.darkpurple }]}>
                  <Text style={NAME}>{programStore.currentProgramDetail.title}</Text>
                </View>
                <View style={POD_META}>
                  {programStore.currentProgramDetail.podIntroText && (
                    <Text style={DESC}>{programStore.currentProgramDetail.podIntroText}</Text>
                  )}
                  <MasterBubble data={programStore.currentProgramDetail} />
                </View>
              </View>
            </>
          }
        />
        <PodcastPlayer
          status={status}
          onToggle={togglePlayPause}
          onStop={stopPodcast}
          onSeek={seek}
        />
      </Container>
    </Screen>
  )
})
