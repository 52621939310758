import * as React from "react"
import { HelperText } from "react-native-paper"
import Collapsible from "react-native-collapsible"
import { ViewStyle } from "react-native"
import { spacing } from "../../theme"

const HELPER: ViewStyle = {
  marginTop: 0,
  paddingTop: 0,
  marginBottom: spacing[2],
}

export function CollapsedError({ error }) {
  return (
    <Collapsible collapsed={!error}>
      <HelperText type="error" visible={error} style={HELPER}>
        {error}
      </HelperText>
    </Collapsible>
  )
}
