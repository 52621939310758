import * as React from "react"
import { Image, ImageStyle, TextStyle, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"
import { Icon } from "../icon/icon"

const CONTAINER: ViewStyle = {
  justifyContent: "flex-start",
  flexDirection: "row",
  borderRadius: 50,
  marginVertical: spacing[2],
  padding: spacing[1],
  backgroundColor: palette.lighterGrey,
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: color.secondary,
}

const IMAGE_CONTAINER: ViewStyle = {
  backgroundColor: palette.purple,
  borderRadius: 30,
  padding: spacing[3],
}

const INFO: ViewStyle = {
  paddingLeft: spacing[3],
  justifyContent: "center",
  flex: 1,
}

export interface PodcastBubbleProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

/**
 * Describe your component here
 */
export const PodcastBubble = observer(function PodcastBubble(props: PodcastBubbleProps) {
  const { style } = props

  return (
    <View style={[CONTAINER, style]}>
      <View style={IMAGE_CONTAINER}>
        <Icon name="mic" size={24} color={palette.white} />
      </View>
      <View style={INFO}>
        <Text style={TEXT} tx="podcast.goto" />
      </View>
    </View>
  )
})
