import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const PodcastDataModel = types
  .model("PodcastData")
  .props({
    desc: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
    web: types.optional(types.string, ""),
    avatar: types.optional(types.string, ""),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type PodcastDataType = Instance<typeof PodcastDataModel>
export interface PodcastData extends PodcastDataType {}
type PodcastDataSnapshotType = SnapshotOut<typeof PodcastDataModel>
export interface PodcastDataSnapshot extends PodcastDataSnapshotType {}
