import * as React from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"

export interface MyViewProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle | ViewStyle[]
  hide?: boolean
  children
}

/**
 * Describe your component here
 */
export const MyView = observer(function MyView(props: MyViewProps) {
  const { style, hide } = props

  if (hide) return <View />

  return <View style={style}>{props.children}</View>
})
