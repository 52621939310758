import * as React from "react"
import { Linking, TextStyle, View, ViewStyle, Image, ImageStyle, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { PodcastData } from "../../models/podcast-data/podcast-data"
import Autolink from "react-native-autolink"
import { palette } from "../../theme/palette"
import { ProgramDetail } from "../../models/program-detail/program-detail"
import { Avatar } from "react-native-paper"

const CONTAINER: ViewStyle = {
  justifyContent: "flex-start",
  flexDirection: "row",
  borderTopLeftRadius: 50,
  borderBottomLeftRadius: 50,

  marginVertical: spacing[2],
  padding: spacing[1],
  backgroundColor: palette.lighterGrey,
  maxHeight: 70,
}

const NAME: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[4],
  color: palette.darkpurple,
}

const LINK: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: color.secondary,
  flex: 1,
}

const AVATAR: ImageStyle = {
  borderRadius: 30,
  height: Platform.OS === "web" ? 60 : "100%",
  width: Platform.OS === "web" ? 60 : "auto",
  aspectRatio: 1,
}

const TEXT_WRAPPER: ViewStyle = {
  marginLeft: spacing[2],
  flex: 1,
}

export interface MasterBubbleProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  data: ProgramDetail
}

/**
 * Describe your component here
 */
export const MasterBubble = observer(function MasterBubble(props: MasterBubbleProps) {
  const { style, data } = props
  const { podIntroPic, podIntroWeb, podIntroMail, podIntroName } = data

  if (!podIntroPic && !podIntroName && !podIntroWeb) return null

  return (
    <View style={[CONTAINER, style]}>
      <View>{podIntroPic ? <Image style={AVATAR} source={{ uri: podIntroPic }} /> : null}</View>
      <View style={TEXT_WRAPPER}>
        {podIntroName ? (
          <Text style={NAME} numberOfLines={1}>
            {podIntroName}
          </Text>
        ) : null}
        {podIntroWeb ? (
          <Autolink
            linkStyle={LINK}
            text={podIntroWeb}
            numberOfLines={podIntroName ? 2 : 3}
            // renderText={(text) => <Text>{text}</Text>}
          />
        ) : null}
      </View>
    </View>
  )
})
