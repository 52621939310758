export const formatUrl = (value, type) => {
  const link = value.substr(value.indexOf("://") + 3)

  if (type === "web") {
    const next = link.endsWith("/") ? link.slice(0, -1) : link
    return "https://" + next
  } else if (type === "email") {
    return "mailto:" + link
  } else if (type === "tel") {
    return "tel:" + link
  }
  return link
}
