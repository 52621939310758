import * as React from "react"
import { TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"

const SIDE_BUTTONS: ViewStyle = {
  marginVertical: spacing[2],
  paddingVertical: spacing[4],
  borderTopLeftRadius: 30,
  borderBottomLeftRadius: 30,
  backgroundColor: palette.darkpurple,
  elevation: 9,
}

const TEXT_LENGTH = 65
const TEXT_HEIGHT = 60
const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2

export interface SideButtonProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  onPress: any
  tx: string
}
/**
 * Describe your component here
 */
export const SideButton = observer(function SideButton(props: SideButtonProps) {
  const { style, tx, onPress } = props

  return (
    <TouchableOpacity style={SIDE_BUTTONS} onPress={onPress}>
      <View style={{ width: TEXT_HEIGHT, height: TEXT_LENGTH }}>
        <Text
          style={{
            transform: [
              { rotate: "270deg" },
              { translateX: -OFFSET + 1 },
              { translateY: OFFSET + 2 },
            ],
            width: TEXT_LENGTH,
            height: TEXT_HEIGHT,
            color: "white",
          }}
          tx={tx}
        />
      </View>
    </TouchableOpacity>
  )
})
