import React, { useEffect, useState } from "react"
import { Platform, ScrollView, TextStyle, View } from "react-native"
import InputScrollView from "react-native-input-scroll-view"
import { TextInput } from "react-native-paper"
import { translate } from "../../../i18n"
import { spacing, typography } from "../../../theme"
import { palette } from "../../../theme/palette"
import { FLEX01, FLEX09, FLEX1, MB } from "../../../utils/style"
import { CollapsedError } from "../../collapsed-error/CollapsedError"
import { FormPageNavigator } from "../../form-page-navigator/form-page-navigator"
import { FormPageProps } from "../../form-page/form-page"
import { Text } from "../../text/text"
import { RegisterFormValues } from "../register-form"

const TEXT: TextStyle = {
  fontSize: typography.size[3],
  textAlign: "left",
  marginBottom: spacing[5],
}

export const RegPage1: React.FC<FormPageProps<RegisterFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  nextPage,
}) => {
  const [valid, setValid] = useState(false)

  useEffect(() => {
    if (errors.fistname || errors.lastname) {
      setValid(false)
    } else {
      if (values.firstname && values.lastname) {
        setValid(true)
      } else {
        setValid(false)
      }
    }
  }, [values, errors])

  return (
    <>
      <View style={FLEX09}>
        {Platform.OS === "web" ? (
          <>
            <Text style={TEXT} tx="auth.registermessage" />
            <TextInput
              label={translate("register.firstname")}
              value={values.firstname}
              onChangeText={(text) => setFieldValue("firstname", text)}
              onBlur={handleBlur("firstname")}
              returnKeyType="done"
              error={touched.firstname && errors.firstname}
              underlineColor={palette.purple}
              style={MB}
            />
            <CollapsedError error={touched.firstname && errors.firstname} />
            <TextInput
              label={translate("register.lastname")}
              value={values.lastname}
              onChangeText={(text) => setFieldValue("lastname", text)}
              onBlur={handleBlur("lastname")}
              returnKeyType="done"
              error={touched.lastname && errors.lastname}
              underlineColor={palette.purple}
              style={MB}
            />
            <CollapsedError error={touched.lastname && errors.lastname} />
            <TextInput
              placeholder={translate("register.motivation")}
              value={values.bio}
              onChangeText={(text) => setFieldValue("bio", text)}
              returnKeyType="done"
              onBlur={handleBlur("bio")}
              underlineColor={palette.purple}
              multiline={true}
              numberOfLines={5}
            />
          </>
        ) : (
          <ScrollView style={FLEX1}>
            <Text style={TEXT} tx="auth.registermessage" />
            <TextInput
              label={translate("register.firstname")}
              value={values.firstname}
              onChangeText={handleChange("firstname")}
              onBlur={handleBlur("firstname")}
              returnKeyType="done"
              error={touched.firstname && errors.firstname}
              underlineColor={palette.purple}
              style={MB}
            />
            <CollapsedError error={touched.firstname && errors.firstname} />
            <TextInput
              label={translate("register.lastname")}
              value={values.lastname}
              onChangeText={handleChange("lastname")}
              onBlur={handleBlur("lastname")}
              returnKeyType="done"
              error={touched.lastname && errors.lastname}
              underlineColor={palette.purple}
              style={MB}
            />
            <CollapsedError error={touched.lastname && errors.lastname} />
            <TextInput
              placeholder={translate("register.motivation")}
              value={values.bio}
              onChangeText={handleChange("bio")}
              returnKeyType="done"
              onBlur={handleBlur("bio")}
              underlineColor={palette.purple}
              multiline={true}
              numberOfLines={5}
            />
          </ScrollView>
        )}
      </View>

      <View style={FLEX01}>
        <FormPageNavigator nextPage={nextPage} disabled={!valid} />
      </View>
    </>
  )
}
