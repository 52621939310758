import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { EventsInfoModel } from "../events-info/events-info"
import { EventVenueModel } from "../event-venue/event-venue"
import { LinkModel } from "../link/link"
import { TagModel } from "../tag/tag"

/**
 * Model description here for TypeScript hints.
 */
export const EventModel = types.model("Event").props({
  objectId: types.identifier,
  name: types.string,
  image: types.string,
  thumb: types.maybe(types.string),
  startDate: types.Date,
  endDate: types.maybeNull(types.Date),
  isPublic: types.boolean,
  data: types.maybe(types.array(EventsInfoModel)),
  desc: types.string,
  links: types.optional(types.array(LinkModel), []),
  isOnline: types.boolean,
  tags: types.optional(types.array(TagModel), []),
  place_id: types.maybe(types.reference(EventVenueModel)),
  user_id: types.maybe(types.string) // types.safeReference(UserModel),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type EventType = Instance<typeof EventModel>
export interface Event extends EventType {}
type EventSnapshotType = SnapshotOut<typeof EventModel>
export interface EventSnapshot extends EventSnapshotType {}
