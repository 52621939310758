export const palette = {
  black: "#1d1d1d",
  white: "#ffffff",
  offwhite: "#f8f8ff",

  morealphawhite: "#ffffffcc",
  alphawhite: "#ffffff98",
  lessalphawhite: "#ffffff85",
  tinyalphawhite: "#ffffff20",

  orange: "#ed6f25",
  paleorange: "#E8B467",
  orangeDarker: "#EB9918",

  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",

  angry: "#dd3333",

  purple: "#8a56ac",
  darkpurple: "#655372",
  darkerpurple: "#352641",

  alphablack: "#1d1d1d66",

  textfieldgrey: "#E7E7E7",
  textfieldunderlinegrey: "#B7B8B8",

  nothing: "#00000000",

  // NEW PALETTE
  amethyst: "#9b59b6", // lighter
  wisteria: "#8e44ad", // darker
  carrot: "#e67e22", // lighter
  pumpkin: "#d35400", // darker
}
