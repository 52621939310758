import * as Localization from "expo-localization"
import i18n from "i18n-js"

const en = require("./universalapp-en")
const de = require("./universalapp-de")
const fr = require("./universalapp-fr")
const it = require("./universalapp-it")

i18n.fallbacks = true
i18n.translations = { en, de, fr, it }

i18n.locale = Localization.locale.substr(0, 2)

export const MyLanguage = i18n.locale
