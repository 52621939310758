import React from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { Container, Header, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { ForgotPasswordForm } from "../../components/forgot-password-form/forgot-password-form"
import Bugsnag from "@bugsnag/react-native"

const ROOT: ViewStyle = {
  flex: 1,
}

export const ForgotPasswordScreen = observer(function ForgotPasswordScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Forgot Password")

  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  const nav = useNavigation()

  return (
    <Screen style={ROOT} preset="fixed">
      <Container gradient>
        <Header
          headerTx="auth.resetpwmessage"
          leftIcon={{ name: "arrow-left" }}
          onLeftPress={nav.goBack}
        />
        <ForgotPasswordForm />
      </Container>
    </Screen>
  )
})
