/* eslint-disable react-native/no-inline-styles */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Linking, Pressable, TextStyle, View, ViewStyle } from "react-native"
import { Button, Container, Icon, Screen, Text } from "../../components"
import { spacing, typography } from "../../theme"
import { AccountModel, useStores } from "../../models"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { palette } from "../../theme/palette"
import { useToast } from "react-native-toast-notifications"
import { usePurchasesApi } from "../../hooks/usePurchasesApi"
import { useStripeApi } from "../../hooks/useStripeApi"
import Collapsible from "react-native-collapsible"
import { FLEX1, ROW } from "../../utils/style"
import { translate } from "../../i18n"
import { Link } from "@react-navigation/native"

const ROOT: ViewStyle = {
  // backgroundColor: color.palette.black,
  flex: 1,
}

const SLIDE: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const PACKAGES: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
}

const ITEM: ViewStyle = {
  width: 100,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: palette.white,
  borderRadius: spacing[3],
  margin: spacing[1],
  padding: spacing[1],
  paddingVertical: spacing[2],
}

const ACTIVE_ITEM: ViewStyle = {
  width: 100,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: palette.paleorange,
  borderRadius: spacing[3],
  margin: spacing[1],
  padding: spacing[1],
  paddingVertical: spacing[2],
}

const THANK_YOU_BOX: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: palette.alphawhite,
  borderRadius: spacing[3],
  margin: spacing[1],
  padding: spacing[1],
  paddingVertical: spacing[2],
  marginBottom: spacing[2],
}

const TEXT: TextStyle = {
  fontSize: typography.size[3],
  color: palette.morealphawhite,
}

const SUB_TYPE: TextStyle = {
  fontSize: typography.size[4],
  color: palette.morealphawhite,
  textAlign: "center",
}

const DISCLAIMER: TextStyle = {
  fontSize: typography.size[3],
  color: palette.morealphawhite,
  textAlign: "justify",
  padding: spacing[2],
}

export const PaywallScreen = observer(function PaywallScreen() {
  // Pull in one of our MST stores
  const rootStore = useStores()
  const { account } = rootStore
  const purchaserFetch = usePurchasesApi("subscribers/" + rootStore.me)

  // the selected package
  const [sub, setSub] = useState(null)
  const [activeSub, setActiveSub] = useState(null)

  const [showDisclaimer, setShowDisclaimer] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [packages, setPackages] = useState([
    // { this is test data
    //   annual: false,
    //   identifier: "earth",
    //   price: "5.00",
    //   stripePriceId: "price_1IXhkKCBPTEWbSrZ63FB0j6o",
    // },
    {
      annual: false,
      identifier: "earth",
      price: "5.00",
      stripeProductId: "prod_JJuXNcpFcaelHf",
      stripePriceId: "price_1IhGirCBPTEWbSrZHzhyB6qn",
    },
    {
      annual: true,
      identifier: "earth",
      price: "55.00",
      stripeProductId: "prod_JJuYUR3UlX7qxC",
      stripePriceId: "price_1IhGjBCBPTEWbSrZpbZHWTtc",
    },
    {
      annual: false,
      identifier: "sun",
      price: "8.00",
      stripeProductId: "prod_JJuYiMwbZtjsOY",
      stripePriceId: "price_1IhGjTCBPTEWbSrZUo7N2Lei",
    },
    {
      annual: true,
      identifier: "sun",
      price: "89.00",
      stripeProductId: "prod_JJuYialXgzf3PO",
      stripePriceId: "price_1IhGjkCBPTEWbSrZDd1NKBdQ",
    },
    {
      annual: false,
      identifier: "universal",
      price: "13.00",
      stripeProductId: "prod_JJuZxrAKHL2WeO",
      stripePriceId: "price_1IhGk5CBPTEWbSrZLen1a0GV",
    },
    {
      annual: true,
      identifier: "universal",
      price: "145.00",
      stripeProductId: "prod_JJuZRDx01RtymZ",
      stripePriceId: "price_1IhGkKCBPTEWbSrZrgM1f4oX",
    },
  ])

  useEffect(() => {
    if (purchaserFetch.state === "SUCCESS") {
      console.log("data: ", JSON.stringify(purchaserFetch.data))

      if (JSON.stringify(purchaserFetch.data.subscriber.entitlements) === JSON.stringify({})) {
        console.log("No active Subs")
        // Object is Empty
      } else {
        console.log("Entitlement found")

        // Object is Not Empty
        const currentActiveSub =
          purchaserFetch.data.subscriber.entitlements.access.product_identifier

        if (currentActiveSub) {
          console.log("User has: ", currentActiveSub)
          setActiveSub(packages.find((pack) => pack.stripeProductId === currentActiveSub))
        }
        rootStore.unlock()
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
    return () => {}
  }, [purchaserFetch])

  useEffect(() => {
    const set = async () => {
      await purchaserFetch.setAttributes("country", rootStore.userStore.me.home.country)
      await purchaserFetch.setAttributes("region", rootStore.userStore.me.home.region)
    }
    set()
  }, [])

  const PackageDisplayItem = ({ purchasePackage }) => {
    const { identifier, price, annual, stripePriceId } = purchasePackage
    let icon = identifier

    if (identifier === "earth") {
      icon = "globe"
    }

    if (identifier === "universal") {
      icon = "heart"
    }

    return (
      <View style={sub && stripePriceId === sub.stripePriceId ? ACTIVE_ITEM : ITEM}>
        <Icon
          name={icon}
          size={36}
          color={sub && stripePriceId === sub.stripePriceId ? palette.alphawhite : palette.orange}
        />
        <Text>{identifier}</Text>
        <Text>{price}</Text>
        {annual ? <Text tx="sub.annualy" /> : <Text tx="sub.monthly" />}
      </View>
    )
  }

  const PackageItem = ({ purchasePackage }) => {
    const { identifier, price, annual, stripePriceId } = purchasePackage
    let icon = identifier

    if (identifier === "earth") {
      icon = "globe"
    }

    if (identifier === "universal") {
      icon = "heart"
    }

    const onSelection = () => {
      if (sub === purchasePackage) {
        setSub(null)
      } else {
        setSub(purchasePackage)
      }
    }

    return (
      <Pressable
        onPress={onSelection}
        style={sub && stripePriceId === sub.stripePriceId ? ACTIVE_ITEM : ITEM}
      >
        <Icon
          name={icon}
          size={36}
          color={sub && stripePriceId === sub.stripePriceId ? palette.alphawhite : palette.orange}
        />
        <Text>{identifier}</Text>
        <Text>
          {price}/{translate(annual ? "sub.year" : "sub.month")}
        </Text>
      </Pressable>
    )
  }

  const gotoStripe = () => {
    Linking.openURL(
      "https://universalapp-purchases.herokuapp.com/purchase/" +
        rootStore.me +
        "/" +
        sub.stripePriceId,
    )
  }
  console.log(rootStore.account)

  if (isLoading || !rootStore) return <LoadingScreen />

  return (
    <Screen style={ROOT} preset="scroll">
      <Container>
        <View style={{ padding: spacing[3] }}>
          {activeSub ? (
            <View style={THANK_YOU_BOX}>
              <Text style={[TEXT, { color: palette.darkerpurple }]} tx="sub.thanks" />
              <Link
                to={"https://universalapp-purchases.herokuapp.com/portal/" + rootStore.sessiontoken}
              >
                <Button>
                  <Text>Manage Subscription</Text>
                </Button>
              </Link>
              <View style={ROW}>
                <View style={[FLEX1, { justifyContent: "center" }]}>
                  <Text style={[TEXT, { color: palette.darkerpurple }]} tx="sub.yourcurrentsubis" />
                </View>
                <View style={[FLEX1, { alignItems: "center" }]}>
                  <PackageDisplayItem purchasePackage={activeSub} />
                </View>
              </View>
            </View>
          ) : (
            <>
              <View style={THANK_YOU_BOX}>
                {!activeSub && (
                  <>
                    {rootStore.account.hasCode ? (
                      <Text style={[TEXT, { color: palette.alphablack }]} tx="sub.success" />
                    ) : rootStore.account.freeTimeLeft ? (
                      <Text style={[TEXT, { color: palette.alphablack }]} tx="sub.notyet" />
                    ) : (
                      <Text tx="sub.freemonthnomore" />
                    )}
                  </>
                )}
              </View>
              <Text style={TEXT} tx="sub.text" />
            </>
          )}
        </View>
        <View style={SLIDE}>
          <View style={PACKAGES}>
            {packages.map((purchasePackage) => {
              if (!purchasePackage.annual) {
                return (
                  <PackageItem
                    key={purchasePackage.stripePriceId}
                    purchasePackage={purchasePackage}
                  />
                )
              } else return null
            })}
          </View>
          <View style={PACKAGES}>
            {packages.map((purchasePackage) => {
              if (purchasePackage.annual) {
                return (
                  <PackageItem
                    key={purchasePackage.stripePriceId}
                    purchasePackage={purchasePackage}
                  />
                )
              } else return null
            })}
          </View>
        </View>
        {sub ? (
          <Button tx="sub.purchase" onPress={gotoStripe} style={{ margin: spacing[4] }} />
        ) : (
          <>
            {account.hasSubscription && (
              <>
                <Text
                  style={[TEXT, { textAlign: "center", marginTop: spacing[6] }]}
                  tx="sub.alreadygotone"
                />
                <Link
                  href={account.subscription.manageUrl}
                  to={account.subscription.manageUrl}
                  style={{ margin: spacing[3], width: "100%", display: "grid" }}
                >
                  <Button
                    tx="sub.manage"
                    preset="secondary"
                    onPress={account.subscription.manage}
                  />
                </Link>
              </>
            )}
          </>
        )}
      </Container>
    </Screen>
  )
})
