import React, { useState } from "react"
import { ActivityIndicator, View, ViewStyle, TextStyle, Platform, Alert } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, typography } from "../../theme"
import { Formik } from "formik"
import * as Yup from "yup"
import { useStores } from "../../models"
import { Button } from "../button/button"
import { translate } from "../../i18n"
import { palette } from "../../theme/palette"
import { TextInput } from "react-native-paper"
import { CollapsedError } from "../collapsed-error/CollapsedError"
import { useToast } from "react-native-toast-notifications"
import { useLinkTo } from "@react-navigation/native"
const CONTAINER: ViewStyle = {
  justifyContent: "center",
}

const BUTTON: ViewStyle = {
  marginVertical: spacing[2],
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Enter a valid email").required("Please enter your email address"),
  password: Yup.string()
    .required("Please enter your password")
    .min(4, "Password must have at least 4 characters"),
})

export interface LoginFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

/**
 * Describe your component here
 */
export const LoginForm = observer(function LoginForm(props: LoginFormProps) {
  const rootStore = useStores()
  const { style } = props
  const toast = useToast()
  if (!rootStore) return null

  return (
    <View style={[CONTAINER, style]}>
      <Formik
        initialValues={{
          email: "",
          password: "",
          agb: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          const result = await rootStore.signIn(values.email, values.password)
          if (result !== "success") {
            //            Toast.showWithGravity(result, Toast.LONG, Toast.TOP, ["UIAlertController"])
            toast.show(result)
          }
          actions.setSubmitting(false)
        }}
      >
        {({
          handleChange,
          values,
          submitForm,
          errors,
          isValid,
          isSubmitting,
          touched,
          handleBlur,
        }) => (
          <>
            <TextInput
              placeholder={translate("auth.email")}
              // this is used as active and passive border color
              // eslint-disable-next-line react-native/no-inline-styles
              value={values.email}
              keyboardType="email-address"
              onChangeText={handleChange("email")}
              returnKeyType="done"
              autoCapitalize="none"
              onBlur={handleBlur("email")}
              error={!!errors.email}
              style={{ backgroundColor: palette.nothing }}
            />
            <CollapsedError error={touched.email && errors.email} />
            <TextInput
              placeholder={translate("auth.password")}
              value={values.password}
              onChangeText={handleChange("password")}
              onBlur={handleBlur("password")}
              returnKeyType="done"
              autoCapitalize="none"
              secureTextEntry={true}
              error={!!errors.password}
              style={{ backgroundColor: palette.nothing }}
            />
            <CollapsedError error={touched.password && errors.password} />

            {/* if we are submitting show activity indicator else login button */}
            {isSubmitting ? (
              <ActivityIndicator animating={true} color={palette.purple} size={30} />
            ) : (
              <>
                <Button
                  style={BUTTON}
                  onPress={submitForm}
                  disabled={!isValid || isSubmitting}
                  preset="secondary"
                  tx="auth.login"
                />
              </>
            )}
          </>
        )}
      </Formik>
    </View>
  )
})
