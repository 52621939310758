import React, { useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { translate } from "../../i18n"
import { Formik } from "formik"
import { useStores } from "../../models"
import * as Yup from "yup"
import { RegPage1 } from "./pages/RegPage1"
import { RegPage2 } from "./pages/RegPage2"
import { FLEX1 } from "../../utils/style"
import { RegPage3 } from "./pages/RegPage3"
import { useToast } from "react-native-toast-notifications"

// import TextField from "@material-ui/core/TextField"

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required(translate("register.required.firstname"))
    .min(2, translate("register.min.firstname")),
  lastname: Yup.string()
    .required(translate("register.required.lastname"))
    .min(2, translate("register.min.lastname")),
  bio: Yup.string(),
  countryCode: Yup.string().required(translate("register.required.country")),
  regionCode: Yup.string().required(translate("register.required.region")),
  city: Yup.string().required(translate("register.required.city")),
  email: Yup.string()
    .email(translate("register.invalid.email"))
    .required(translate("register.required.email")),
  password: Yup.string()
    .required(translate("register.required.password"))
    .min(8, translate("register.min.password")),
  passwordCheck: Yup.string().oneOf(
    [Yup.ref("password"), null],
    translate("register.invalid.match"),
  ),
})

export interface RegisterFormValues {
  firstname: string
  lastname: string
  bio: string
  city: string
  email: string
  password: string
  passwordCheck: string
  agb: boolean
  countryCode: string
  regionCode: string
}

/**
 * Describe your component here
 */
export const RegisterForm = observer(function RegisterForm() {
  const rootStore = useStores()
  const toast = useToast()
  const [page, setPage] = useState(0)

  const nextPage = () => setPage(page + 1)
  const previousPage = () => setPage(page - 1)

  if (!rootStore) return null

  return (
    <View style={FLEX1}>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          bio: "",
          city: "",
          email: "",
          password: "",
          passwordCheck: "",
          agb: false,
          countryCode: "",
          regionCode: "",
        }}
        onSubmit={async (values, actions) => {
          __DEV__ && console.log("Submitting Regsiter Form...", values)
          // Use API to handle validated data
          const result = await rootStore.signUp({
            email: values.email,
            username: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            bio: values.bio,
            country: values.countryCode,
            region: values.regionCode,
            city: values.city,
            password: values.password,
          })
          if (result !== "success") {
            console.log(result)
            toast.show(result)
          }
          actions.setSubmitting(false)
        }}
        validationSchema={validationSchema}
      >
        {(
          {
            handleChange,
            values,
            submitForm,
            errors,
            isSubmitting,
            touched,
            handleBlur,
            setFieldValue,
          },
          pages = [
            <RegPage1
              key={1}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              nextPage={nextPage}
            />,
            <RegPage2
              key={2}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              nextPage={nextPage}
              previousPage={previousPage}
            />,
            <RegPage3
              key={3}
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              submitForm={submitForm}
              previousPage={previousPage}
              isSubmitting={isSubmitting}
            />,
          ],
        ) => <>{pages[page]}</>}
      </Formik>
    </View>
  )
})
