import { ApiResponse, ApisauceInstance, create } from "apisauce"
import { GetGeneralResult, GetGeneralResults } from "./api.types"
import { getGeneralApiProblem } from "./api-problem"
import { ApiConfig, DEFAULT_RC_API_CONFIG } from "./api-config"

export class RcApi {
  apisauce: ApisauceInstance
  config: ApiConfig
  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_RC_API_CONFIG) {
    this.config = config
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: this.config.headers,
    })
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: this.config.headers,
    })
  }

  async getPurchaserInfo(userId): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/subscribers/${userId}`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        // The session has expired
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data.subscriber
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }

  async getOfferings(): Promise<GetGeneralResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.apisauce.get(`/subscribers/asdf123/offerings`)

      // the typical ways to die when calling an api
      if (!response.ok) {
        // The session has expired
        const problem = getGeneralApiProblem(response)
        if (problem) return problem
      }

      const data = response.data
      return { kind: "ok", data }
    } catch (e) {
      __DEV__ && console.log(e.message)
      return { kind: "bad-data" }
    }
  }
}
