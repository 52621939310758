import * as React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"
import { FormikErrors } from "formik"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  marginVertical: spacing[1],
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: palette.angry,
  marginLeft: spacing[2],
  marginRight: spacing[2],
  textAlign: "center",
}

export interface ErrorMessageProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  errorValue: string | FormikErrors<Date>
}

/**
 * Describe your component here
 */
export const ErrorMessage = observer(function ErrorMessage(props: ErrorMessageProps) {
  const { style, errorValue } = props

  return (
    <View style={[CONTAINER, style]}>
      <Text style={TEXT}>{errorValue}</Text>
    </View>
  )
})
