import * as React from "react"
import { View, ViewStyle, Keyboard } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "../../theme"
import { Button } from "../button/button"

const BUTTON: ViewStyle = {
  marginVertical: spacing[2],
  alignSelf: "stretch",
}

const HALF_BUTTON: ViewStyle = {
  justifyContent: "space-between",
  flexDirection: "row",
  flex: 1,
  minHeight: 70,
}

const RIGHT: ViewStyle = {
  alignSelf: "flex-end",
}

export interface EventFormBottomButtonsProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  nextPage?
  previousPage?
  submitForm?
  persistKeyboard?: boolean
  disabled: boolean
  action?: "create" | "edit"
}

/**
 * Describe your component here
 */
export const EventFormBottomButtons = observer(function EventFormBottomButtons(
  props: EventFormBottomButtonsProps,
) {
  const { nextPage, previousPage, submitForm, persistKeyboard, disabled, action = "create" } = props

  React.useEffect(() => {
    Keyboard.addListener("keyboardDidShow", _keyboardDidShow)
    Keyboard.addListener("keyboardDidHide", _keyboardDidHide)

    // cleanup function
    return () => {
      Keyboard.removeListener("keyboardDidShow", _keyboardDidShow)
      Keyboard.removeListener("keyboardDidHide", _keyboardDidHide)
    }
  }, [])

  const [keyboardStatus, setKeyboardStatus] = React.useState(false)
  const _keyboardDidShow = () => setKeyboardStatus(true)
  const _keyboardDidHide = () => setKeyboardStatus(false)

  if (keyboardStatus && !persistKeyboard) return null

  if (previousPage && nextPage) {
    return (
      <View style={HALF_BUTTON}>
        <Button style={BUTTON} onPress={previousPage} preset="secondary" tx="common.back" />
        <Button
          style={BUTTON}
          onPress={nextPage}
          disabled={disabled || false}
          preset="secondary"
          tx="common.next"
        />
      </View>
    )
  }

  if (submitForm && previousPage) {
    return (
      <View style={HALF_BUTTON}>
        <Button style={BUTTON} onPress={previousPage} preset="secondary" tx="common.back" />
        <Button
          style={BUTTON}
          onPress={() => submitForm()}
          disabled={disabled || false}
          preset="primary"
          tx={"event.action." + action}
        />
      </View>
    )
  }

  if (nextPage) {
    return (
      <View style={[HALF_BUTTON, RIGHT]}>
        <Button
          style={BUTTON}
          onPress={nextPage}
          disabled={disabled || false}
          preset="secondary"
          tx="common.next"
        />
      </View>
    )
  }
})
