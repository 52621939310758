import * as React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { Button } from "../button/button"
import { ROW } from "../../utils/style"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  flex: 1,
  minHeight: 50,
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 14,
  color: color.primary,
}

export interface FormPageNavigatorProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  nextPage?: any
  previousPage?: any
  submitForm?: any
  actionTx?: string
  disabled?: boolean
}

/**
 * Describe your component here
 */
export const FormPageNavigator = observer(function FormPageNavigator(
  props: FormPageNavigatorProps,
) {
  const {
    style = { padding: spacing[1] },
    nextPage,
    previousPage,
    submitForm,
    disabled,
    actionTx,
  } = props

  if (nextPage && previousPage) {
    return (
      <View style={[CONTAINER, style, ROW]}>
        <Button onPress={previousPage} tx="common.back" />
        <Button onPress={nextPage} tx="common.next" disabled={disabled} />
      </View>
    )
  }

  if (nextPage && !previousPage && !submitForm) {
    return (
      <View style={[CONTAINER, style]}>
        <Button onPress={nextPage} tx="common.next" disabled={disabled} />
      </View>
    )
  }

  return (
    <View style={[CONTAINER, style]}>
      <Button onPress={submitForm} tx={actionTx} disabled={disabled} />
    </View>
  )
})
