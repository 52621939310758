import { useState } from "react"
import * as ImagePicker from "expo-image-picker"
import { cloudinaryUpload } from "../utils/cloudinary"
import Bugsnag from "@bugsnag/react-native"

function useImagePicker() {
  const [loading, setLoading] = useState(false)

  async function pick(x = 1, y = 1, preset?) {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [x, y],
      quality: 1,
    })

    console.log(result)

    if (!result.cancelled) {
      setLoading(true)
      const uri = result.uri
      // const type = result.type.concat("/jpeg")
      const name = Math.random().toString(36).substr(2, 12)
      const source = {
        uri,
        // type,
        name,
      }
      console.log(source)
      try {
        const url = await cloudinaryUpload(source, preset || "ogbxkyjd")
        setLoading(false)
        return url
      } catch (e) {
        Bugsnag.notify(e)
        console.log(e)
        setLoading(false)
        return e.message
      }
    } else {
      setLoading(false)
      return false
    }
  }

  return { loading, pick }
}

export default useImagePicker
