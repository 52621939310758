/* eslint-disable react-native/no-inline-styles */
import React, { createRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import {
  ActivityIndicator,
  SafeAreaView,
  TextStyle,
  View,
  ViewStyle,
  Keyboard,
  Platform,
} from "react-native"
import { observer } from "mobx-react-lite"
import { Bubble, GiftedAvatar, GiftedChat, IMessage } from "react-native-gifted-chat"
import { palette } from "../../theme/palette"
import { useStores } from "../../models"
import { Text } from "../text/text"
import { spacing, typography } from "../../theme"
import { FLEX1, ROW, VS } from "../../utils/style"
import { MyLanguage } from "../../i18n/i18n"
import useWebSocket from "../../hooks/useWebSocket"
import { HumanModal } from "../human-modal/human-modal"
import { translate } from "../../i18n"
import { useLinkTo } from "@react-navigation/native"
import { Button } from "../button/button"
import { IconButton } from "react-native-paper"

export interface ChatRoomProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  sessiontoken: string
  reqId: number
  visible: boolean
  me: any
  nav: any
}

const TIME: TextStyle = {
  marginHorizontal: 10,
  marginBottom: 5,
  color: palette.lightGrey,
  fontSize: 10,
}

const CHAT_NAME: TextStyle = {
  marginLeft: spacing[1],
  alignSelf: "center",
  color: palette.darkerpurple,
  fontSize: typography.size[2],
}
/**
 * Describe your component here
 */
export const ChatRoom = observer(function ChatRoom(props: ChatRoomProps) {
  const rootStore = useStores()
  const { chatRoomStore, userStore, radioStore } = rootStore
  const { style, visible } = props
  const linkTo = useLinkTo()

  const ref = useRef(null)

  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(true)

  const [patience, setPatience] = useState(false)
  const [visibleHumanModal, setVisisbleHumanModal] = useState(false)
  const [bottomOffset, setBottomOffset] = useState(0)

  const onCreate = (object) => {
    // if the message fits to our radio we add it to the chat
    object.radio_id === radioStore.CurrentRadioStationId &&
      setMessages((previousMessages) =>
        GiftedChat.append(previousMessages, object.message as IMessage[]),
      )

    // in any case we add it to the chatstore
    try {
      chatRoomStore.addtoStore({
        objectId: object.objectId,
        radio_id: object.radio_id,
        message: {
          _id: object.message._id,
          user: {
            _id: object.message.user._id as string,
            avatar: object.message.user.avatar,
            name: object.message.user.name,
          },
          text: object.message.text,
          createdAt: new Date(object.message.createdAt),
          image: object.message.image || "",
          video: object.message.video || "",
          audio: object.message.audio || "",
        },
        createdAt: object.message.createdAt,
      })
    } catch (e) {
      console.log(e)
    }
  }

  // initialization of Websocket connection
  const { ws, state } = useWebSocket(
    onCreate,
    () => {
      __DEV__ && console.log("error in websocket")
    },
    rootStore.sessiontoken,
    1,
    { className: "ChatRoom", where: {}, fields: undefined },
  )

  const showHumanModal = async (userId) => {
    // get or load user before showing modal
    setPatience(true)
    if (userId !== userStore.me.objectId) {
      await userStore.setCurrentUser(userId)
      setVisisbleHumanModal(true)
    }
    setPatience(false)
  }

  const hideHumanModal = () => {
    setVisisbleHumanModal(false)
  }

  const gotoHuman = () => {
    hideHumanModal()
    linkTo("/human")
  }

  const loadMessages = async (radioId) => {
    setLoading(true)
    __DEV__ && console.log("Loading Chatroom Messages into gifted Chat ...")

    const res = await chatRoomStore.getChatRoomMessages(radioId)
    setMessages(GiftedChat.append([], res as IMessage[]))
    __DEV__ && console.log("Done loading Chatroom Messages ...")

    setLoading(false)
  }

  // const invertedWheelEvent = useCallback((e) => {
  //   ref.current._messageContainerRef.currentgetScrollableNode().scrollTop -= e.deltaY
  //   console.log(
  //     "scroll info",
  //     e.deltaMode,
  //     e.deltaY,
  //     e.wheelDeltaY,
  //     ref.current.getScrollableNode().scrollTop,
  //   )
  //   e.preventDefault()
  // }, [])

  // useEffect(() => {
  //   const currentRef = ref.current._messageContainerRef.current
  //   if (currentRef != null) {
  //     currentRef.getScrollableNode().addEventListener("wheel", invertedWheelEvent)

  //     // enable hardware acceleration
  //     // makes scrolling fast in safari and firefox
  //     // https://stackoverflow.com/a/24157294
  //     currentRef.setNativeProps({
  //       style: {
  //         transform: "translate3d(0,0,0) scaleY(-1)",
  //       },
  //     })
  //   }

  //   return () => {
  //     if (currentRef != null) {
  //       currentRef.getScrollableNode().removeEventListener("wheel", invertedWheelEvent)
  //     }
  //   }
  // }, [ref, invertedWheelEvent])
  // useEffect(() => {
  //   const scrollNode = ref.current && ref.current.getScrollableNode()
  //   console.log(scrollNode)

  //   if (!scrollNode) return
  //   const listener = scrollNode.addEventListener("wheel", (e) => {
  //     scrollNode.scrollTop -= e.deltaY
  //     e.preventDefault()
  //   })
  //   ref.current._messageContainerRef.current.setNativeProps({
  //     style: { transform: "translate3d(0,0,0) scaleY(-1)" },
  //   })
  //   return () => scrollNode.removeEventListener("wheel", listener)
  // }) // needs to run any time flatlist mounts

  // load initial messages
  useEffect(() => {
    // TBD limit to 20 or smth. must be done in store
    loadMessages(radioStore.CurrentRadioStationId)
    return () => {
      setMessages([])
    }
  }, [radioStore.CurrentRadioStationId, chatRoomStore.state])

  // to make sure the chat socket is connected
  useEffect(() => {
    console.log(state)
    if (visible && state === "closed") {
      ws.current.reconnect()
    }
  }, [visible])

  // on IOS we need to get the height of the keyboard for gifted chat
  useEffect(() => {
    function onKeyboardDidShow(e: KeyboardEvent): void {
      const offset = e.endCoordinates.height
      setBottomOffset(offset)
    }

    Keyboard.addListener("keyboardDidShow", onKeyboardDidShow)
    return (): void => {
      Keyboard.removeListener("keyboardDidShow", onKeyboardDidShow)
    }
  }, [])

  // send the messages to Parse server
  const onSend = useCallback(
    (messages = []) => {
      // setMessages((previousMessages) => GiftedChat.append(previousMessages, messages))
      messages.map((message) =>
        chatRoomStore.sendMessage(radioStore.CurrentRadioStationId, rootStore.me, message),
      )
    },
    [radioStore.CurrentRadioStationId],
  )

  // the chat message bubble component
  const renderBubble = (props) => {
    const position = props.currentMessage.user._id === rootStore.me ? "right" : "left"

    return (
      <Bubble
        {...props}
        position={position}
        renderUsernameOnMessage={false}
        wrapperStyle={{
          left: {
            backgroundColor: palette.morealphawhite,
            borderRadius: 15,
            marginBottom: 5,
            padding: 5,
            justifyContent: "flex-start",
            marginLeft: spacing[2],
          },
          right: {
            backgroundColor: palette.morealphawhite,
            borderRadius: 15,
            borderBottomRightRadius: 30,
            marginBottom: 10,
            padding: 5,
            marginRight: spacing[3],
            justifyContent: "flex-end",
            alignSelf: "auto",
            marginLeft: 0,
          },
        }}
        renderTime={(props) => (
          <Text style={TIME}>
            {new Date(props.currentMessage.createdAt).toLocaleString(MyLanguage, {
              day: "numeric",
              month: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Text>
        )}
        textStyle={{
          left: { color: palette.darkerpurple, fontSize: typography.size[2] },
          right: { color: palette.darkerpurple, fontSize: typography.size[2] },
        }}
        renderCustomView={() => {
          return (
            <View style={[ROW, VS]}>
              <GiftedAvatar
                user={props.currentMessage.user}
                textStyle={{ fontSize: typography.size[1] }}
                avatarStyle={{ padding: spacing[1], width: spacing[6], height: spacing[6] }}
                onPress={() => {
                  !patience && showHumanModal(props.currentMessage.user._id)
                }}
              />
              <Text style={CHAT_NAME}>{props.currentMessage.user.name}</Text>
            </View>
          )
        }}
      />
    )
  }

  useLayoutEffect(() => {
    if (Platform.OS === "web") {
      console.log("REF: ", ref.current)
      const currentRef = ref.current
      if (ref.current) {
        setTimeout(() => {
          console.log("first step", currentRef._messageContainerRef)
        }, 300)
        if (currentRef._messageContainerRef.current) {
          console.log("second step")

          const scrollNode = ref.current._messageContainerRef.current.getScrollableNode()
          console.log(scrollNode)

          if (!scrollNode) {
            const listener = scrollNode.addEventListener("wheel", (e) => {
              scrollNode.scrollTop -= e.deltaY
              e.preventDefault()
            })
            scrollNode.setNativeProps({
              style: { transform: "translate3d(0,0,0) scaleY(-1)" },
            })
            return () => scrollNode.removeEventListener("wheel", listener)
          }
        }
      }
    }
  })

  if (!visible) return null

  if (!userStore.me || loading) {
    return (
      <View style={{ padding: spacing[3] }}>
        <ActivityIndicator animating={true} color={palette.alphawhite} />
        <Text style={{ textAlign: "center", color: palette.alphawhite }}>Loading...</Text>
      </View>
    )
  }

  if (state !== "subscribed") {
    return (
      <View style={{ padding: spacing[3] }}>
        <ActivityIndicator animating={true} color={palette.alphawhite} />
        <Text style={{ textAlign: "center", color: palette.alphawhite }}>Connecting...</Text>
      </View>
    )
  }

  return (
    <SafeAreaView style={[FLEX1, style]}>
      <GiftedChat
        ref={(giftedChat) => {
          console.log("setting ref")
          ref.current = giftedChat
        }}
        listViewProps={{ showsVerticalScrollIndicator: false }}
        placeholder={translate("writeamessage")}
        messages={messages}
        bottomOffset={Platform.OS === "ios" ? bottomOffset : 0}
        onSend={(messages) => onSend(messages)}
        timeFormat="HH:MM"
        dateFormat="DD.MM.YYYY"
        user={{
          _id: userStore.me.objectId,
          avatar: userStore.me.avatar,
          name: userStore.me.name + " " + userStore.me.lastName,
        }}
        scrollToBottom
        renderUsernameOnMessage={false}
        timeTextStyle={{
          left: { color: palette.darkerpurple, fontSize: typography.size[2] },
          right: { color: palette.darkerpurple, fontSize: typography.size[2] },
        }}
        renderBubble={renderBubble}
        showUserAvatar={false}
        renderAvatar={null}
      />
      {userStore.currentUser && (
        <HumanModal
          user={userStore.currentUser}
          visible={visibleHumanModal}
          dismiss={hideHumanModal}
          goto={gotoHuman}
        />
      )}
    </SafeAreaView>
  )
})
