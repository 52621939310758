/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import {
  Image,
  ImageBackground,
  ImageStyle,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StatusBar,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import { Container, Screen, Text } from "../../components"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { ProfileEventList } from "../../components/profile-event-list/profile-event-list"
import Bugsnag from "@bugsnag/react-native"
import { useNavigation } from "@react-navigation/native"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { useStores } from "../../models"
import { palette } from "../../theme/palette"
import { DEVICE_WIDTH } from "../../utils/device"
import { getStatusBarHeight } from "react-native-iphone-x-helper"
import usePlatform from "../../hooks/usePlatform"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.lighterGrey,
  minHeight: "100%",
  paddingTop: Platform.OS === "ios" ? getStatusBarHeight() + 5 : 0,
}

const TITLE: ViewStyle = {
  backgroundColor: color.palette.lighterGrey,
  borderTopRightRadius: 80,
  flexDirection: "row",
  justifyContent: "space-between",
  paddingVertical: spacing[3],
  paddingLeft: spacing[6],
}

const BODY: ViewStyle = {
  paddingHorizontal: spacing[6],
}

const NAME: TextStyle = {
  fontSize: typography.size[5],
  fontWeight: "bold",
  fontFamily: typography.primary,
}

const SUBNAME: TextStyle = {
  fontSize: typography.size[3],
  fontWeight: "normal",
  color: palette.lightGrey,
  fontFamily: typography.primary,
}

const BIO: TextStyle = {
  fontSize: typography.size[4],
  fontWeight: "normal",
  color: palette.darkerpurple,
  fontFamily: typography.primary,
}

const COVER: ImageStyle = {
  width: DEVICE_WIDTH,
  height: DEVICE_WIDTH,
  justifyContent: "flex-end",
}

export const HumanScreen = observer(function HumanScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Human")

  // Pull in one of our MST stores
  const { userStore } = useStores()

  // Pull in navigation via hook
  const nav = useNavigation()

  const scrollViewRef = useRef<ScrollView>()

  const scrollToBottom = () => {
    scrollViewRef.current.scrollToEnd({ animated: true })
  }

  useEffect(() => {
    userStore.updateUser(userStore.currentUser.objectId)
  }, [])

  if (!userStore.me) return <LoadingScreen />

  return (
    <Screen style={ROOT} preset="scroll">
      <Container>
        <View
          style={{
            backgroundColor: palette.lighterGrey,
            marginTop: 150,
            borderBottomLeftRadius: 100,
            borderTopRightRadius: 100,
          }}
        >
          <Image
            source={{
              uri: userStore.currentUser.avatar || "https://placekitten.com/640/640",
            }}
            resizeMode="contain"
            style={{
              width: 200,
              height: 200,
              borderRadius: 100,
              marginTop: -100,
              marginLeft: 25,
            }}
          />

          <View style={TITLE}>
            <View>
              <Text style={NAME}>
                {userStore.currentUser.name} {userStore.currentUser.lastName}
              </Text>
              <Text style={SUBNAME}>{(userStore.currentUser.home || {}).city}</Text>
              <Text style={SUBNAME}>
                {userStore.currentUser.home.region + ", "}
                {userStore.currentUser.CountryString}
              </Text>
            </View>
          </View>
          <View style={BODY}>
            <Text style={BIO}>{userStore.currentUser.bio}</Text>
            <ProfileEventList
              userId={userStore.currentUser.objectId}
              showPast
              onChangeContentLength={scrollToBottom}
            />
          </View>
        </View>
      </Container>
    </Screen>
  )
})
