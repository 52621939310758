import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Dimensions, Image, ImageStyle, Pressable, TextStyle, View, ViewStyle } from "react-native"
import {
  Button,
  Container,
  Icon,
  ProgramModal,
  RestrictedScreen,
  Screen,
  SideButton,
  Text,
} from "../../components"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { PodcastButton } from "../../components/podcast-button/podcast-button"
import { palette } from "../../theme/palette"
import { spacing, typography } from "../../theme"
import { RadioPickerModal } from "../../components/radio-picker-modal/radio-picker-modal"
import { CIRCLE_BUTTON, VOLUME_CONTAINER } from "./radio-screen-styles"
import Slider from "@react-native-community/slider"
import { ChatRoom } from "../../components/chat-room/chat-room"
import { FLEX1, MB, ROW } from "../../utils/style"
import useMusic from "../../hooks/useMusic"
import * as Animatable from "react-native-animatable"
import { MyView } from "../../components/my-view/my-view"
import { WINDOW_WIDTH } from "../../utils/device"
import useWebSocket from "../../hooks/useWebSocket"

const ROOT: ViewStyle = {
  flex: 1,
}

const SIDE_TAB: ViewStyle = {
  position: "absolute",
  right: 0,
  top: 0,
  width: 30,
  zIndex: 500,
}

const PICKER: ViewStyle = {
  flex: 1,
}
const METADATA_CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "space-around",
  alignItems: "center",
  marginHorizontal: spacing[4],
  paddingVertical: spacing[3],
}
const VOLUME_SLIDER: ViewStyle = {
  padding: spacing[5],
}

const TRACK: TextStyle = {
  fontFamily: typography.secondary,
  fontSize: typography.size[5],
  color: palette.white,
  textAlign: "center",
  flex: 1,
}

const TRACK_OPEN: TextStyle = {
  fontFamily: typography.secondary,
  fontSize: typography.size[4],
  color: palette.white,
}

export const ARTIST: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[4],
  color: palette.alphawhite,
  flex: 1,
}

export const ARTIST_OPEN: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: palette.alphawhite,
}

const BOTTOM_CONTAINER: ViewStyle = {
  backgroundColor: palette.darkerpurple,
  // justifyContent: "space-between",
  borderTopLeftRadius: 50,
  borderTopRightRadius: 50,
  alignItems: "center",
  flex: 1,
}

const CHAT_PULL: ViewStyle = {
  padding: spacing[2],
  alignSelf: "center",
  justifyContent: "center",
}

const CHAT_WRAPPER: ViewStyle = {
  // backgroundColor: palette.darkerpurple,
  position: "absolute",
  width: "100%",
  bottom: 0,
  height: 50,
}

const CHAT_CONTAINER: ViewStyle = {
  backgroundColor: palette.darkpurple,
  borderTopLeftRadius: 50,
  borderTopRightRadius: 50,
  flex: 1,
}

const RADIO_OPEN: ViewStyle = {
  flexDirection: "row",
  // justifyContent: "center",
  // alignItems: "flex-start",
  paddingHorizontal: spacing[1],
}

const RADIO_CLOSED: ViewStyle = {
  flex: 6,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}

const META_OPEN: ViewStyle = {
  alignItems: "flex-start",
  flex: 3,
}

const META_CLOSED: ViewStyle = {
  alignItems: "center",
}

const TOP_PLAY_BUTTON: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}

const ART: ImageStyle = {
  width: 250,
  height: 250,
  resizeMode: "contain",
  borderRadius: spacing[5],
}

const MOB_ART: ImageStyle = {
  width: 200,
  height: 200,
  resizeMode: "contain",
  borderRadius: spacing[5],
}

const ART_WRAP: ViewStyle = {
  // width: 300,
  // height: 300,
  alignSelf: "center",
  //   borderRadius: spacing[5],
  // backgroundColor: palette.morealphawhite,
}

const MAIN: ViewStyle = {
  // height: DEVICE_HEIGTH - 300,
  flex: 1,
}

const SIDE: ViewStyle = {
  width: 40,
  height: "100%",
  // backgroundColor: palette.morealphawhite,
}

const NOSIDE: ViewStyle = {
  width: 0,
  height: "100%",
  // backgroundColor: palette.morealphawhite,
}

const CENTER: ViewStyle = {
  width: WINDOW_WIDTH - 80,
  height: "100%",
  // backgroundColor: palette.alphablack,
}

const TOTAL_CENTER: ViewStyle = {
  width: WINDOW_WIDTH,
  height: "100%",
  // backgroundColor: palette.alphablack,
}

const SIDE_TAB_OPEN: ViewStyle = {
  marginTop: 300,
}

const SIDE_TAB_CLOSED: ViewStyle = {
  // top: 150,
}

const BOTTOM: ViewStyle = {
  flex: 0.3,
}

export const RadioScreen = observer(function RadioScreen() {
  const rootStore = useStores()
  const { radioStore, programStore, userStore } = useStores()

  const { volume, playMusic, stopMusic } = useMusic()

  const nav = useNavigation()
  const linkTo = useLinkTo()

  const [chatVisible, setChatVisible] = useState(false)

  const [metaStyle] = useState<ViewStyle>({ alignItems: "center" })

  const [pickerStyle] = useState<ViewStyle>({ paddingVertical: spacing[3] })
  const [trackStyle] = useState<TextStyle>({ textAlign: "center" })
  const [width, setWidth] = useState<number>(Dimensions.get("window").width)
  const [height, setHeight] = useState<number>(Dimensions.get("window").height)

  useEffect(() => {
    Dimensions.addEventListener("change", ({ window }) => {
      setWidth(window.width)
      setHeight(window.height)
    })
    return () => {
      Dimensions.removeEventListener("change", ({ window }) => {
        setWidth(window.width)
        setHeight(window.height)
      })
    }
  }, [])

  function gotoPodcasts() {
    linkTo("/radio/media")
  }

  const toggleChat = () => {
    setChatVisible(!chatVisible)
  }

  async function tuneIn(name) {
    if (name !== radioStore.CurrentRadioStationName) {
      if (radioStore.isPlaying) {
        await radioStore.setRadioStationByName(name)
        await playRadio()
      } else {
        await radioStore.setRadioStationByName(name)
      }
    }
  }

  const playRadio = async () => {
    console.log("play")
    playMusic(radioStore.currentRadioStation.stream_url)
    await radioStore.getNowPlaying()
    radioStore.setIsPlaying(true)
  }

  const stopRadio = async () => {
    stopMusic()
    radioStore.setIsPlaying(false)
    radioStore.resetNowPlaying()
  }

  // // our websocket for listening to song changes
  // const socket = new WebSocket(PARSE_SERVER_WS_URL, null, {
  //   headers: {
  //     "X-Parse-Application-Id": PARSE_APPLICATION_ID,
  //     "X-Parse-Client-Key": PARSE_CLIENT_KEY,
  //   },

  // })

  const onCreate = (object) => {
    if (object.radio === radioStore.currentRadioStation.radio_id) {
      setTimeout(() => {
        radioStore.setNowPlaying(object.artist, object.title, object.artwork)
      }, 6000)
    }
  }

  useWebSocket(
    onCreate,
    () => __DEV__ && console.log("error in Radio Socket"),
    rootStore.sessionToken,
    2,
    { className: "Song", where: {}, fields: undefined },
  )

  useEffect(() => {
    if (!userStore.me) {
      userStore.setMe(rootStore.dirtyMe)
    }
  }, [])

  if (!radioStore || !userStore.me || !radioStore.currentRadioStation) {
    return <LoadingScreen />
  }

  const side = width > 650
  const big = width > 500

  if (width > height) {
    return (
      <RestrictedScreen style={ROOT} preset="fixed">
        <Container gradient fixed>
          <View style={[side && chatVisible && ROW, FLEX1]}>
            <Animatable.View
              easing="ease-in-out-cubic"
              style={[
                side && !chatVisible ? FLEX1 : { flex: 0.5 },
                // { borderColor: palette.darkpurple, borderRightWidth: 5 },
              ]}
            >
              {/** This is the radio Column */}
              <View style={SIDE_TAB}>
                {programStore.state === "done" && <ProgramModal />}
                {radioStore.currentRadioStation.hasPodcasts && (
                  <PodcastButton onPress={gotoPodcasts} />
                )}
                <SideButton tx="chat.open" onPress={toggleChat} />
              </View>
              <RadioPickerModal style={[PICKER, pickerStyle]} onChoose={(name) => tuneIn(name)} />
              <View style={{ alignItems: "center", justifyContent: "center" }}>
                {radioStore.isPlaying && radioStore.nowPlaying ? (
                  <>
                    <Image
                      style={ART}
                      source={{
                        uri: radioStore.nowPlaying.artwork,
                      }}
                    />
                    <View style={[METADATA_CONTAINER, metaStyle]}>
                      <Text style={[TRACK, trackStyle]}>{radioStore.nowPlaying.title}</Text>
                      <Text style={[ARTIST, trackStyle]}>{radioStore.nowPlaying.artist}</Text>
                    </View>
                  </>
                ) : (
                  <>
                    <Image
                      style={ART}
                      source={{
                        uri: radioStore.currentRadioStation.logo_url,
                      }}
                    />
                    <View style={[METADATA_CONTAINER, metaStyle]}>
                      <Text style={[TRACK, trackStyle]}>{radioStore.currentRadioStation.name}</Text>
                    </View>
                  </>
                )}
              </View>
              <View /* hide={chatVisible} */ style={VOLUME_CONTAINER}>
                <Slider
                  maximumValue={1}
                  minimumValue={0}
                  value={0.5}
                  step={0.01}
                  style={[VOLUME_SLIDER, FLEX1, { width: "60%" }]}
                  thumbTintColor={palette.lighterGrey}
                  minimumTrackTintColor={palette.orange}
                  maximumTrackTintColor="#000000"
                  onValueChange={(value) => {
                    volume(value)
                  }}
                ></Slider>
              </View>
              <View style={BOTTOM_CONTAINER}>
                <View>
                  {radioStore.isPlaying ? (
                    <Button style={CIRCLE_BUTTON} onPress={stopRadio}>
                      <Icon name="pause" size={24} color="white" />
                    </Button>
                  ) : (
                    <Button style={CIRCLE_BUTTON} onPress={playRadio}>
                      <Icon name="play" size={24} color="white" />
                    </Button>
                  )}
                </View>
              </View>
              {/** END RADIO COLUMN */}
            </Animatable.View>
            {chatVisible && (
              <Animatable.View
                // animation="slideInRight"
                style={[
                  side && !chatVisible ? FLEX1 : { flex: 0.5 },
                  { backgroundColor: palette.darkpurple },
                ]}
              >
                {/** This is the Chat column */}
                <ChatRoom
                  nav={nav}
                  me={userStore.me}
                  reqId={2}
                  sessiontoken={rootStore.sessiontoken}
                  visible={chatVisible}
                />
                {/** End Chat column */}
              </Animatable.View>
            )}
          </View>
        </Container>
      </RestrictedScreen>
    )
  } else {
    return (
      <RestrictedScreen style={ROOT} preset="fixed">
        <Container fixed gradient>
          <View style={[MAIN, ROW]}>
            <View style={chatVisible ? NOSIDE : SIDE}></View>
            <View style={chatVisible ? TOTAL_CENTER : CENTER}>
              <RadioPickerModal
                style={
                  chatVisible
                    ? [{ display: "none" }]
                    : [{ display: "flex", flex: 1, marginTop: 20 }]
                }
                onChoose={(id) => tuneIn(id)}
              />
              <View
                style={[
                  chatVisible ? RADIO_OPEN : RADIO_CLOSED,
                  chatVisible ? { height: height * 0.15 } : { height: "100%" },
                ]}
              >
                <View
                  style={[
                    ART_WRAP,
                    // { alignItems: "center", justifyContent: "center" },
                    chatVisible ? META_OPEN : META_CLOSED,
                    chatVisible && ROW,
                  ]}
                >
                  {radioStore.isPlaying && radioStore.nowPlaying ? (
                    <>
                      {/* <View style={[chatVisible ? ART_OPEN : ART_CLOSED, FLEX1]}> */}
                      <Image
                        source={{
                          uri: radioStore.nowPlaying.artwork,
                        }}
                        style={[
                          MOB_ART,
                          chatVisible && { height: 70, width: 70, borderRadius: 10 },
                        ]}
                      />
                      <View style={[METADATA_CONTAINER, chatVisible ? META_OPEN : META_CLOSED]}>
                        <Text style={chatVisible ? TRACK_OPEN : TRACK}>
                          {radioStore.nowPlaying.title}
                        </Text>
                        <Text style={chatVisible ? ARTIST_OPEN : ARTIST}>
                          {radioStore.nowPlaying.artist}
                        </Text>
                      </View>
                    </>
                  ) : (
                    <>
                      <Image
                        source={{
                          uri: radioStore.currentRadioStation.logo_url,
                        }}
                        style={[
                          MOB_ART,
                          chatVisible && { height: 70, width: 70, borderRadius: 10 },
                        ]}
                      />
                      <View style={[METADATA_CONTAINER, chatVisible ? META_OPEN : META_CLOSED]}>
                        <Text style={chatVisible ? TRACK_OPEN : TRACK}>
                          {radioStore.currentRadioStation.name}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
                <MyView style={TOP_PLAY_BUTTON} hide={!chatVisible}>
                  {radioStore.isPlaying ? (
                    <Button style={CIRCLE_BUTTON} onPress={stopRadio}>
                      <Icon name="pause" size={30} color="white" />
                    </Button>
                  ) : (
                    <Button style={CIRCLE_BUTTON} onPress={playRadio}>
                      <Icon name="play" size={30} color="white" />
                    </Button>
                  )}
                </MyView>
              </View>
              <View style={[VOLUME_CONTAINER]}>
                <Slider
                  maximumValue={1}
                  minimumValue={0}
                  value={0.5}
                  step={0.01}
                  style={[VOLUME_SLIDER, FLEX1, { width: big ? "50%" : "90%" }]}
                  thumbTintColor={palette.lighterGrey}
                  minimumTrackTintColor={palette.orange}
                  maximumTrackTintColor="#000000"
                  onValueChange={(value) => {
                    volume(value)
                  }}
                ></Slider>
              </View>
            </View>
            <View style={chatVisible ? NOSIDE : SIDE}>
              <View style={{ flex: 1 }}></View>
              <View style={[{ flex: 6 }, chatVisible ? SIDE_TAB_OPEN : SIDE_TAB_CLOSED]}>
                {programStore.state === "done" && <ProgramModal />}
                {radioStore.currentRadioStation.hasPodcasts && (
                  <PodcastButton onPress={gotoPodcasts} />
                )}
              </View>
            </View>
          </View>
          <View style={BOTTOM}>
            <View style={BOTTOM_CONTAINER}>
              {radioStore.isPlaying ? (
                <Button style={CIRCLE_BUTTON} onPress={stopRadio}>
                  <Icon name="pause" size={30} color="white" />
                </Button>
              ) : (
                <Button style={CIRCLE_BUTTON} onPress={playRadio}>
                  <Icon name="play" size={30} color="white" />
                </Button>
              )}
            </View>
            <View
              style={[CHAT_WRAPPER, chatVisible ? { height: height * 0.775 } : { height: "33%" }]}
            >
              <View style={CHAT_CONTAINER}>
                <Pressable onPress={toggleChat} style={{ alignItems: "center" }}>
                  <View style={CHAT_PULL}>
                    {chatVisible ? (
                      <Icon name={"chevron-down"} />
                    ) : (
                      <Icon name={"message-circle"} size={20} />
                    )}
                  </View>
                </Pressable>
                <ChatRoom
                  style={{ borderTopLeftRadius: 50, borderTopRightRadius: 50 }}
                  me={userStore.me}
                  reqId={2}
                  sessiontoken={rootStore.sessiontoken}
                  visible={chatVisible}
                  nav={nav}
                />
              </View>
            </View>
          </View>
        </Container>
      </RestrictedScreen>
    )
  }
})
