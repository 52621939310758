import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, View, ViewStyle } from "react-native"
import { Button, Container, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
import { color, spacing, typography } from "../../theme"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { useStores } from "../../models"
import { MyLanguage } from "../../i18n/i18n"
import { palette } from "../../theme/palette"
import { FLEX1, MB } from "../../utils/style"
import usePlatform from "../../hooks/usePlatform"
import { openUrl } from "../../utils/openUrl"
import WebView from "react-native-webview"
import { MyModal } from "../../components/my-modal/my-modal"
import { WINDOW_WIDTH } from "../../utils/device"
import { FAB } from "react-native-paper"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
}

const FAB_STYLE: ViewStyle = {
  position: "absolute",
  margin: 16,
  right: 0,
  bottom: 0,
  zIndex: 500,
  backgroundColor: palette.orange,
}

const TEXT_CONTAINER: ViewStyle = {
  padding: spacing[3],
  backgroundColor: palette.white,
}

const TEXT: TextStyle = { color: palette.darkpurple, fontSize: typography.size[3] }

export const PrivacyScreen = observer(function PrivacyScreen() {
  const { metaStore } = useStores()
  const { isWeb } = usePlatform()
  const nav = useNavigation()
  const [privacy, setPrivacy] = useState(false)
  const [terms, setTerms] = useState(false)
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    if (metaStore) {
      const meta = metaStore.getMeta(MyLanguage)
      if (meta) {
        setMeta(meta.privacy)
      } else {
        setMeta(metaStore.getMeta("en").privacy)
      }
    }
  }, [metaStore])

  const linkToPrivacy = () => {
    isWeb ? openUrl("https://universalapp.ch/datenschutz") : setPrivacy(true)
  }

  const linkToTerms = () => {
    isWeb ? openUrl("https://universalapp.ch/nutzungsbedingungen") : setTerms(true)
  }
  if (!metaStore || metaStore.state === "pending" || !meta) return <LoadingScreen />

  return (
    <Screen style={[ROOT, isWeb && FLEX1]} preset="scroll">
      <Container>
        {!isWeb && <UniversalHeader nav={nav} colorful />}
        <View style={TEXT_CONTAINER}>
          <Text style={[TEXT, { marginBottom: spacing[4] }]}>{meta}</Text>
          <Button
            style={MB}
            preset="secondary"
            onPress={linkToPrivacy}
            tx="sub.disclaimer.privacy"
          />
          <Button preset="secondary" onPress={linkToTerms} tx="sub.disclaimer.terms" />
          <MyModal visible={privacy} dismiss={() => setPrivacy(false)} dismissable>
            <WebView
              originWhitelist={["*"]}
              source={{ uri: "https://universalapp.ch/datenschutz" }}
              style={{ flex: 1, width: WINDOW_WIDTH - 40 }}
              scrollEnabled
            />
            <FAB small icon="close" style={FAB_STYLE} onPress={() => setPrivacy(false)} />
          </MyModal>
          <MyModal visible={terms} dismiss={() => setTerms(false)} dismissable>
            <WebView
              originWhitelist={["*"]}
              source={{ uri: "https://universalapp.ch/nutzungsbedingungen" }}
              style={{ flex: 1, width: WINDOW_WIDTH - 40 }}
              scrollEnabled
            />
            <FAB small icon="close" style={FAB_STYLE} onPress={() => setTerms(false)} />
          </MyModal>
        </View>
      </Container>
    </Screen>
  )
})
