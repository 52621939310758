/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { ActivityIndicator, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { palette } from "../../theme/palette"
import { Screen } from "../screen/screen"
import { Container } from "../container/container"

export interface LoadingScreenProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

/**
 * Describe your component here
 */
export const LoadingScreen = observer(function LoadingScreen(props: LoadingScreenProps) {
  return (
    <Screen style={{ flex: 1 }} preset="fixed">
      <Container
        style={{ justifyContent: "center", alignItems: "center" }}
        gradient
        menuShown={false}
      >
        <ActivityIndicator animating={true} color={palette.purple} size={30} />
      </Container>
    </Screen>
  )
})
