/* eslint-disable indent */
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { openUrl } from "../../utils/openUrl"
import { withRootStore } from "../extensions/with-root-store"

/**
 * Model description here for TypeScript hints.
 */
export const SubscriptionModel = types
  .model("Subscription")
  .props({
    platform_product_id: types.identifier,
    platform: types.enumeration(["ios", "android", "stripe"]),
    degree: types.enumeration(["earth", "sun", "universal"]),
    interval: types.enumeration(["month", "year"]),
  })
  .extend(withRootStore)
  .views((self) => ({
    get price() {
      if (self.interval === "month") {
        return self.degree === "earth" ? 5 : self.degree === "sun" ? 8 : 13
      } else {
        return self.degree === "earth" ? 55 : self.degree === "sun" ? 89 : 145
      }
    },
    get icon() {
      let icon = self.degree
      if (self.degree === "earth") {
        icon = "globe"
      }
      if (self.degree === "universal") {
        icon = "heart"
      }
      return icon
    },
    get maticon() {
      let icon = self.degree
      if (self.degree === "universal") {
        icon = "heart"
      }
      return icon
    },
    get managementUrl() {
      return self.platform === "android"
        ? "https://play.google.com/store/account/subscriptions"
        : self.platform === "ios"
        ? "itms-apps://apps.apple.com/account/subscriptions"
        : `https://universalapp-purchases.herokuapp.com/portal/${self.rootStore.sessiontoken}`
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    manage() {
      openUrl(self.managementUrl)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

type SubscriptionType = Instance<typeof SubscriptionModel>
export interface Subscription extends SubscriptionType {}
type SubscriptionSnapshotType = SnapshotOut<typeof SubscriptionModel>
export interface SubscriptionSnapshot extends SubscriptionSnapshotType {}
export const createSubscriptionDefaultModel = () => types.optional(SubscriptionModel, {})
