/* eslint-disable generator-star-spacing */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { translate } from "../../i18n"
import { MyLanguage } from "../../i18n/i18n"
import { GetGeneralResult, GetGeneralResults } from "../../services/api"
import { ParseApi } from "../../services/api/parse-api"
import { withEnvironment } from "../extensions/with-environment"
import { RadioStationDetailModel } from "../radio-station-detail/radio-station-detail"
import { RadioStationModel } from "../radio-station/radio-station"
import { TrackModel } from "../track/track"

/**
 * Model description here for TypeScript hints.
 */
export const RadioStoreModel = types
  .model("RadioStore")
  .props({
    currentRadioStation: types.maybe(types.reference(RadioStationModel)),
    radioStationDetails: types.optional(types.array(RadioStationDetailModel), []),
    radioStations: types.optional(types.array(RadioStationModel), []),
    nowPlaying: types.maybe(TrackModel),
    history: types.optional(types.array(TrackModel), []),
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    // Bools
    isPlaying: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get CurrentRadioStationId() {
      return self.currentRadioStation.objectId
    },
    get CurrentRadioStationIndex() {
      return self.currentRadioStation.index
    },
    get CurrentRadioStationLogo() {
      return self.currentRadioStation.logo_url
    },
    get CurrentRadioStationName() {
      return self.currentRadioStation.name
    },
  }))
  .actions((self) => ({
    load: flow(function* load() {
      console.log("loading Radio...")
      self.state = "pending"
      // try {
      // const parseApi = new ParseApi(self.environment.parseApi)
      const parseApi = new ParseApi()

      let result: GetGeneralResults = yield parseApi.getAllOfClass("RadioStation")

      if (result.kind === "ok") {
        self.radioStations = result.data.results.map((station, index) => {
          return {
            name: station.name,
            index: index + 1,
            objectId: station.objectId as string,
            stream_url: station.stream_url,
            language: station.language,
            logo_url: station.logo_url,
            radio_id: station.radio_id,
            hasPodcasts: station.podcasts,
            about: station.about,
            about_image: station.about_image,
            contact: station.contact,
            humans: station.Links,
          }
        })

        const initialStation = self.radioStations.find((station) => station.language === MyLanguage)
        self.currentRadioStation = initialStation
          ? initialStation.objectId
          : (self.radioStations[0].objectId as any)
      } else {
        __DEV__ && console.error(result)
        self.state = "error"
      }
      // eslint-disable-next-line array-callback-return

      console.log("loading RadioStationDetails from", self.CurrentRadioStationId)

      result = yield parseApi.getAllOfClass("RadioStationDetail")

      if (result.kind === "ok") {
        // save()
        self.radioStationDetails = result.data.results.map((radioStationDetail) => {
          return {
            objectId: radioStationDetail.objectId,
            title: radioStationDetail.title,
            time: radioStationDetail.time,
            info: radioStationDetail.info,
            radio_id: radioStationDetail.radio_id,
            timetable: radioStationDetail.timetable,
          }
        }) as any
      } else {
        __DEV__ && console.log(result.kind)
        self.state = "error"
      }

      // first The Programs of the current RadioStation

      self.state = "done"
      console.log("...Done loading Radio")
      //} catch (error) {
      //self.state = "error"
      //console.log(
      // `Failed to retrieve Radiostations from Server with load, with error code: ${error.message}`,
      //)
      //}
    }),
    async afterCreate() {
      await this.load()
      await this.getNowPlaying()
      this.setIsPlaying(false)
    },
    getNowPlaying: flow(function* getNowPlaying() {
      if (self.currentRadioStation && self.state !== "pending") {
        console.log("getting now Playing from ", self.currentRadioStation.name)

        self.state = "pending"
        if (self.history.length > 0) {
          self.history.length = 0
        }
        try {
          const parseApi = new ParseApi()
          const result: GetGeneralResults = yield parseApi.getSomeOfClass(
            "Song",
            {
              radio: self.currentRadioStation.radio_id,
            },
            1,
            "-createdAt",
          )

          if (result.kind === "ok" && result.data.results.length > 0) {
            self.nowPlaying = {
              artist: result.data.results[0].artist,
              title: result.data.results[0].title,
              artwork: result.data.results[0].artwork,
            }
          } else {
            // as a fallback we load the error stuff
            self.nowPlaying = {
              artist: translate("radio.noSongsError"),
              title: translate("radio.noSongsErrorSorry"),
              artwork: self.currentRadioStation.logo_url,
            }
            self.history.length = 0
          }
          // for (const x in songs) {
          //   if (x === "0") {
          //     self.nowPlaying = {
          //       artist: songs[x].attributes.artist,
          //       title: songs[x].attributes.title,
          //       artwork: songs[x].attributes.artwork,
          //     }
          //   }
          //   self.history.unshift({
          //     artist: songs[x].attributes.artist,
          //     title: songs[x].attributes.title,
          //     artwork: songs[x].attributes.artwork,
          //   })
          // }
          // console.log(self.history)
          self.state = "done"
        } catch (error) {
          console.log("Failed to getNowPlaying:", error)
          self.state = "error"
        }
      }
    }),
    setNowPlaying(artist, title, artwork) {
      console.log("updateing new Song")
      self.nowPlaying = {
        artist: artist,
        title: title,
        artwork: artwork,
      }
    },
    setTrack(artist, title) {
      console.log("updateing new Track")
      self.nowPlaying = {
        artist: artist,
        title: title,
        artwork: self.nowPlaying.artwork,
      }
    },
    resetNowPlaying() {
      self.nowPlaying = undefined
    },
    historyPush(artist, title, artwork) {
      self.history.unshift({
        artist: artist,
        title: title,
        artwork: artwork,
      })
      self.history.pop()
    },
    setIsPlaying(bool: boolean) {
      self.isPlaying = bool
    },
    setRadioStation(id: string) {
      console.log("tuning in to Radiostation with id", id)
      self.state = "pending"
      try {
        self.currentRadioStation = id as any
        self.state = "done"
      } catch (error) {
        console.log("Failed to tune into Radiostation with id: ", id, " error: ", error)
        self.state = "error"
      }
    },
    setRadioStationByIndex(index: number) {
      console.log("tuning in to Radiostation with index", index)
      self.state = "pending"
      try {
        self.currentRadioStation = self.radioStations.find((radio) => radio.index === index) as any
        self.state = "done"
      } catch (error) {
        console.log("Failed to tune into Radiostation with index: ", index, " error: ", error)
        self.state = "error"
      }
    },
    setRadioStationByName(name: string) {
      console.log("tuning in to Radiostation with name ", name)
      self.state = "pending"
      try {
        self.currentRadioStation = self.radioStations.find((radio) => radio.name === name) as any
        self.state = "done"
      } catch (error) {
        console.log("Failed to tune into Radiostation with name: ", name, " error: ", error)
        self.state = "error"
      }
    },
    setLoadError() {
      self.nowPlaying = {
        artist: translate("radio.connectionErrorSorry"),
        title: translate("radio.connectionError"),
        artwork: self.currentRadioStation.logo_url,
      }
      self.history.length = 0
    },
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type RadioStoreType = Instance<typeof RadioStoreModel>
export interface RadioStore extends RadioStoreType {}
type RadioStoreSnapshotType = SnapshotOut<typeof RadioStoreModel>
export interface RadioStoreSnapshot extends RadioStoreSnapshotType {}
