import { Linking } from "react-native"
import { canUseDOM } from "fbjs/lib/ExecutionEnvironment"

export const openUrl = (url) => {
  if (canUseDOM) {
    window.open(url, "_blank", "noopener")
  } else {
    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          console.log("Can't handle url: " + url)
        } else {
          return Linking.openURL(url)
        }
      })
      .catch((err) => console.error("An error occurred", err))
  }
}
