/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react"
import { Platform, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import * as Yup from "yup"
import { Formik } from "formik"
import { useStores } from "../../models"
import { translate } from "../../i18n"
import * as ImagePicker from "expo-image-picker"
import { Page1 } from "./pages/Page1"
import { Page2 } from "./pages/Page2"
import { Page3 } from "./pages/Page3"
import { Page4 } from "./pages/Page4"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import format from "date-fns/format"
import { EventFormStepValidation } from "../event-form-step-validation/event-form-step-validation"
import { Page3b } from "./pages/Page3b"
import { useToast } from "react-native-toast-notifications"

const validationSchema = Yup.object().shape({
  name: Yup.string().required(translate("event.required.name")).min(2, "min. 2").max(50, "max. 50"),
  image: Yup.string().url("invalid image url").required(translate("event.required.image")),
  startUTC: Yup.date().min(new Date(new Date().getTime() - 1000 * 60 * 60 * 24)),
  startDate: Yup.string()
    .required(translate("event.required.startDate"))
    .matches(
      /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/,
      translate("event.match.date"),
    ),
  startTime: Yup.string()
    .required(translate("event.required.startTime"))
    .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, translate("event.match.time")),
  endTime: Yup.string()
    .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, translate("event.match.time"))
    .nullable(),
  endDate: Yup.string()
    .matches(
      /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/,
      translate("event.match.date"),
    )
    .nullable(),
  endUTC: Yup.date().nullable(),
  placeId: Yup.string().required(translate("event.required.place")),
  desc: Yup.string().required(translate("event.required.desc")),
  tags: Yup.array().required(translate("event.required.tags")),
})

export interface EventFormValues {
  name: string
  image: string
  startUTC: Date
  startDate: string
  startTime: string
  hasEndDate: boolean
  endUTC?: Date
  endDate?: string
  endTime?: string
  placeId: string
  isOnline: boolean
  desc: string
  tags: { id: string; item: string }[]
}

export interface EventFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  initialValues?
  action?: "create" | "edit"
}

/**
 * Describe your component here
 */
export const EventForm = observer(function EventForm(props: EventFormProps) {
  const rootStore = useStores()
  const { eventStore } = useStores()
  const toast = useToast()
  const { style, initialValues, action = "create" } = props
  const nav = useNavigation()
  const linkTo = useLinkTo()

  const [page, setPage] = useState(0)

  if (!eventStore) return null

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== "granted") {
        console.log("Sorry, we need camera roll permissions to make this work!")
        nav.goBack()
      }
    })()
  }, [])

  const nextPage = () => setPage(page + 1)
  const previousPage = () => setPage(page - 1)

  let defaultValues: EventFormValues = {
    name: "",
    image: "",
    startUTC: new Date(),
    startDate: new Date().toLocaleDateString("de", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    startTime: "12:00",
    hasEndDate: false,
    endUTC: new Date(),
    endDate: new Date().toLocaleDateString("de", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    endTime: "12:00",
    placeId: "",
    isOnline: false,
    desc: "",
    tags: [],
  }

  if (initialValues) {
    console.log(initialValues)
    defaultValues = { ...initialValues }
    defaultValues.startTime = format(initialValues.startDate, "HH:mm")
    defaultValues.startDate = initialValues.startDate.toLocaleDateString("de", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    defaultValues.startUTC = new Date(initialValues.startDate)
    if (initialValues.endDate !== null) {
      defaultValues.hasEndDate = true
      defaultValues.endTime = format(initialValues.endDate, "HH:mm")
      defaultValues.endUTC = new Date(initialValues.endDate)
      defaultValues.endDate = initialValues.endDate.toLocaleDateString("de", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    }
    defaultValues.placeId = initialValues.place_id.objectId
  }

  return (
    <Formik<EventFormValues>
      initialValues={defaultValues}
      onSubmit={async (values, actions) => {
        // lets make shure the times are good
        actions.setSubmitting(true)

        let response
        action === "create"
          ? (response = await eventStore.createEvent(values, rootStore.me))
          : (response = await eventStore.updateEvent(values, initialValues.objectId))
        if (response !== "success") {
          // Toast.showWithGravity(response, Toast.LONG, Toast.TOP, ["UIAlertController"])
          toast.show(response)
          actions.setSubmitting(false)
        } else {
          await eventStore.load()
          actions.setSubmitting(false)
          // nav.navigate("Events")
          linkTo("/events")
        }
      }}
      validationSchema={validationSchema}
    >
      {(
        {
          handleChange,
          values,
          submitForm,
          errors,
          isValid,
          isSubmitting,
          touched,
          handleBlur,
          setFieldValue,
        },
        pages = [
          <Page1
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            nextPage={nextPage}
          />,
          <Page2
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            nextPage={nextPage}
            previousPage={previousPage}
          />,
          <Page3
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            nextPage={nextPage}
            previousPage={previousPage}
          />,
          <Page3b
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            nextPage={nextPage}
            previousPage={previousPage}
          />,
          <Page4
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            previousPage={previousPage}
            submitForm={submitForm}
            action={action}
            isSubmitting={isSubmitting}
            isValid={isValid}
          />,
        ],
      ) => (
        <>
          <EventFormStepValidation active={page + 1} errors={errors} />
          {pages[page]}
        </>
        /* <View style={BOTTOM}>
            {steps(errors)}
            {page === pages.length - 1 ? (
              <>
                <View style={HALF_BUTTON}>
                  <Button
                    style={BUTTON}
                    onPress={previousPage}
                    preset="secondary"
                    tx="event.back"
                  />

                  <Button
                    style={BUTTON}
                    onPress={() => {
                      console.log(values)
                      console.log(errors)

                      submitForm()
                    }}
                    // disabled={!isValid || isSubmitting}
                    preset="primary"
                    tx={"event.action." + action}
                  />
                </View>
              </>
            ) : (
              <>
                {page > 0 ? (
                  <View style={HALF_BUTTON}>
                    <Button
                      style={BUTTON}
                      onPress={previousPage}
                      preset="secondary"
                      tx="event.back"
                    />
                    <Button style={BUTTON} onPress={nextPage} preset="secondary" tx="event.next" />
                  </View>
                ) : (
                  <Button style={BUTTON} onPress={nextPage} preset="secondary" tx="event.next" />
                )}
              </>
            )}
          </View>
        </View> */
      )}
    </Formik>
  )
})
