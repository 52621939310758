import React, { useEffect, useState } from "react"
import { Modal, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Icon } from "../icon/icon"
import { useStores } from "../../models"
import { palette } from "../../theme/palette"
import { PodcastSeekBar } from "../podcast-seek-bar/podcast-seek-bar"
import * as Animatable from "react-native-animatable"
import { Text } from "../text/text"
import useMusic from "../../hooks/useMusic"
import { useAudioPosition } from "react-use-audio-player"
import { Divider, Portal } from "react-native-paper"
import { AVPlaybackStatus } from "expo-av"
export interface PodcastPlayerProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  onStop: any
  onToggle: any
  status: AVPlaybackStatus
  onSeek: any
}

function ControlButton({ title, onPress }) {
  return (
    <TouchableOpacity style={styles.controlButtonContainer} onPress={onPress}>
      <Icon name={title} size={24} color={palette.purple} />
    </TouchableOpacity>
  )
}

/**
 * Describe your component here
 */
export const PodcastPlayer = observer(function PodcastPlayer(props: PodcastPlayerProps) {
  const { style, onStop, onToggle, status, onSeek } = props
  const { podcastStore } = useStores()

  return (
    <Portal>
      {status && status.isLoaded && status.uri !== "" && (
        <View style={[style, styles.card]}>
          <Text style={styles.title}>{podcastStore.currentPodcast.title}</Text>
          <View style={styles.controls}>
            <ControlButton title={status.isPlaying ? "pause" : "play"} onPress={onToggle} />
            <View style={{ flex: 4 }}>
              <PodcastSeekBar status={status} onSeek={onSeek} />
            </View>
            <ControlButton title={"square"} onPress={onStop} />
          </View>
        </View>
      )}
    </Portal>
  )
})

const styles = StyleSheet.create({
  artist: {
    fontWeight: "bold",
  },
  card: {
    alignItems: "center",
    backgroundColor: palette.white,
    borderTopLeftRadius: spacing[3],
    borderTopRightRadius: spacing[3],
    elevation: 5,
    padding: spacing[3],
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    flex: 1,
  },
  controlButtonContainer: {
    alignItems: "center",
    flex: 1,
  },
  controlButtonText: {
    fontSize: 18,
    textAlign: "center",
  },
  controls: {
    flexDirection: "row",
    marginVertical: 20,
    width: "100%",
    alignItems: "stretch",
  },
  cover: {
    backgroundColor: "grey",
    height: 140,
    marginTop: 20,
    width: 140,
  },
  progress: {
    flexDirection: "row",
    height: 3,
    marginTop: 10,
    width: "90%",
  },
  title: {
    fontSize: 18,
    margin: 10,
  },
})
