/* eslint-disable generator-star-spacing */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { Platform } from "react-native"
import { GetGeneralResults } from "../../services/api"
import { ParseApi } from "../../services/api/parse-api"
import { withEnvironment } from "../extensions/with-environment"
import { ProgramDetailModel } from "../program-detail/program-detail"
import { ProgramOverviewModel } from "../program-overview/program-overview"
import { ProgramModel } from "../program/program"

/**
 * Model description here for TypeScript hints.
 */
export const ProgramStoreModel = types
  .model("ProgramStore")
  .props({
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    programs: types.optional(types.array(ProgramModel), []),
    programDetails: types.optional(types.array(ProgramDetailModel), []),
    programOverviews: types.optional(types.array(ProgramOverviewModel), []),
    currentProgram: types.maybe(types.reference(ProgramModel)),
    currentProgramDetail: types.maybe(types.reference(ProgramDetailModel)),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get CurrentProgramId() {
      if (self.currentProgram) {
        return self.currentProgram.objectId
      } else {
        return self.programs[0].objectId
      }
    },
    get CurrentProgramDetailId() {
      if (self.currentProgramDetail) {
        return self.currentProgramDetail.objectId
      } else {
        return self.programDetails[0].objectId
      }
    },
  }))
  .actions((self) => ({
    load: flow(function* load() {
      console.log("loading programs")

      try {
        const parseApi = new ParseApi()
        let result: GetGeneralResults = yield parseApi.getAllOfClass("Program")
        // first The Programs of the current RadioStation

        if (result.data.results.length > 0) {
          self.programs = result.data.results.map((program) => {
            return {
              objectId: program.objectId,
              name: program.name,
              info: program.info,
              cover: program.cover,
              radio_id: program.radio_id,
              hasPodcasts: program.has_podcasts,
              icon: program.icon,
              isPublic: program.status === "public",
            }
          })
        } else {
          const error = "No Programs found"
          throw error
        }

        // get Program Details
        result = yield parseApi.getAllOfClass("ProgramDetail")
        if (result.data.results.length > 0) {
          self.programDetails = result.data.results.map((programdetail) => {
            return {
              objectId: programdetail.objectId as string,
              day: programdetail.day as string,
              title: programdetail.title as string,
              sort: programdetail.Sort as number,
              info: (programdetail.info as string) || "",
              time: (programdetail.time as string) || "",
              links: programdetail.links.map((link) => {
                if (link.value && link.type && link.name) {
                  return link
                }
                return null
              }),
              program_id: programdetail.program_id as string,
              hasPodcasts: programdetail.has_podcasts as boolean,
              cover: programdetail.cover as string,
              podcastData: programdetail.podcast_data || undefined,
              podIntroText: programdetail.pod_intro_text || undefined,
              podIntroWeb: programdetail.pod_intro_web || undefined,
              podIntroMail: programdetail.pod_intro_mail || undefined,
              podIntroPic: programdetail.pod_intro_pic || undefined,
              podIntroName: programdetail.pod_intro_name || undefined,
              isPublic: programdetail.status === "public",
            }
          })
        } else {
          const error = "No ProgramDetails found"
          throw error
        }

        // get Program Details
        result = yield parseApi.getAllOfClass("ProgramOverview")
        if (result.data.results.length > 0) {
          self.programOverviews = result.data.results.map((programoverview) => {
            return {
              objectId: programoverview.objectId,
              title: programoverview.title,
              description: programoverview.description,
              timetable: programoverview.timetable,
              radio_id: programoverview.radio_id,
              sort: programoverview.Sort,
            }
          })
        } else {
          const error = "No ProgramOverviews found"
          throw error
        }

        self.state = "done"
      } catch (error) {
        console.log(error)
        self.state = "error"
      }
    }),
    afterCreate() {
      this.load()
    },
    setCurrentProgram(id) {
      console.log("setting current program to ", id)
      self.currentProgram = id
    },
    setCurrentProgramDetail(id) {
      console.log("setting current program to ", id)
      self.currentProgramDetail = id
    },
    getPrograms(id: string) {
      return self.programs.filter((program) => program.radio_id.objectId === id)
    },
    getSectionedPrograms(id: string) {
      const programs = []
      self.programDetails
        .filter((prog) => prog.program_id.radio_id.objectId === id && prog.hasPodcasts)
        .forEach((prog) => {
          const section = programs.findIndex((section) => section.title === prog.program_id.name)
          if (section > -1) {
            programs[section].data.push(prog) // already have a list- append to it
          } else {
            programs.push({
              title: prog.program_id.name,
              cover: prog.program_id.cover,
              data: [prog],
            }) // no list for this month yet - create a new one
          }
        })

      return programs.map((section) => {
        return {
          title: section.title,
          cover: section.cover,
          data: section.data.sort((a, b) => a.sort - b.sort),
        }
      })
    },
    getProgramsWithPodcasts(id: string) {
      const podcastCat = []
      self.programs.map((program) => {
        if (program.radio_id.objectId === id) {
          self.programDetails.map((programde) => {
            if (programde.program_id.objectId === program.objectId && programde.hasPodcasts) {
              podcastCat.push(programde)
            }
            return null
          })
        }
        return null
      })
      return podcastCat
    },
    getProgramOverview(id) {
      return self.programOverviews.filter((overview) => overview.radio_id.objectId === id)
    },
    getCurrentProgramDetails() {
      return self.programDetails
        .filter((program) => program.program_id.objectId === self.CurrentProgramId)
        .slice()
        .sort((a, b) => a.sort - b.sort)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ProgramStoreType = Instance<typeof ProgramStoreModel>
export interface ProgramStore extends ProgramStoreType {}
type ProgramStoreSnapshotType = SnapshotOut<typeof ProgramStoreModel>
export interface ProgramStoreSnapshot extends ProgramStoreSnapshotType {}
