import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { color } from "../../theme"
import { useStores } from "../../models"
import { View } from "@dripsy/core"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
}

export const GoodbyeScreen = observer(function GoodbyeScreen() {
  const rootStore = useStores()
  useEffect(() => {
    setTimeout(() => {
      rootStore.signOut()
    }, 1500)
  }, [])
  return (
    <Screen style={ROOT} preset="scroll">
      <Container gradient menuShown={false}>
        <View sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <Text preset="header" tx="common.goodbye" />
        </View>
      </Container>
    </Screen>
  )
})
