/**
 * The root navigator is used to switch between major navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow (which is contained in your MainNavigator) which the user
 * will use once logged in.
 */
import React from "react"
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { LoginScreen, WelcomeScreen } from "../screens"
import { RegisterScreen } from "../screens/register/register-screen"
import { useStores } from "../models"
import { observer } from "mobx-react-lite"
import { MetaNavigator } from "./meta-navigator"
import { ForgotPasswordScreen } from "../screens/forgot-password/forgot-password-screen"
import * as Linking from "expo-linking"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * We recommend using MobX-State-Tree store(s) to handle state rather than navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type RootParamList = {
  Login: undefined
  Register: undefined
  Welcome: undefined
  Main: undefined
  Meta: undefined
  ForgotPassword: undefined
}

const Stack = createStackNavigator<RootParamList>()

const RootStack = observer(function RootStack() {
  const rootStore = useStores()

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={rootStore.isSignedIn ? "Meta" : "Welcome"}
    >
      {rootStore.isSignedIn ? (
        <>
          <Stack.Screen
            name="Meta"
            component={MetaNavigator}
            options={{ headerShown: false, headerTransparent: true }}
          />
        </>
      ) : (
        <>
          <Stack.Screen options={{ headerShown: false }} name="Welcome" component={WelcomeScreen} />
          <Stack.Screen options={{ headerShown: false }} name="Login" component={LoginScreen} />
          <Stack.Screen
            options={{ headerShown: false }}
            name="Register"
            component={RegisterScreen}
          />
          <Stack.Screen
            options={{ headerShown: false }}
            name="ForgotPassword"
            component={ForgotPasswordScreen}
          />
        </>
      )}
    </Stack.Navigator>
  )
})

export const RootNavigator = React.forwardRef<
  NavigationContainerRef,
  Partial<React.ComponentProps<typeof NavigationContainer>>
>((props, ref) => {
  // This is important for the Web Version, it takes care of the Url stuff. kindof... setting an explicit id for an event and soforth would be cool in the future
  const linking = {
    prefixes: [
      Linking.createURL("https://web.universalapp.ch/"),
      Linking.createURL("/"),
      Linking.createURL("universal://"),
    ],
    config: {
      screens: {
        Welcome: "welcome",
        Register: "register",
        Login: "login",
        ForgotPassword: "passwordreset",
        Meta: {
          screens: {
            Main: {
              screens: {
                Radio: {
                  screens: {
                    Radio: "radio",
                    RadioStationAbout: "radio/about",
                    Overview: "radio/program/overview",
                    Program: "radio/program",
                    Human: "human",
                    Podcasts: {
                      screens: {
                        PodcastLibrary: "radio/media",
                        PodcastCategory: "radio/media/category",
                      },
                    },
                  },
                },
                Events: {
                  screens: {
                    Events: "events",
                    Details: "events/detail",
                    CreateEvent: "events/create",
                    EditEvent: "events/edit",
                  },
                },
                Profile: { screens: { Profile: "profile", EditProfile: "profile/edit" } },
              },
            },
            Redemption: "redeem",
            About: "about",
            Support: "support",
            Privacy: "privacy",
            Sub: "subscription",
            Account: {
              screens: {
                MyAccount: "account",
                ResetPassword: "resetpassword",
                GoodBye: "goodbye",
              },
            },
          },
        },
      },
    },
  }

  return (
    <NavigationContainer linking={linking} {...props} ref={ref}>
      <RootStack />
    </NavigationContainer>
  )
})

RootNavigator.displayName = "RootNavigator"
