import React, { useEffect, useRef, useState } from "react"
import { ActivityIndicator, Image, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { useStores } from "../../models"
import { Formik } from "formik"
import { TextInput } from "react-native-paper"
import { translate } from "../../i18n"
import { palette } from "../../theme/palette"
import { Button } from "../button/button"
import * as Yup from "yup"
import { Icon } from "../icon/icon"
import { useLinkTo } from "@react-navigation/native"
import useImagePicker from "../../hooks/useImagePicker"
import { CollapsedError } from "../collapsed-error/CollapsedError"
import CountryPicker from "react-native-country-picker-modal"
import regionData from "../../../node_modules/country-region-data/data.json"
import Collapsible from "react-native-collapsible"
import { MATERIAL_FIELD, MB, ROW } from "../../utils/style"
import { LoadingScreen } from "../loading-screen/loading-screen"
import { useToast } from "react-native-toast-notifications"
import { Picker } from "@react-native-picker/picker"

const BODY: ViewStyle = {
  paddingHorizontal: spacing[6],
  backgroundColor: color.palette.lighterGrey,
  borderTopRightRadius: 80,
  borderBottomLeftRadius: 80,
}

const REGION_PICKER: ViewStyle = {
  padding: spacing[3],
  paddingVertical: spacing[5],
  backgroundColor: palette.textfieldgrey,
  borderWidth: 0,
  borderTopLeftRadius: spacing[1],
  borderTopRightRadius: spacing[1],
  borderBottomWidth: 2,
  borderColor: palette.textfieldunderlinegrey,
}

const IMAGE_BUTTON: ViewStyle = {
  borderRadius: 50,
  backgroundColor: palette.alphawhite,
  width: 100,
  height: 100,
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
}

const REGION_ITEM: TextStyle = {
  padding: spacing[3],
  fontSize: typography.size[2] + 2,
  fontFamily: null,
}

const REGION_PICKER_TEXT: TextStyle = {
  fontSize: typography.size[2] + 2,
  fontFamily: null,
  lineHeight: typography.size[4],
  paddingBottom: spacing[3],
  paddingTop: spacing[2],
}

export interface ProfileFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required(translate("register.required.firstname"))
    .min(2, translate("register.min.firstname")),
  lastname: Yup.string()
    .required(translate("register.required.lastname"))
    .min(2, translate("register.min.lastname")),
  bio: Yup.string(),
  country: Yup.string().required(translate("register.required.country")),
  region: Yup.string().required(translate("register.required.region")),
  city: Yup.string().required(translate("register.required.city")),
  avatar: Yup.string().url(),
})

/**
 * Describe your component here
 */
export const ProfileForm = observer(function ProfileForm(props: ProfileFormProps) {
  const rootStore = useStores()
  const { userStore } = rootStore
  const { loading, pick } = useImagePicker()
  const [regions, setRegions] = useState([])
  const toast = useToast()
  const ref = useRef()
  const linkTo = useLinkTo()

  const pickImage = async (setFieldValue) => {
    const image = await pick()
    if (image) {
      setFieldValue("avatar", image)
    }
  }

  useEffect(() => {
    setRegions(
      regionData.find((land) => land.countryShortCode === userStore.me.home.country).regions,
    )
    return () => {
      // cleanup
    }
  }, [])

  // useEffect(() => {
  //   ref &&
  //     ref.current.select(
  //       regions.findIndex((region) => region.shortCode === userStore.me.home.region),
  //     )
  //   return () => {
  //     // cleanup
  //   }
  // }, [regions])

  if (!userStore.me) return <LoadingScreen />

  console.log(userStore.me)
  return (
    <Formik
      initialValues={{
        avatar: userStore.me.avatar || "",
        firstname: userStore.me.name,
        lastname: userStore.me.lastName,
        bio: userStore.me.bio,
        city: userStore.me.home.city,
        country: userStore.me.home.country,
        region: userStore.me.home.region,
      }}
      onSubmit={async (values, actions) => {
        const result = await rootStore.updateProfile(values)
        if (result !== "success") {
          // Toast.showWithGravity(result, Toast.LONG, Toast.TOP, ["UIAlertController"])
          toast.show(result)
          actions.setSubmitting(false)
        } else {
          actions.setSubmitting(false)
          linkTo("/profile")
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        values,
        submitForm,
        errors,
        touched,
        isValid,
        isSubmitting,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <View style={[BODY, { marginTop: 150 }]}>
            <View style={[ROW, { justifyContent: "flex-start", marginBottom: spacing[4] }]}>
              <Image
                source={{
                  uri: userStore.me.avatar || "https://placekitten.com/640/640",
                }}
                resizeMode="contain"
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  justifyContent: "flex-end",
                  marginTop: -100,
                  marginLeft: 25,
                }}
              />
              <Button
                onPress={() => {
                  !loading && pickImage(setFieldValue)
                }}
                preset="secondary"
                style={{ marginTop: spacing[1] }}
              >
                {loading ? (
                  <ActivityIndicator animating={true} color={palette.purple} />
                ) : (
                  <View style={[ROW, { justifyContent: "flex-start", alignItems: "center" }]}>
                    <Icon name="camera" color={palette.white} style={{ padding: spacing[2] }} />
                    <Text
                      tx={values.avatar ? "profile.changeAvatar" : "profile.addAvatar"}
                      style={{ color: palette.white, textAlign: "center", paddingLeft: spacing[2] }}
                    />
                  </View>
                )}
              </Button>
            </View>
            <TextInput
              placeholder={translate("register.firstname")}
              value={values.firstname}
              onChangeText={(text) => setFieldValue("firstname", text)}
              onBlur={handleBlur("firstname")}
              returnKeyType="done"
              style={MB}
              error={!!(touched.firstname && errors.firstname)}
            />
            <CollapsedError error={touched.firstname && errors.firstname} />
            <TextInput
              placeholder={translate("register.lastname")}
              value={values.lastname}
              onChangeText={(text) => setFieldValue("lastname", text)}
              onBlur={handleBlur("lastname")}
              returnKeyType="done"
              style={MB}
              error={!!(touched.lastname && errors.lastname)}
            />
            <CollapsedError error={touched.lastname && errors.lastname} />
            <CountryPicker
              countryCode={values.country}
              withEmoji={true}
              onSelect={(country) => {
                setFieldValue("country", country.cca2)
                setRegions(
                  regionData.find((land) => land.countryShortCode === country.cca2).regions,
                )
              }}
              preferredCountries={["CH", "FR", "DE", "IT"]}
              withFilter={true}
              withAlphaFilter={false}
              withFlagButton={true}
              withCountryNameButton={true}
              containerButtonStyle={[MATERIAL_FIELD, MB]}
            />
            <CollapsedError error={touched.country && errors.country} />
            <Collapsible collapsed={regions.length === 0}>
              <Picker
                ref={ref}
                selectedValue={values.region}
                enabled={regions.length > 0}
                onValueChange={(option) => {
                  console.log("picked", option)
                  setFieldValue("region", option)
                }}
                style={[REGION_PICKER, MB]}
              >
                <Picker.Item
                  key={"initial"}
                  value={null}
                  label={translate("register.pick.region")}
                />
                {regions.map((region) => {
                  return (
                    <Picker.Item key={region.shortCode} value={region.name} label={region.name} />
                  )
                })}
              </Picker>
            </Collapsible>
            <CollapsedError error={touched.region && errors.region} />
            <TextInput
              placeholder={translate("register.city")}
              value={values.city}
              onChangeText={(text) => setFieldValue("city", text)}
              onBlur={handleBlur("city")}
              returnKeyType="done"
              style={MB}
              error={!!(touched.city && errors.city)}
            />
            <CollapsedError error={touched.city && errors.city} />
            <TextInput
              placeholder={translate("register.motivation")}
              value={values.bio}
              onChangeText={(text) => setFieldValue("bio", text)}
              // onBlur={handleBlur("bio")}
              returnKeyType="done"
              multiline
              style={MB}
              numberOfLines={5}
            />
            <CollapsedError error={touched.bio && errors.bio} />
            {isSubmitting ? (
              <ActivityIndicator animating={true} color={palette.purple} size={30} />
            ) : (
              <Button
                style={{ marginVertical: spacing[4] }}
                onPress={submitForm}
                disabled={!isValid || isSubmitting}
                preset="secondary"
                tx="profile.save"
              />
            )}
          </View>
        </>
      )}
    </Formik>
  )
})
