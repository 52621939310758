/* eslint-disable generator-star-spacing */
import { destroy, flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserStoreModel } from "../user-store/user-store"
import { EventStoreModel } from "../event-store/event-store"
import { RadioStoreModel } from "../radio-store/radio-store"
import { ChatStoreModel } from "../chat-store/chat-store"
import { ProgramStoreModel } from "../program-store/program-store"
import { PodcastStoreModel } from "../podcast-store/podcast-store"
import { ParseApi } from "../../services/api/parse-api"
import { withEnvironment } from "../extensions/with-environment"
import { GetGeneralResult, GetLoginResult } from "../../services/api"
import { v4 as uuidv4 } from "uuid"
import { saveString, loadString } from "../../utils/storage"
import { Platform } from "react-native"
import * as Localization from "expo-localization"
import { APP_VERSION } from "../../app"
import { ChatRoomStoreModel } from "../chat-room-store/chat-room-store"
// import { ApiResponse } from "apisauce"
// import rnm from "@rn-matrix/core"
import Purchases from "react-native-purchases"
import addDays from "date-fns/addDays"
import { translate } from "../../i18n"
import { MetaStoreModel } from "../meta-store/meta-store"
import { RcApi } from "../../services/api/rc-api"
import { Event } from "./../event/event"
import { MatrixStoreModel } from "../matrix-store/matrix-store"
import { AccountModel } from "../account/account"
import { SubscriptionStoreModel } from "../subscription-store/subscription-store"
import subData from "../subscription-store/subscriptions.js"

const {
  INST_ID_KEY,
  APP_NAME,
  // MATRIX_REGISTRATION_TOKEN,
  // REVENUECAT_API_KEY,
  // ENTITLEMENT_ID,
  // MATRIX_HOMESERVER,
  // MATRIX_BASEURL,
} = require("../../config/env")

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model("RootStore")
  .props({
    installationId: types.string,
    sessiontoken: types.maybe(types.string),
    matrixToken: types.maybe(types.string),
    isSignedIn: types.optional(types.boolean, false),
    isSubscribed: types.boolean,
    hasAccess: types.optional(types.boolean, false),
    state: types.enumeration("State", ["pending", "done", "error"]),
    message: types.maybe(types.string),
    userStore: types.optional(UserStoreModel, {}),
    radioStore: types.optional(RadioStoreModel, {}),
    eventStore: types.optional(EventStoreModel, {}),
    chatStore: types.optional(ChatStoreModel, {}),
    programStore: types.optional(ProgramStoreModel, {}),
    podcastStore: types.optional(PodcastStoreModel, {}),
    chatRoomStore: types.optional(ChatRoomStoreModel, {}),
    metaStore: types.optional(MetaStoreModel, { meta: [], state: "pending" }),
    subscriptionStore: types.optional(SubscriptionStoreModel, {
      subscriptions: subData,
      state: "done",
    }),
    matrixStore: types.optional(MatrixStoreModel, {}),
    me: types.maybe(types.string),
    account: types.optional(AccountModel, {
      code: undefined,
      createdAt: undefined,
      state: "pending",
    }),
    avatar: types.maybe(types.string),
    track: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    signIn: flow(function* signIn(username, password) {
      self.state = "pending"
      const parseApi = new ParseApi()

      try {
        __DEV__ && console.log("Regular Login attempt")
        const result: GetLoginResult = yield parseApi.loginUser(username, password)
        if (result.kind === "ok") {
          const { objectId, sessionToken, createdAt } = result.data
          self.sessiontoken = sessionToken
          self.me = objectId
          self.userStore.setMe()
          self.account.parseId = objectId
          self.account.createdAt = new Date(createdAt)

          if (!__DEV__ && Platform.OS !== "web") {
            yield Purchases.identify(self.me)
          }
          self.isSignedIn = true
          self.state = "done"
          return "success"
        } else {
          __DEV__ && console.log("Login Failed : ", result)
          self.isSignedIn = false
          self.sessiontoken = ""
          self.state = "done"
          return translate("errors." + result.kind)
        }
      } catch (e) {
        console.log(e)
        self.state = "error"
        return translate("auth.errors.problem")
      }
    }),
    afterCreate() {
      // We are done setting up rootStore
      this.validateSession()
      this.checkAccess()
      self.state = "done"
    },
    // checkForUpdates() {
    //   const inAppUpdates = new SpInAppUpdates(
    //     false, // isDebug
    //   )
    //   inAppUpdates
    //     .checkNeedsUpdate()
    //     .then((result) => {
    //       if (result.shouldUpdate) {
    //         let updateOptions: StartUpdateOptions = {}
    //         if (Platform.OS === "android") {
    //           // android only, on iOS the user will be promped to go to your app store page
    //           updateOptions = {
    //             updateType: IAUUpdateKind.FLEXIBLE,
    //           }
    //         }
    //         inAppUpdates.startUpdate(updateOptions)
    //       }
    //     })
    //     .catch((err) => {
    //       __DEV__ && console.log(err)
    //       Bugsnag.notify("Error while checking for updates")
    //     })
    // },
    checkAccess: flow(function* checkAccess() {
      self.state = "pending"
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
      let subStatus

      if (self.isSignedIn && self.me) {
        console.log("Checking Subscription Status")
        // get Users SUbscription info
        let purchaserInfo
        let hasEntitlements
        if (Platform.OS === "web") {
          const rcApi = new RcApi()
          const res = yield rcApi.getPurchaserInfo(self.me)
          purchaserInfo = res.data
          if (purchaserInfo && typeof purchaserInfo.entitlements?.active?.access !== "undefined") {
            hasEntitlements = true
          } else {
            hasEntitlements = false
          }
        } else {
          purchaserInfo = yield Purchases.getPurchaserInfo()
          if (typeof purchaserInfo.entitlements.active.access !== "undefined") {
            hasEntitlements = true
          } else {
            hasEntitlements = false
          }
        }
        if (hasEntitlements) {
          __DEV__ && console.log("This user is subscribed")
          self.isSubscribed = true
          self.hasAccess = true
          subStatus = true
        } else {
          subStatus = false
          __DEV__ && console.log("Checking for Code")
          // the user is not subscribed
          self.isSubscribed = false

          // lets check if he has a code
          const userResult: GetGeneralResult = yield parseApi.getSomeOfClass("_User", {
            objectId: self.me,
          })
          if (userResult.kind === "ok") {
            const thirtyDaysAgo = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
            const userCreatedAt = new Date(userResult.data.results[0].createdAt)

            if (userResult.data.results[0].hasCode) {
              __DEV__ && console.log("Found Code")
              // The user has a code so lets check if it is still valid
              const codeResult: GetGeneralResult = yield parseApi.getSomeOfClass("Code", {
                objectId: userResult.data.results[0].hasCode.objectId,
              })
              if (codeResult.kind === "ok") {
                // got code details
                const duration = codeResult.data.results[0].duration
                self.account.code = codeResult.data.results[0].objectId
                if (duration === "life") {
                  // we have a lifetime free access user here
                  __DEV__ && console.log("User is with us for life")
                  self.hasAccess = true
                } else {
                  // lets do some math
                  const expirationDate = addDays(
                    new Date(codeResult.data.results[0].updatedAt),
                    duration,
                  )
                  if (new Date() < expirationDate) {
                    __DEV__ && console.log("Code is valid")
                    // user still has time
                    self.hasAccess = true
                  } else {
                    __DEV__ && console.log("Code is expired")
                    // the code is expired
                    self.hasAccess = false
                  }
                }
              } else {
                __DEV__ && console.log("Error fetching code details")
                // this is quite a pitty. I'd say we default to lieftime access
                self.hasAccess = true
              }
            } else {
              __DEV__ && console.log("No Code")
              // the user does not have a code so we check if hes been with us for 30 days
              __DEV__ &&
                console.log(
                  "Comparing Dates ",
                  userCreatedAt.toDateString(),
                  " must be less than ",
                  thirtyDaysAgo.toDateString(),
                )
              if (userCreatedAt < thirtyDaysAgo) {
                // The user has no more time left (30 days)
                __DEV__ && console.log("Time is up")
                self.hasAccess = false
              } else {
                __DEV__ && console.log("There is still time")
                self.hasAccess = true
              }
            }
          } else {
            __DEV__ && console.log("Error fetching user details")
            // this is quite a pitty. I'd say we default to the one month free
            self.hasAccess = true
          }
        }
        console.log("I AM ", self.me)

        // not really necessary anymore
        // update isPaying in Parse USer DB
        // const result: GetGeneralResult = yield parseApi.updateUser(self.me, {
        //   isPaying: subStatus,
        // })
        // if (result.kind === "ok") {
        //   __DEV__ && console.log("Subscription update successfull")
        //   self.isSubscribed = subStatus
        // } else {
        //   __DEV__ && console.log("Subscription update failed: ", result)
        //   self.state = "error"
        // }
        self.state = "done"
      } else {
        self.state = "error"
      }

      console.log("access: ", self.hasAccess)
    }),
    validateSession: flow(function* validateSession() {
      console.log("try to restore Session ", self.sessiontoken)
      self.state = "pending"
      if (self.sessiontoken) {
        console.log("checking sessiontoken: ", self.sessiontoken)
        const parseApi = new ParseApi()
        parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
        const result: GetGeneralResult = yield parseApi.restoreSession()

        if (result.kind === "ok") {
          console.log("Session restored successfully")
          console.log("Let's assume that all the login stuff is good then")
          console.log("rs.me ", self.me)
          console.log("rs.acc.pid ", self.account.parseId)
          console.log("rs.usst.me ", self.userStore.me)

          // self.me = result.data.objectId
          // !self.userStore.me && self.userStore.setMe()
          // self.account.parseId = result.data.objectId
          // self.account.createdAt = new Date(result.data.createdAt)

          // login User into Revenuact SDK
          if (!__DEV__ && Platform.OS !== "web") {
            yield Purchases.identify(self.me)
          }

          self.isSignedIn = true
          self.state = "done"
        } else {
          __DEV__ && console.log("Session invalid : ", result)
          if (result.temporary) {
            self.state = "done"
            // Toast.showWithGravity(translate("errors.temporary"), Toast.LONG, Toast.TOP, ["UIAlertController"])
          } else {
            self.sessiontoken = undefined
            self.me = undefined
            self.state = "done"
          }
          self.isSignedIn = false
        }
      } else {
        console.log("No Sessiontoken to validate")
        self.isSignedIn = false
        self.me = undefined
        self.state = "done"
      }
    }),
    redeemCode: flow(function* redeemCode(code) {
      const parseApi = new ParseApi()
      try {
        const codeResult: GetGeneralResult = yield parseApi.getSomeOfClass("Code", { code: code })
        console.log(JSON.stringify(codeResult))
        if (codeResult.kind === "ok") {
          if (codeResult.data.results.length > 0) {
            // got code details and it exists
            if (codeResult.data.results[0].user_id) {
              // This code already belongs to a user
              __DEV__ && console.log("Code is taken already")
              return translate("sub.errors.taken")
            } else {
              // ok lets redeem
              const redeemResult: GetGeneralResult = yield parseApi.updateObject(
                "Code",
                {
                  user_id: { __type: "Pointer", className: "_User", objectId: self.me },
                },
                codeResult.data.results[0].objectId,
              )
              if (redeemResult.kind === "ok") {
                parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
                const userResult: GetGeneralResult = yield parseApi.updateUser(self.me, {
                  hasCode: {
                    __type: "Pointer",
                    className: "Code",
                    objectId: codeResult.data.results[0].objectId,
                  },
                })
                if (userResult.kind === "ok") {
                  self.hasAccess = true
                  return "success"
                } else {
                  return translate("sub.errors.problem")
                }
              } else {
                return translate("sub.errors.problem")
              }
            }
          } else {
            return translate("sub.errors.invalid")
          }
        } else {
          __DEV__ && console.log("Error fetching code details")
          return translate("sub.errors.problem")
        }
      } catch (e) {
        console.log(e)
        return translate("sub.errors.problem")
      }
    }),
    lock() {
      self.hasAccess = false
    },
    unlock() {
      self.hasAccess = true
    },
    setTrack(url: string) {
      self.track = url
    },
    forgotPW: flow(function* forgotPW(email) {
      const parseApi = new ParseApi()
      const result: GetGeneralResult = yield parseApi.resetPassword(email)

      if (result.kind === "ok") {
        console.log(result)
        console.log(self)
      } else {
        __DEV__ && console.log(result.kind)
        self.state = "error"
        self.message = result.kind
      }
    }),
    setInstallationId: flow(function* setInstallationId() {
      if (Platform.OS !== "web") {
        if (!self.installationId) {
          const id = yield loadString(INST_ID_KEY)
          if (!id) {
            // this is really brand new or we have a serious problem
            self.installationId = uuidv4()
            const Installation = {
              installationId: self.installationId,
              deviceType: Platform.OS,
              localeIdentifier: Localization.locale,
              timeZone: Localization.timezone,
              appName: APP_NAME, // DeviceInfo.getApplicationName(),
              appVersion: APP_VERSION,
            }
            // if (Platform.OS === "ios") {
            // Installation.deviceToken = yield DeviceInfo.getDeviceToken()
            // }
            const parseApi = new ParseApi()
            parseApi.uploadInstallation(Installation)
          } else {
            self.installationId = id
          }
        } else {
          saveString(INST_ID_KEY, self.installationId)
        }
      }
    }),
    // updateSubscriptionStatus: flow(function* updateSubscriptionStatus(status: boolean) {
    //   __DEV__ && console.log("updating Subscription...")

    //   // set isPaying in Parse
    //   const parseApi = new ParseApi()
    //   parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
    //   const result: GetGeneralResult = yield parseApi.updateUser(self.me, {
    //     isPaying: status,
    //   })

    //   if (result.kind === "ok") {
    //     __DEV__ && console.log("Subscription update successfull")
    //     self.isSubscribed = status
    //   } else {
    //     __DEV__ && console.log("Subscription update failed: ", result)
    //     self.state = "error"
    //   }

    //   __DEV__ && console.log("User subscription status => ", self.isSubscribed)
    // }),
    signOut: flow(function* SignOut() {
      console.log("logging out...")
      self.state = "pending"
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
      const result: GetGeneralResult = yield parseApi.logoutUser()
      if (result.kind === "ok") {
        console.log("signout successfull")
        self.isSignedIn = false
        self.sessiontoken = ""
        self.me = undefined
        self.state = "done"
      } else {
        __DEV__ && console.log(result)
        self.state = "error"
        self.message = result.kind
        self.isSignedIn = false

        // dirty sign out
        self.sessiontoken = ""
        self.me = undefined
        self.state = "done"
      }
    }),
    signUp: flow(function* signUp(data: any) {
      self.state = "pending"
      self.message = ""
      const parseApi = new ParseApi()

      // ************************ Matrix integration posponed
      // first we check if email isn't already taken
      // try {
      //   const checkEmailResult: GetGeneralResult = yield parseApi.getSomeOfClass("_User", { email: data.email }, "objectId")
      //   if (checkEmailResult.kind === "ok") {
      //     if (checkEmailResult.data.results.length > 0) {
      //     // email already taken
      //       console.log("email is taken!")
      //       self.state = "error"
      //       self.message = "emailtaken"
      //     }
      //   }
      // } catch (error) {
      //   self.state = "error"
      //   self.message = error.message
      //   __DEV__ && console.log(error)
      // }

      // // generate username for matrix
      // const username = data.firstname + data.lastname
      // if (self.state === "pending") {
      // // try to create Matrix Account
      //   try {
      //     const formData = new FormData()
      //     formData.append("username", username)
      //     formData.append("password", data.password)
      //     formData.append("confirm", data.password)
      //     formData.append("token", MATRIX_REGISTRATION_TOKEN)
      //     console.log("registering with Matrix...", formData)

      //     // const parseApi = new ParseApi(self.environment.parseApi)
      //     const result: ApiResponse<any, any> = yield self.environment.matrixApi.apisauce.post(
      //       "matrix-registration/register",
      //       formData,
      //     )
      //     console.log(result)
      //     if (!result.ok) {
      //       self.state = "error"
      //       self.message = result.data.errcode
      //     } else {
      //       self.matrixToken = result.data.access_token
      //     }
      //   } catch (error) {
      //     self.state = "error"
      //     console.log(`Failed to register User in Matrix: ${error.message}`)
      //   }
      // }

      // // if Matrix Account was able to be created then we register a Parse USer
      // if (self.state === "pending" && self.matrixToken) {
      // ************************ Matrix integration posponed

      // try to register Parse User
      try {
        const result: GetGeneralResult = yield parseApi.registerUser(data)
        if (result.kind === "ok") {
          const {
            objectId,
            sessionToken,
            region,
            country,
            firstname,
            lastname,
            createdAt,
          } = result.data
          // success
          console.log(result)
          self.sessiontoken = sessionToken
          self.me = objectId
          self.userStore.setMe()
          self.account.parseId = objectId
          self.account.createdAt = new Date(createdAt)

          // login User into Revenuact SDK
          if (!__DEV__ && Platform.OS !== "web") {
            yield Purchases.identify(self.me)
            Purchases.setDisplayName(result.data.firstname + " " + result.data.lastname)
            Purchases.setAttributes({ region: result.data.region, country: result.data.country })
          }

          self.isSignedIn = true
          self.state = "done"
          return "success"

          // start matrix client --> posponed
          // rnm.createClient(MATRIX_HOMESERVER, self.matrixToken, data.firstname + data.lastname)
          // rnm.start(true)
        } else {
          __DEV__ && console.log(result)
          self.state = "error"
          return translate("errors." + result.kind)
        }
      } catch (error) {
        self.state = "error"
        console.log(`Failed to register User in Parse: ${error.message}`)
        return translate("register.error.problem")
      }
      // }
    }),
    updateProfile: flow(function* updateProfile(data: any) {
      self.state = "pending"
      self.message = ""
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
      console.log("trying to update user with: ", data)
      // try to update Parse User
      try {
        const result: GetGeneralResult = yield parseApi.updateUser(self.me, data)
        console.log("result from update: ", result)

        if (result.kind === "ok") {
          // success
          self.userStore.me.avatar = data.avatar
          self.userStore.me.name = data.firstname
          self.userStore.me.lastName = data.lastname
          self.userStore.me.bio = data.bio
          self.userStore.me.home = { city: data.city, country: data.country, region: data.region }
        } else {
          self.state = "error"
          self.message = result.kind
        }

        self.state = "done"
        return "success"
      } catch (error) {
        self.state = "error"
        console.log(`Failed to update User in Parse: ${error.message}`)
        return error.message
      }
    }),
    deleteProfile: flow(function* deleteProfile() {
      self.state = "pending"
      self.message = ""
      const parseApi = new ParseApi()
      parseApi.apisauce.setHeader("X-Parse-Session-Token", self.sessiontoken)
      try {
        // first we remove all events created by the user
        const eventsToDelete: Event[] = self.eventStore.events.filter(
          (event) => event.user_id === self.me,
        )
        for (const index in eventsToDelete) {
          console.log("deleting event : ", eventsToDelete[index].objectId)
          yield parseApi.deleteObject("Event", eventsToDelete[index].objectId)
          destroy(eventsToDelete[index])
        }

        console.log("going to delete user : ", self.me)
        // try to delete Parse User
        const result: GetGeneralResult = yield parseApi.deleteUser(self.me)
        console.log("result from delete: ", result)
        if (result.kind === "ok") {
          // success then kill session
          const result: GetGeneralResult = yield parseApi.logoutUser()
          if (result.kind === "ok") {
            console.log("signout successfull")
            self.isSignedIn = false
            self.sessiontoken = undefined
            self.me = undefined
            self.state = "done"
          } else {
            __DEV__ && console.log(result)
            self.state = "error"
            self.message = result.kind
            self.isSignedIn = false

            // dirty sign out
            self.sessiontoken = undefined
            self.me = undefined
            self.state = "done"
          }
        } else {
          self.state = "error"
          self.message = result.kind
        }

        self.state = "done"
        return "success"
      } catch (error) {
        self.state = "error"
        console.log(`Failed to delete User in Parse: ${error.message}`)
        return error.message
      }
    }),
    setSessiontoken(sessiontoken) {
      self.sessiontoken = sessiontoken
    },
  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
