import React from "react"
import { observer } from "mobx-react-lite"
import { TextStyle, View, ViewStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"
import { LinkButton } from "../../components/link-button/link-button"
import { HumanBubble } from "../../components/human-bubble/human-bubble"
import { AwesomeContainer } from "../../components/awesome-container/awesome-container"
import Bugsnag from "@bugsnag/react-native"

const TITLE: TextStyle = {
  color: palette.white,
  fontSize: typography.size[5],
  fontFamily: typography.secondary,
}

const BUTTON_ROW: ViewStyle = {
  flexDirection: "row",
  marginVertical: spacing[2],
  justifyContent: "center",
}

const CONTENT: ViewStyle = {
  width: "100%",
}

const CONTENT_CONTAINER: ViewStyle = {
  padding: spacing[4],
  paddingLeft: 40,
  paddingBottom: spacing[5],
  // overflow: "hidden",
}

export const RadioStationAboutScreen = observer(function RadioStationAboutScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Radio about")

  // Pull in one of our MST stores
  const { radioStore } = useStores()

  // Pull in navigation via hook
  const nav = useNavigation()

  return (
    <Screen preset="scroll">
      <Container gradient>
        <AwesomeContainer
          goback={nav.goBack}
          imageSource={radioStore.currentRadioStation.about_image}
          headerBackgroundColor={palette.paleorange}
          header={
            <>
              <Text style={TITLE}>{radioStore.currentRadioStation.name}</Text>
            </>
          }
          // category={eventStore.currentEvent.tags[0].item}
        >
          <View style={CONTENT_CONTAINER}>
            <View style={CONTENT}>
              <Text style={{ fontSize: typography.size[3] }}>
                {radioStore.currentRadioStation.about}
              </Text>
            </View>
            <View style={BUTTON_ROW}>
              {radioStore.currentRadioStation.contact.map((contact, index) => (
                <LinkButton key={index} link={contact} showName={false} />
              ))}
            </View>
            {radioStore.currentRadioStation.humans.map((human, index) => (
              <HumanBubble key={index} human={human} />
            ))}
          </View>
        </AwesomeContainer>
      </Container>
    </Screen>
  )
})
