/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, typography } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"
import { ActivityIndicator } from "react-native-paper"
import { FLEX1, ROW } from "../../utils/style"
import { useAudioPosition } from "react-use-audio-player"
import Slider from "@react-native-community/slider"
import { AVPlaybackStatus } from "expo-av"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
}

const TIME_TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[1],
  color: palette.lightGrey,
}

const BAR: ViewStyle = {
  flex: 6,
}

const TIME: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
}

export interface PodcastSeekBarProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  status: AVPlaybackStatus
  onSeek: any
}

/**
 * Describe your component here
 */
export const PodcastSeekBar = observer(function PodcastSeekBar(props: PodcastSeekBarProps) {
  const { status, onSeek } = props
  // const { position, duration, seek, } = useAudioPosition()

  const minitify = (input: number) => {
    const durationMins = Math.round(input / 60)
    const durationSecs = (Math.round(input) % 60).toString().padStart(2, "0")

    return `${durationMins}:${durationSecs}`
  }

  if (status && status.isLoaded) {
    const { positionMillis, durationMillis } = status

    const from = minitify(positionMillis / 1000) || "0:00"
    const to = minitify(durationMillis / 1000 - positionMillis / 1000) || "0:00"

    return (
      <View style={FLEX1}>
        <View style={[ROW, FLEX1, { justifyContent: "space-between" }]}>
          <View style={TIME}>
            <Text style={TIME_TEXT}>{from}</Text>
          </View>
          <View style={[TIME, { alignContent: "flex-end" }]}>
            <Text style={[TIME_TEXT, { textAlign: "right" }]}>{to}</Text>
          </View>
        </View>
        <Slider
          maximumValue={durationMillis}
          minimumValue={0}
          value={positionMillis}
          step={1}
          thumbTintColor={palette.lighterGrey}
          minimumTrackTintColor={palette.orange}
          maximumTrackTintColor="#000000"
          // onValueChange={(value) => {
          //   onSeek(value)
          // }}
          onSlidingComplete={(value) => {
            onSeek(value)
          }}
          style={{ flex: 1 }}
        />
      </View>
    )
  }
})
