import React from "react"
import { observer } from "mobx-react-lite"
import { Platform, TextStyle, View, ViewStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { LinearGradient } from "expo-linear-gradient"
import { palette } from "../../theme/palette"
import { spacing, typography } from "../../theme"
import { AwesomeContainer } from "../../components/awesome-container/awesome-container"
import Bugsnag from "@bugsnag/react-native"
import { getStatusBarHeight } from "react-native-iphone-x-helper"

const TITLE: TextStyle = {
  color: palette.white,
  fontWeight: "bold",
  fontSize: typography.size[5],
  fontFamily: typography.secondary,
}

const BUTTON_ROW: ViewStyle = {
  flexDirection: "row",
}

const CONTENT: ViewStyle = {
  paddingLeft: 40,
  padding: spacing[4],
}

const CONTENT_CONTAINER: ViewStyle = {
  paddingBottom: spacing[5],
  // overflow: "hidden",
}

const SECTION: ViewStyle = {
  marginVertical: spacing[3],
}

const TIMETABLE: ViewStyle = {
  flexDirection: "row",
}

const TT_BIG_CELL: ViewStyle = {
  width: "30%",
}

const TT_CELL: ViewStyle = {
  width: "70%",
}

const HEADER: TextStyle = {
  fontSize: typography.size[3],
  fontWeight: "bold",
}

const DESC: TextStyle = {
  fontSize: typography.size[3],
  fontWeight: "normal",
  marginBottom: spacing[2],
}

const TT_KEY: TextStyle = {
  fontSize: typography.size[2],
  fontWeight: "bold",
}

const TT_VALUE: TextStyle = {
  fontSize: typography.size[2],
  fontWeight: "normal",
}

const GRADIENT: ViewStyle = {
  flex: 1,
  paddingTop: Platform.OS === "ios" ? getStatusBarHeight() + 5 : 0,
}

export const ProgramOverviewScreen = observer(function ProgramOverviewScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Programm Overview")

  // Pull in one of our MST stores
  const { radioStore, programStore } = useStores()

  // Pull in navigation via hook
  const nav = useNavigation()

  return (
    <Screen preset="scroll" style={{ backgroundColor: palette.white }}>
      <Container gradient>
        <AwesomeContainer
          dark
          style={GRADIENT}
          goback={nav.goBack}
          imageSource={radioStore.currentRadioStation.logo_url}
          headerBackgroundColor={palette.paleorange}
          header={
            <>
              <Text style={TITLE}>{radioStore.currentRadioStation.name}</Text>
              <View style={BUTTON_ROW}></View>
            </>
          }
        >
          <View style={CONTENT_CONTAINER}>
            <View style={CONTENT}>
              {programStore
                .getProgramOverview(radioStore.CurrentRadioStationId)
                .sort((a, b) => a.sort - b.sort)
                .map((overview, index) => (
                  <View key={index} style={SECTION}>
                    <Text style={HEADER}>{overview.title}</Text>
                    <Text style={DESC}>{overview.description}</Text>
                    {overview.timetable.map((timetable, index) => (
                      <View key={index} style={TIMETABLE}>
                        <View style={TT_BIG_CELL}>
                          <Text style={TT_KEY}>{timetable.time}</Text>
                        </View>
                        <View style={TT_CELL}>
                          <Text style={TT_VALUE}>{timetable.name}</Text>
                        </View>
                      </View>
                    ))}
                  </View>
                ))}
            </View>
          </View>
        </AwesomeContainer>
      </Container>
    </Screen>
  )
})
