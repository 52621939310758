module.exports = {
  PARSE_APPLICATION_ID: "Wkdh6TH5JDR4tqFY5pGXXQZUVcVchYSkRT6shepj",
  PARSE_JS_KEY: "Y57aPmhNiYUvYiHlnylOeSqGJbxFsHwxdGfrARxK",
  PARSE_REST_KEY: "uaO5IZ4F96zi9A5Yv0DhsOdQn7a2Mro7lKYiPO5u",
  PARSE_CLIENT_KEY: "nD81iOXlYWtsSy58EXqgEhnI2srG4NDzbQ7QL0ab",
  PARSE_SERVER_URL: "https://parseapi.back4app.com/",
  PARSE_SERVER_WS_URL: "wss://universalapp.b4a.io",
  REVENUECAT_API_KEY: "bSYllUvnqCWyidqpyJIIrEGJzhkpTyXI",
  INST_ID_KEY: "UNIVERSALAPP_INST_ID",
  APP_NAME: "UNIVERSAL APP",
  HERE_API_URL: "https://geocoder.ls.hereapi.com/6.2/geocode.json?",
  HERE_API_KEY: "SVkYRSVUzDjaxD5iHrtnNecdMvNbx7GJEvGdT_FxayE",
  MATRIX_HOMESERVER: "https://matrix.nvrsl.ch",
  MATRIX_BASEURL: "https://nvrsl.ch",
  MATRIX_REG_HOMESERVER: "https://matrix.nvrsl.ch:5000",
  MATRIX_REGISTRATION_TOKEN: "ClarionRicardoOlga",
  ENTITLEMENT_ID: "access",
  STRIPE_API_KEY: "pk_live_51IVHvSCBPTEWbSrZ6Ja4amih8OOcgoEpyDJogi55fL7rqNAgPvC5H8clxFNp5S1zxCsW8AWsDmfLd8clVeyaIGiZ00nJ2jjv6S",
  BUGSNAG_API_KEY: "1f70ad53044459a6259c26c8091447b0",
  REVENUECAT_API_URL: "https://api.revenuecat.com/v1/",
}
