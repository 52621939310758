import React, { useEffect, useState } from "react"
import {
  Dimensions,
  Platform,
  View,
  ViewStyle,
  ScrollView,
  useWindowDimensions,
} from "react-native"
import { getStatusBarHeight } from "react-native-iphone-x-helper"
import { FLEX1, MB } from "../../utils/style"
import { LinearGradient } from "expo-linear-gradient"
import { UniversalHeader } from "../universal-header/universal-header"
import { useNavigation } from "@react-navigation/native"
import usePlatform from "../../hooks/usePlatform"

export interface ContainerProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  children
  gradient?: boolean
  menuIcon?
  onMenuPress?
  light?: boolean
  fixed?: boolean
  menuShown?: boolean
}

export const IOS_TOP_PADDING: ViewStyle = {
  paddingTop: Platform.select({ ios: getStatusBarHeight() + 5, default: 0 }),
}

/**
 * Container that takes care of top padding on IOS devices
 */
export const Container = (props: ContainerProps) => {
  const {
    style,
    children,
    gradient = false,
    light,
    menuIcon,
    onMenuPress,
    fixed,
    menuShown = true,
  } = props
  const { isWeb } = usePlatform()
  const [width, setWidth] = useState<number>(Dimensions.get("window").width)
  const nav = useNavigation()

  useEffect(() => {
    Dimensions.addEventListener("change", ({ window }) => {
      setWidth(window.width)
    })
    return () => {
      Dimensions.removeEventListener("change", ({ window }) => {
        setWidth(window.width)
      })
    }
  }, [])

  const windowHeight = useWindowDimensions().height

  const normal = ["#373074", "#5d3459", "#bf5b48", "#dd6629", "#e4841f", "#eba804"]
  const lighter = ["#37307450", "#5d345950", "#bf5b4850", "#dd662950", "#e4841f50", "#eba80450"]

  if (gradient || isWeb) {
    return (
      <LinearGradient
        colors={light ? lighter : normal}
        style={FLEX1}
        start={{ x: 0, y: 1 }}
        end={{ x: 0.8, y: 0 }}
      >
        <View
          style={[
            IOS_TOP_PADDING,
            FLEX1,
            // { paddingRight: isBig ? "25%" : 0, paddingLeft: isBig ? "25%" : 0 },
            style,
          ]}
        >
          <>
            {isWeb ? (
              <>
                {fixed ? (
                  <View
                    style={[
                      FLEX1,
                      width >= 900
                        ? {
                            width: 900,
                            marginHorizontal: "auto",
                          }
                        : {
                            width: width,
                          },
                    ]}
                  >
                    <UniversalHeader
                      web
                      menuShown={menuShown}
                      nologo={!menuShown}
                      nav={nav}
                      menuIcon={menuIcon}
                      colorful={light}
                      onMenuPress={onMenuPress}
                      style={MB}
                    />
                    {children}
                  </View>
                ) : (
                  <ScrollView
                    style={{
                      flexGrow: 1,
                      height: Platform.OS === "web" ? windowHeight : undefined,
                    }}
                  >
                    <View
                      style={[
                        FLEX1,
                        width >= 900
                          ? {
                              width: 900,
                              marginHorizontal: "auto",
                            }
                          : {
                              width: width,
                            },
                      ]}
                    >
                      <UniversalHeader
                        web
                        menuShown={menuShown}
                        nologo={!menuShown}
                        nav={nav}
                        menuIcon={menuIcon}
                        colorful={light}
                        onMenuPress={onMenuPress}
                        style={MB}
                      />
                      {children}
                    </View>
                  </ScrollView>
                )}
              </>
            ) : (
              children
            )}
          </>
        </View>
      </LinearGradient>
    )
  }

  return <View style={[IOS_TOP_PADDING, FLEX1, style]}>{children}</View>
}
