import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  Image,
  ImageStyle,
  TextStyle,
  View,
  ViewStyle,
  TouchableOpacity,
  SectionList,
  Platform,
} from "react-native"
import { Container, Screen, Text } from "../../components"
import { color, spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"
import { useIsFocused, useLinkTo, useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { FAB } from "react-native-paper"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { MyLanguage } from "../../i18n/i18n"
import Bugsnag from "@bugsnag/react-native"
import { ROW } from "../../utils/style"
import usePlatform from "../../hooks/usePlatform"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
}

const FAB_STYLE: ViewStyle = {
  position: "absolute",
  margin: 16,
  right: 40,
  bottom: 40,
  zIndex: 500,
  backgroundColor: palette.orange,
}

const CONTAINER: ViewStyle = {
  paddingLeft: spacing[3],
  paddingTop: spacing[2],
  height: 73.5,
  marginBottom: spacing[2],
}
const LIST: ViewStyle = {
  // backgroundColor: palette.lightGrey,
  // flex: 1,
}
const EVENT_ITEM: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: spacing[4],
}

const EVENT_IMAGE_WRAPPER: ViewStyle = {
  //   width: 150,
  //   height: 150,
  paddingRight: spacing[3],
}
const EVENT_IMAGE: ImageStyle = {
  width: 130,
  height: 73.5,
  // aspectRatio: 1.765,
  borderRadius: spacing[4],
}

const EVENT_INFO_WRAPPER: ViewStyle = {
  borderRadius: Platform.select({ web: spacing[4], default: 0 }),
  borderTopLeftRadius: spacing[4],
  borderBottomLeftRadius: spacing[4],
  backgroundColor: palette.white,
  paddingVertical: spacing[1],
  paddingLeft: spacing[3],
  paddingRight: 0,
  flexGrow: 2,
  flex: 1,
  justifyContent: "space-evenly",
}

const DATE: TextStyle = {
  fontSize: typography.size[1],
  fontFamily: typography.secondary,
  color: palette.lightGrey,
  padding: 0,
}

const TITLE: TextStyle = {
  fontWeight: "bold",
  fontSize: typography.size[1],
  fontFamily: typography.secondary,
  lineHeight: typography.size[2],
  padding: 0,
}

const PLACE: TextStyle = {
  fontWeight: "bold",
  fontSize: typography.size[1],
  fontFamily: typography.secondary,
  color: palette.paleorange,
  lineHeight: typography.size[2],
}

const SECTION_HEADER: TextStyle = {
  fontSize: typography.size[3],
  fontFamily: typography.secondary,
  color: palette.alphawhite,
  lineHeight: typography.size[2],

  paddingLeft: spacing[4],
  paddingTop: spacing[5],
  paddingBottom: spacing[2],
}

const FOOTER: TextStyle = {
  color: palette.morealphawhite,
  paddingLeft: spacing[4],
  paddingTop: spacing[5],
  paddingBottom: spacing[2],
  marginBottom: spacing[7],
  fontSize: typography.size[3],
}

const Event = ({ event, onPress }) => {
  let dateString
  if (event.endDate && event.startDate.toDateString() !== event.endDate.toDateString()) {
    dateString = `${event.startDate
      .toLocaleDateString(MyLanguage, {
        weekday: "short",
        month: "short",
        day: "numeric",
      })
      .toUpperCase()} - ${event.endDate
      .toLocaleDateString(MyLanguage, {
        weekday: "short",
        month: "short",
        day: "numeric",
      })
      .toUpperCase()}`
  } else {
    dateString = event.startDate
      .toLocaleDateString(MyLanguage, {
        weekday: "short",
        month: "short",
        day: "numeric",
      })
      .toUpperCase()
  }

  if (!event || !event.place_id) return <LoadingScreen />

  return (
    <TouchableOpacity onPress={onPress} style={CONTAINER}>
      <View style={EVENT_ITEM}>
        <View style={EVENT_IMAGE_WRAPPER}>
          <Image style={EVENT_IMAGE} source={{ uri: event.thumb }} />
        </View>
        <View style={EVENT_INFO_WRAPPER}>
          <Text style={DATE}>{dateString}</Text>
          <Text style={TITLE} numberOfLines={2}>
            {event.name}
          </Text>
          <Text style={PLACE} numberOfLines={1}>
            {event.place_id.name}, {event.place_id.city}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export const EventsScreen = observer(function EventsScreen() {
  Bugsnag.leaveBreadcrumb("Screen: Events Overview Screen")

  // Pull in one of our MST stores
  const { eventStore } = useStores()
  const [data, setData] = useState(null)
  const [filter] = useState([])
  const [loading, setLoading] = useState(false)
  const linkTo = useLinkTo()

  const isFocused = useIsFocused()
  const nav = useNavigation()
  const { isWeb } = usePlatform()

  const gotoCreateEvent = () => {
    // nav.navigate("CreateEvent")
    linkTo("/events/create")
  }

  // const toggleFilter = () => {
  //   setShowFilter(!showFilter)
  // }

  // const resetFilter = () => {
  //   setFilter([])
  //   setData(eventStore.getSectionedEvents([]))
  //   toggleFilter()
  // }

  // useEffect(() => {
  //   if (filter.length > 0) {
  //     console.log("checking tags: ", filter)

  //     const filtered = eventStore.getSectionedEvents(filter)

  //     setData(filtered)
  //   }
  // }, [filter])

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      const loadingevents = await eventStore.getSectionedEvents(filter)
      setData(loadingevents)
      setLoading(false)
    }
    if (eventStore.state === "done") {
      load()
    }
  }, [eventStore.state, isFocused])

  if (!eventStore || !data || loading || eventStore.state === "pending") return <LoadingScreen />

  return (
    <Screen style={ROOT} preset="fixed">
      <Container gradient>
        {data.length > 0 ? (
          <SectionList
            style={LIST}
            sections={data}
            keyExtractor={(item) => item.objectId}
            showsVerticalScrollIndicator={true}
            renderItem={({ item }) => (
              <Event
                event={item}
                onPress={() => {
                  eventStore.setCurrentEvent(item.objectId)
                  // nav.navigate("Details")
                  linkTo("/events/detail")
                }}
              />
            )}
            renderSectionHeader={({ section }) => (
              <View style={ROW}>
                <Text style={SECTION_HEADER}>{section.title.toUpperCase()}</Text>
                {/* if this is the first section we use the header to display a search button aswell */}
                {section.date === data[0].date && (
                  <View style={ROW}>
                    {/* {showFilter && (
                      <IconButton
                        icon="cancel"
                        color={palette.morealphawhite}
                        size={26}
                        onPress={resetFilter}
                      />
                    )} */}
                    {/* for later release
                    <IconButton
                      icon={showFilter ? "chevron-up" : "magnify"}
                      color={palette.morealphawhite}
                      size={26}
                      onPress={toggleFilter}
                    /> */}
                  </View>
                )}
              </View>
            )}
            ListHeaderComponent={
              <>
                {!isWeb && <UniversalHeader nav={nav} />}
                {/* <Collapsible collapsed={!showFilter} enablePointerEvents>
                  <TagButton
                    tags={EVENT_TAGS}
                    onChange={(selected) => {
                      setFilter(selected)
                    }}
                  />
                </Collapsible> */}
              </>
            }
            ListFooterComponent={
              <View style={{ paddingVertical: spacing[4] }}>
                <Text style={FOOTER} tx="event.cta"></Text>
              </View>
            }
          />
        ) : (
          <Text>No Items found for this Filter</Text>
        )}
      </Container>
      <FAB small icon="plus" style={FAB_STYLE} onPress={gotoCreateEvent} />
    </Screen>
  )
})
