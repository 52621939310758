import React, { useState } from "react"
import { View, FlatList, TouchableOpacity, Image, Keyboard } from "react-native"
import { Modal, Searchbar } from "react-native-paper"
import {
  PLACE_ADDRESS,
  PLACE_AVATAR,
  PLACE_ENTRY,
  PLACE_IMAGE,
  PLACE_NAME,
  RESET_BUTTON,
  SELECTED,
} from "../event-form-styles"
import { EventFormValues } from "../event-form"
import { useStores } from "../../../models"
import { Button } from "../../button/button"

import { Icon } from "../../icon/icon"
import { Text } from "../../text/text"
import { spacing } from "../../../theme"
import { palette } from "../../../theme/palette"
import { FLEX01, FLEX09, FLEX1 } from "../../../utils/style"
import { EventFormBottomButtons } from "../../event-form-bottom-buttons/event-form-bottom-buttons"
import { translate } from "../../../i18n"
import { PlaceForm } from "../../place-form/place-form"
import { ScrollView } from "react-native-gesture-handler"
import { FormPageProps } from "../../form-page/form-page"
import usePlatform from "../../../hooks/usePlatform"
interface Place {
  name: string
  link: { name: string; avatar: string; value: string; type: string }
  street: string
  // eslint-disable-next-line camelcase
  street_nr: string
  zip: string
  city: string
}

const Item = ({ item, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={PLACE_ENTRY}>
      <View style={PLACE_AVATAR}>
        {item.image ? (
          <Image source={{ uri: item.image }} style={PLACE_IMAGE} />
        ) : (
          <Icon name="map-pin" size={36} />
        )}
      </View>
      <View>
        <Text style={PLACE_NAME}>{item.name}</Text>
        <Text style={PLACE_ADDRESS}>
          {item.street} {item.street_nr}
        </Text>
        <Text style={PLACE_ADDRESS}>
          {item.zipcode} {item.city}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export const Page2: React.FC<FormPageProps<EventFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  previousPage,
  nextPage,
}) => {
  const { eventStore } = useStores()
  const [data, setData] = useState(eventStore.getPlaces())
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedPlace, selectPlace] = useState<any>(
    eventStore.places.find((entry) => entry.objectId === values.placeId),
  )
  const [showModal, setShowModal] = useState(false)
  const { isWeb } = usePlatform()

  const onCreatePlace = (place) => {
    setData(eventStore.places)
    selectPlace(place)
    setFieldValue("placeId", place.objectId)
    setShowModal(false)
  }

  React.useEffect(() => {
    Keyboard.addListener("keyboardDidShow", _keyboardDidShow)
    Keyboard.addListener("keyboardDidHide", _keyboardDidHide)

    // cleanup function
    return () => {
      Keyboard.removeListener("keyboardDidShow", _keyboardDidShow)
      Keyboard.removeListener("keyboardDidHide", _keyboardDidHide)
    }
  }, [])

  const [keyboardStatus, setKeyboardStatus] = React.useState(false)
  const _keyboardDidShow = () => setKeyboardStatus(true)
  const _keyboardDidHide = () => setKeyboardStatus(false)

  const onChangeSearch = (query) => {
    setSearchQuery(query)
    if (query === "") {
      setData(eventStore.places)
    } else {
      const search = query.toLowerCase()
      setData(
        eventStore.places.filter((place: Place) => {
          if (
            place.name.toLowerCase().includes(search) ||
            place.city.toLowerCase().includes(search) ||
            place.street.toLowerCase().includes(search)
          ) {
            return place
          }
          return null
        }),
      )
    }
  }

  const [valid, setValid] = React.useState(false)

  React.useEffect(() => {
    if (errors.placeId) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [values, errors])

  return (
    <View style={[{ margin: spacing[4] }, !isWeb && FLEX1]}>
      <View style={!isWeb && (keyboardStatus ? FLEX1 : FLEX09)}>
        <FlatList
          keyboardShouldPersistTaps="always"
          keyExtractor={(item) => item.objectId}
          ListHeaderComponent={
            <>
              {selectedPlace && (
                <View style={[PLACE_ENTRY, SELECTED]}>
                  <View style={PLACE_AVATAR}>
                    {selectedPlace.image ? (
                      <Image source={{ uri: selectedPlace.image }} style={PLACE_IMAGE} />
                    ) : (
                      <Icon name="map-pin" size={36} />
                    )}
                  </View>
                  <View>
                    <Text style={PLACE_NAME}>{selectedPlace.name}</Text>
                    <Text style={PLACE_ADDRESS}>
                      {selectedPlace.street} {selectedPlace.street_nr}
                    </Text>
                    <Text style={PLACE_ADDRESS}>
                      {selectedPlace.zipcode} {selectedPlace.city}
                    </Text>
                  </View>
                  <View style={RESET_BUTTON}>
                    <Button
                      preset="pale"
                      onPress={() => {
                        selectPlace(null)
                        setFieldValue("placeId", "")
                        onChangeSearch("")
                      }}
                    >
                      <Icon name="x" />
                    </Button>
                  </View>
                </View>
              )}

              <Searchbar
                placeholder={translate("event.search")}
                onChangeText={onChangeSearch}
                value={searchQuery}
              />
              <Button
                onPress={() => setShowModal(true)}
                preset="pale"
                style={{ alignSelf: "flex-end", flexDirection: "row" }}
              >
                <Icon name="plus" color={palette.purple} />
                <Text tx="place.addnew" style={{ color: palette.purple }} />
              </Button>
              <View style={keyboardStatus ? { display: "none" } : !isWeb && FLEX01}>
                <EventFormBottomButtons
                  nextPage={nextPage}
                  previousPage={previousPage}
                  disabled={!valid}
                />
              </View>
            </>
          }
          data={data}
          renderItem={({ item }) => {
            const backgroundColor =
              item.objectId === (selectedPlace || {}).objectId ? "#6e3b6e" : "#f9c2ff"
            const color = item.objectId === (selectedPlace || {}).objectId ? "green" : "purple"

            return (
              <Item
                item={item}
                backgroundColor={backgroundColor.toString()}
                textColor={{ color }}
                onPress={() => {
                  console.log("picked: ", item)
                  setFieldValue("placeId", item.objectId)
                  selectPlace(item)
                  Keyboard.dismiss()
                  setData([])
                }}
              />
            )
          }}
        />
      </View>
      <Modal
        visible={showModal}
        style={{
          flex: 1,
          backgroundColor: palette.white,
          marginTop: 0,
          margin: -5,
          elevation: 5,
          borderRadius: 5,
        }}
      >
        <ScrollView keyboardShouldPersistTaps="always">
          <PlaceForm onCancel={() => setShowModal(false)} onCreatePlace={onCreatePlace} />
        </ScrollView>
      </Modal>
    </View>
  )
}
