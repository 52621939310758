/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { palette } from "../../theme/palette"

export interface EventFormStepValidationProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  errors
  active?: number
}

/**
 * Describe your component here
 */
export const EventFormStepValidation = observer(function EventFormStepValidation(
  props: EventFormStepValidationProps,
) {
  const { errors, active } = props

  const page1: boolean =
    errors.name || errors.startDate || errors.startTime || errors.endDate || errors.endTime
  const page2: boolean = errors.placeId
  const page3: boolean = errors.desc || errors.tags
  const page4: boolean = errors.image

  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomColor: palette.morealphawhite,
        borderBottomWidth: 2,
      }}
    >
      <View
        style={{
          width: "25%",
          height: 4,
          opacity: active === 1 ? 1 : 0.7,
          backgroundColor: page1 ? palette.orange : palette.purple,
        }}
      ></View>
      <View
        style={{
          width: "25%",
          height: 4,
          opacity: active === 2 ? 1 : 0.7,
          backgroundColor: page2 ? palette.orange : palette.purple,
        }}
      ></View>
      <View
        style={{
          width: "25%",
          height: 4,
          opacity: active === 3 ? 1 : 0.7,
          backgroundColor: page3 ? palette.orange : palette.purple,
        }}
      ></View>
      <View
        style={{
          width: "25%",
          height: 4,
          opacity: active === 4 ? 1 : 0.7,
          backgroundColor: page4 ? palette.orange : palette.purple,
        }}
      ></View>
    </View>
  )
})
