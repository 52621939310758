import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { RadioStationModel } from "../radio-station/radio-station"
import { TimeTableModel } from "../time-table/time-table"

/**
 * Model description here for TypeScript hints.
 */
export const ProgramOverviewModel = types.model("ProgramOverview").props({
  objectId: types.identifier,
  radio_id: types.reference(RadioStationModel),
  title: types.string,
  description: types.string,
  timetable: types.optional(types.array(TimeTableModel), []),
  sort: types.number,
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ProgramOverviewType = Instance<typeof ProgramOverviewModel>
export interface ProgramOverview extends ProgramOverviewType {}
type ProgramOverviewSnapshotType = SnapshotOut<typeof ProgramOverviewModel>
export interface ProgramOverviewSnapshot extends ProgramOverviewSnapshotType {}
