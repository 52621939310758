/* eslint-disable react/display-name */
import * as React from "react"
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer"
import { PaywallScreen } from "../screens/paywall/paywall-screen"
import { MainNavigator } from "./main-navigator"
import { useStores } from "../models"
import { LinearGradient } from "expo-linear-gradient"
import { FLEX1, ROW } from "../utils/style"
import { Avatar } from "react-native-paper"
import { Linking, TextStyle, ViewStyle } from "react-native"
import { spacing, typography } from "../theme"
import { translate } from "../i18n"
import { Feather } from "@expo/vector-icons"
import { palette } from "../theme/palette"
import { useLinkTo } from "@react-navigation/native"
import { AboutScreen } from "../screens/about/about-screen"
import { PrivacyScreen } from "../screens/privacy/privacy-screen"
import { SupportScreen } from "../screens/support/support-screen"
import { RedeemScreen } from "../screens/redeem/redeem-screen"
import { Text } from "../components"
import { APP_VERSION } from "../app"
import { LogoutItem } from "../components/logout-item/logout-item"
import { AccountNavigator } from "./account-navigator"
import usePlatform from "../hooks/usePlatform"
import { View } from "@dripsy/core"
import { observer } from "mobx-react-lite"

export const MetaMenu = createDrawerNavigator()

export const AVATAR: ViewStyle = {
  margin: spacing[2],
}

const HELLO: TextStyle = {
  color: palette.morealphawhite,
  fontSize: typography.size[4],
}

export type PrimaryParamList = {
  Settings: undefined
  Pay: undefined
  Main: undefined
}

// Our main navigation
const Drawer = createDrawerNavigator()

export const MetaNavigator = observer(function MetaNavigator() {
  const rootStore = useStores()
  const { isIos, isWeb } = usePlatform()
  const linkTo = useLinkTo()

  React.useEffect(() => {
    const setup = async () => {
      console.log("setting up start page")

      const initialUrl = await Linking.getInitialURL()
      const url = new URL(initialUrl)
      if (url) {
        console.log("we have an url")

        try {
          linkTo(url.pathname)
        } catch (e) {
          console.log("try failed")
          linkTo("/radio")
        }
      } else {
        console.log("default to Radio Screen")
        linkTo("/radio")
      }
    }
    !rootStore.hasAccess && rootStore.checkAccess()
    isWeb && setup()
  }, [])

  if (!rootStore || !rootStore.userStore.me) return null

  return (
    <Drawer.Navigator
      initialRouteName={"Main"}
      drawerContent={(props) => {
        return (
          <LinearGradient
            colors={["#373074", "#5d3459", "#bf5b48", "#dd6629", "#e4841f", "#eba804"]}
            // eslint-disable-next-line react-native/no-inline-styles
            style={FLEX1}
            start={{ x: 0, y: 1 }}
            end={{ x: 0.8, y: 0 }}
          >
            <DrawerContentScrollView {...props}>
              <DrawerItem
                label=""
                to="Profile"
                onPress={() => linkTo("/profile")}
                icon={() => {
                  return (
                    <View sx={{ flexDirection: "row" }}>
                      {rootStore.userStore.me.avatar ? (
                        <Avatar.Image
                          style={AVATAR}
                          size={64}
                          source={{ uri: rootStore.userStore.me.avatar }}
                        />
                      ) : (
                        <Avatar.Text size={64} label={rootStore.userStore.me.initials} />
                      )}
                      <View sx={{ justifyContent: "center", marginLeft: "$l" }}>
                        <Text style={HELLO} tx="common.hello" />
                        <Text style={HELLO}>{rootStore.userStore.me.name}</Text>
                      </View>
                    </View>
                  )
                }}
                labelStyle={{ color: palette.white }}
              />
              <DrawerItemList {...props} labelStyle={{ color: palette.white }} />
              <LogoutItem />
            </DrawerContentScrollView>
            <Text style={{ margin: spacing[1], color: palette.tinyalphawhite }}>{APP_VERSION}</Text>
          </LinearGradient>
        )
      }}
    >
      <Drawer.Screen
        name="Main"
        component={MainNavigator}
        options={{
          headerShown: false,
          drawerIcon: () => <Feather name="home" size={20} color={"white"} />,
          title: translate("menu.home"),
        }}
      />
      <Drawer.Screen
        name="Account"
        component={AccountNavigator}
        options={{
          headerShown: false,
          drawerIcon: () => <Feather name="user" size={20} color={"white"} />,
          title: translate("acc.my"),
        }}
      />
      <Drawer.Screen
        name="Sub"
        component={PaywallScreen}
        options={{
          headerShown: false,
          drawerIcon: () => <Feather name="award" size={20} color={"white"} />,
          title: translate("menu.sub"),
        }}
      />
      <Drawer.Screen
        name="About"
        component={AboutScreen}
        options={{
          headerShown: false,
          drawerIcon: () => <Feather name="info" size={20} color={"white"} />,
          title: translate("menu.about"),
        }}
      />
      <Drawer.Screen
        name="Support"
        component={SupportScreen}
        options={{
          headerShown: false,
          drawerIcon: () => <Feather name="help-circle" size={20} color={"white"} />,
          title: translate("menu.support"),
        }}
      />
      <Drawer.Screen
        name="Privacy"
        component={PrivacyScreen}
        options={{
          headerShown: false,
          drawerIcon: () => <Feather name="shield" size={20} color={"white"} />,
          title: translate("menu.privacy"),
        }}
      />
      {!isIos && (
        <Drawer.Screen
          name="Redemption"
          component={RedeemScreen}
          options={{
            headerShown: false,
            drawerIcon: () => <Feather name="gift" size={20} color={"white"} />,
            title: translate("menu.redeem"),
          }}
        />
      )}
    </Drawer.Navigator>
  )
})
