import * as React from "react"
import { Dimensions, Image, ImageStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Header } from "../header/header"
import { DrawerActions, useLinkTo } from "@react-navigation/native"
import { palette } from "../../theme/palette"
import usePlatform from "../../hooks/usePlatform"
import { Logo } from ".."
import { View } from "@dripsy/core"
import { Banner } from "../banner/banner"

const UNIVERSAL: ImageStyle = {
  marginLeft: -30,
  height: 21,
  width: 197,
}

const UNIVERSAL_CENTER: ImageStyle = {
  height: 35,
  resizeMode: "contain",
  alignSelf: "center",
}

export interface UniversalHeaderProps {
  /**
   * An optional style override useful for padding & margin.
   */
  nav: any
  canGoBack?
  colorful?: boolean
  nologo?: boolean
  [x: string]: any
  web?: boolean
  onGoBack?: any
  menuShown?: boolean
}

/**
 * Describe your component here
 */
export const UniversalHeader = observer(function UniversalHeader(props: UniversalHeaderProps) {
  const {
    nav,
    canGoBack,
    onGoBack,
    colorful,
    nologo,
    web = false,
    menuShown = true,
    ...other
  } = props
  const linkTo = useLinkTo()
  const { isMobile, isPortrait } = usePlatform()

  function openDrawer() {
    nav.dispatch(DrawerActions.openDrawer())
  }

  function goBack() {
    if (onGoBack) {
      onGoBack()
    } else {
      nav.goBack()
    }
  }

  if (web) {
    return (
      <View
        sx={{
          width: "100%",
        }}
      >
        <Header
          style={{ justifyContent: "space-between" }}
          rightIcon={
            menuShown && {
              name: "more-vertical",
              color: colorful ? palette.darkpurple : palette.morealphawhite,
            }
          }
          onRightPress={menuShown && openDrawer}
          web={menuShown && !isMobile && !isPortrait}
          onLinkTo={linkTo}
          {...other}
        >
          {!nologo && (
            <View sx={{ marginRight: "$50" }}>
              <Banner rgb={colorful} />
            </View>
          )}
        </Header>
      </View>
    )
  }

  if (canGoBack) {
    return (
      <>
        <Header
          leftIcon={{
            name: "arrow-left",
            color: colorful ? palette.darkpurple : palette.morealphawhite,
          }}
          onLeftPress={goBack}
          rightIcon={{
            name: "more-vertical",
            color: colorful ? palette.darkpurple : palette.morealphawhite,
          }}
          style={web && { paddingHorizontal: 300 }}
          onRightPress={openDrawer}
          {...other}
        >
          {!nologo && (
            <View sx={{ marginLeft: "auto", marginRight: "auto" }}>
              <Banner rgb={colorful} />
            </View>
          )}
        </Header>
      </>
    )
  }

  return (
    <>
      <Header
        rightIcon={{
          name: "more-vertical",
          color: colorful ? palette.darkpurple : palette.morealphawhite,
        }}
        onRightPress={openDrawer}
        {...other}
      >
        {!nologo && (
          <View sx={{ mr: "$50" }}>
            <Banner rgb={colorful} />
          </View>
        )}
      </Header>
    </>
  )
})
