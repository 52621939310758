import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { QuestionModel } from "../question/question"

/**
 * Model description here for TypeScript hints.
 */
export const MetaModel = types
  .model("Meta")
  .props({
    locale: types.string,
    vision: types.maybe(types.string),
    about: types.maybe(types.string),
    timeline: types.maybe(types.string),
    privacy: types.maybe(types.string),
    support: types.maybe(types.string),
    faq: types.optional(types.array(QuestionModel), []),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type MetaType = Instance<typeof MetaModel>
export interface Meta extends MetaType {}
type MetaSnapshotType = SnapshotOut<typeof MetaModel>
export interface MetaSnapshot extends MetaSnapshotType {}
