import { palette } from "./palette"
import { makeTheme } from "dripsy"

const dimensions = {
  // feel the fibunacci
  $none: 0, // nothing absolutely nothing
  $xxs: 1, // same context close together
  $xs: 2, // grouped components
  $s: 3,
  $m: 5,
  $l: 8,
  $xl: 13, // these things have nothing to do with each other
  $xxl: 21,
  $xxxl: 34, // wooow
}

const percentages = {
  $50: "50%",
  $25: "25%",
  $12: "12%",
  $33: "33%",
  $66: "66%",
  $100: "100%",
}

const theme = makeTheme({
  colors: {
    $mainBackground: palette.white,
    $modalBackground: palette.offwhite,
    $mainTextColor: palette.darkerpurple,
  },
  space: { ...dimensions, ...percentages },
  borderRadius: dimensions,
  fontSizes: {
    $0: 12,
    $1: 14,
    $2: 16,
    $3: 18,
    $4: 24,
    $5: 28,
    $6: 32,
  },
  text: {
    header: {
      fontFamily: "ShopifySans-Bold",
      fontWeight: "bold",
      fontSize: 34,
      lineHeight: 42.5,
      color: palette.darkerpurple,
    },
    subheader: {
      fontFamily: "ShopifySans-SemiBold",
      fontWeight: "600",
      fontSize: 28,
      lineHeight: 36,
      color: palette.darkerpurple,
    },
    body: {
      fontFamily: "ShopifySans",
      fontSize: 16,
      lineHeight: 24,
      color: palette.darkerpurple,
    },
  },
})

export default theme
export type Theme = typeof theme
declare module "dripsy" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DripsyCustomTheme extends Theme {}
}
