/* eslint-disable react-native/sort-styles */
/* eslint-disable react-native/no-color-literals */
import * as React from "react"
import { ViewStyle, TouchableWithoutFeedback, StyleSheet } from "react-native"
import { observer } from "mobx-react-lite"
import { Portal, Modal } from "react-native-paper"
import { View } from "@dripsy/core"

export interface MyModalProps {
  /**
   * An optional style override useful for padding & margin.
   */
  visible: boolean
  dismiss?: any
  dismissable?: boolean
  children
  plain?: boolean
}

/**
 * Describe your component here
 */
export const MyModal = observer(function MyModal(props: MyModalProps) {
  const { visible, dismiss, dismissable = true, children, plain } = props
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={dismissable && dismiss}
        dismissable={dismissable}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{ shadowOpacity: 0 }}
      >
        <View sx={{ alignItems: "center", justifyContent: "center" }}>
          {plain ? (
            children
          ) : (
            <View
              sx={{
                backgroundColor: "$modalBackground",
                borderRadius: "$xl",
                padding: "$xxl",
                margin: "$xxl",
              }}
            >
              {children}
            </View>
          )}
        </View>
      </Modal>
    </Portal>
  )
})
