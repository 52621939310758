import * as React from "react"
import { TextStyle, View, ViewStyle, Image, Keyboard } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { ActivityIndicator, TextInput } from "react-native-paper"
import { Formik } from "formik"
import { Button } from "../button/button"
import * as Yup from "yup"
import { translate } from "../../i18n"
import { CollapsedError } from "../collapsed-error/CollapsedError"
import { palette } from "../../theme/palette"
import { useStores } from "../../models"
import Collapsible from "react-native-collapsible"
import { Text } from "../text/text"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { useToast } from "react-native-toast-notifications"

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[4],
  color: palette.morealphawhite,
  textAlign: "center",
  padding: spacing[3],
}

export interface RedeemFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required(translate("sub.coderequired")),
})

/**
 * Describe your component here
 */
export const RedeemForm = observer(function RedeemForm(props: RedeemFormProps) {
  const rootStore = useStores()
  const toast = useToast()
  const [showSuccess, setShowSuccess] = React.useState(false)
  const nav = useNavigation()
  const linkTo = useLinkTo()

  const enterTheVoid = () => {
    rootStore.unlock()
    setShowSuccess(false)
    // nav.navigate("Meta", { screen: "Main" })
    linkTo("/radio")
  }

  return (
    <Formik
      initialValues={{
        code: "",
      }}
      onSubmit={async (values, actions) => {
        const result = await rootStore.redeemCode(values.code)
        if (result === "success") {
          setShowSuccess(true)
        } else {
          toast.show(result)
        }
        Keyboard.dismiss()
        actions.setSubmitting(false)
      }}
      validationSchema={validationSchema}
    >
      {({ handleChange, values, submitForm, errors, isValid, isSubmitting, handleBlur }) => (
        <>
          <TextInput
            // this is used as active and passive border color
            // eslint-disable-next-line react-native/no-inline-styles
            value={values.code}
            label={translate("sub.code")}
            onChangeText={handleChange("code")}
            returnKeyType="done"
            autoCapitalize="none"
            onBlur={handleBlur("code")}
            error={errors.code as any}
            style={{ marginBottom: spacing[2] }}
          />
          <CollapsedError error={errors.code} />
          {/* if we are submitting show activity indicator else login button */}
          {isSubmitting ? (
            <ActivityIndicator animating={true} color={palette.purple} size={30} />
          ) : (
            <>
              <Button
                onPress={submitForm}
                disabled={!isValid || isSubmitting}
                preset="secondary"
                tx="sub.redeem"
              />
            </>
          )}
          <Collapsible collapsed={!showSuccess}>
            <Text style={TEXT} tx="sub.success" />
            <Button tx="sub.gotoMain" onPress={enterTheVoid} />
          </Collapsible>
        </>
      )}
    </Formik>
  )
})
