import * as React from "react"
import { ActivityIndicator, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { Button } from "../button/button"
import { palette } from "../../theme/palette"
import { ErrorMessage } from "../error-message/error-message"
import { Formik } from "formik"
import { useStores } from "../../models"
import * as Yup from "yup"
import { translate } from "../../i18n"
import { TextInput } from "react-native-paper"
import Collapsible from "react-native-collapsible"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  margin: spacing[3],
}

const MESSAGE: TextStyle = {
  padding: spacing[2],
  fontFamily: typography.secondary,
  fontSize: typography.size[4],
  color: color.secondary,
  textAlign: "center",
}

const ERROR: TextStyle = {
  color: palette.angry,
}

const BUTTON: ViewStyle = {
  marginVertical: spacing[2],
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Enter a valid email").required("Please enter your email address"),
})

export interface ForgotPasswordFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

/**
 * Describe your component here
 */
export const ForgotPasswordForm = observer(function ForgotPasswordForm(
  props: ForgotPasswordFormProps,
) {
  const { style } = props
  const rootStore = useStores()
  const [message, setMessage] = React.useState("")
  const [error, setError] = React.useState(false)

  return (
    <View style={[CONTAINER, style]}>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={async (values, actions) => {
          await rootStore.forgotPW(values.email)
          if (rootStore.state === "error") {
            // IN CASE THE LOGIN FAILED WE RETURN THE ERROR
            setMessage(translate("auth.resetpwfail"))
            setError(true)
            values.email = ""
            actions.setSubmitting(false)
          }
          if (rootStore.state === "done") {
            // IN CASE THE LOGIN FAILED WE RETURN THE ERROR
            values.email = ""
            setMessage(translate("auth.resetpwsuccess"))
            actions.setSubmitting(false)
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          submitForm,
          errors,
          isValid,
          isSubmitting,
          touched,
          handleBlur,
        }) => (
          <>
            <TextInput
              placeholder={translate("auth.email")}
              // this is used as active and passive border color
              // eslint-disable-next-line react-native/no-inline-styles
              value={values.email}
              keyboardType="email-address"
              onChangeText={handleChange("email")}
              returnKeyType="done"
              autoCapitalize="none"
              onBlur={handleBlur("email")}
            />
            <ErrorMessage errorValue={touched.email && errors.email} />

            {/* if we are submitting show activity indicator else login button */}
            {isSubmitting ? (
              <ActivityIndicator animating={true} color={palette.purple} size={30} />
            ) : (
              <>
                <Button
                  style={BUTTON}
                  onPress={submitForm}
                  disabled={!isValid || isSubmitting}
                  preset="secondary"
                  tx="auth.resetpw"
                />
              </>
            )}
          </>
        )}
      </Formik>
      <Collapsible collapsed={!message}>
        <View
          style={{
            backgroundColor: palette.alphawhite,
            borderRadius: spacing[3],
            margin: spacing[3],
            padding: spacing[3],
          }}
        >
          <Text style={[MESSAGE, error && ERROR]}>{message}</Text>
        </View>
      </Collapsible>
    </View>
  )
})
