import React, { useState, useEffect } from "react"
import {
  Image,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Alert,
  ImageStyle,
  Pressable,
} from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, typography } from "../../theme"
import { Text } from "../"
import { IconButton } from "react-native-paper"
import { useIsFocused, useLinkTo, useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { MyLanguage } from "../../i18n/i18n"
import { palette } from "../../theme/palette"
import { translate } from "../../i18n"
import Collapsible from "react-native-collapsible"
import { Icon } from "../icon/icon"
import { ROW, VC } from "../../utils/style"
import { MyModal } from "../my-modal/my-modal"
import { Button } from "../button/button"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
  marginTop: spacing[3],
}

const EVENT_ITEM: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: spacing[2],
  borderRadius: spacing[4],
  backgroundColor: palette.morealphawhite,
}

const EVENT_IMAGE_WRAPPER: ViewStyle = {
  //   width: 150,
  //   height: 150,
  justifyContent: "center",
  alignItems: "center",
  padding: spacing[1],
}
const EVENT_IMAGE: ImageStyle = {
  width: 80,
  height: 80,
  borderRadius: spacing[3],
}

const EVENT_INFO_WRAPPER: ViewStyle = {
  paddingVertical: spacing[1],
  paddingLeft: spacing[1],
  paddingRight: 0,
  flexGrow: 2,
  flex: 1,
  justifyContent: "space-evenly",
}

const DATE: TextStyle = {
  fontSize: typography.size[1],
  fontFamily: typography.secondary,
}

const TITLE: TextStyle = {
  fontWeight: "bold",
  fontSize: typography.size[2],
  fontFamily: typography.secondary,
  lineHeight: typography.size[3],
}

const HEADER: TextStyle = {
  fontWeight: "bold",
  fontSize: typography.size[3],
  fontFamily: typography.primary,
}

const PLACE: TextStyle = {
  fontWeight: "bold",
  fontSize: typography.size[1],
  fontFamily: typography.secondary,
  color: palette.paleorange,
}

export interface ProfileEventListProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  userId: string
  showPast?: boolean
}

/**
 * Describe your component here
 */
export const ProfileEventList = observer(function ProfileEventList(props: ProfileEventListProps) {
  const { style, userId, showPast } = props

  const { eventStore, userStore } = useStores()
  const [events, setEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [collapsed, setCollapsed] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [eventTobeDeleted, setEventToBeDeleted] = useState(null)
  const isFocused = useIsFocused()
  const linkTo = useLinkTo()

  useEffect(() => {
    setEvents(eventStore.getFutureEventByUserId(userId))
    setPastEvents(eventStore.getPastEventByUserId(userId))
  }, [isFocused])

  const goToEvent = (eventId) => {
    eventStore.setCurrentEvent(eventId)
    linkTo("/events/detail")
  }

  const goToEditEvent = (eventId) => {
    console.log(eventId)
    eventStore.setCurrentEvent(eventId)
    linkTo("/events/edit")
  }

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const toggleDeleteEventModal = (objectId) => {
    setEventToBeDeleted(objectId)
    setShowDeleteModal(!showDeleteModal)
  }

  const deleteEvent = (thisEvent) => {
    console.log("trying to delete event")
    setShowDeleteModal(false)
    eventStore.setCurrentEvent(undefined)
    eventStore.deleteEvent(thisEvent)
    setEvents(eventStore.getFutureEventByUserId(userId))
    setPastEvents(eventStore.getPastEventByUserId(userId))
  }

  if (!eventStore || !userStore || !events) return null

  const isme = userStore.me.objectId === userId

  return (
    <>
      <View style={{ padding: spacing[4] }} />
      {isme ? (
        <Text style={HEADER} tx="profile.myevents" />
      ) : (
        <View style={ROW}>
          <Text style={HEADER} tx="profile.hisherevents" />
          <Text style={HEADER}>{userStore.getUserById(userId).name}</Text>
        </View>
      )}
      <View style={[CONTAINER, style]}>
        {events.map((event) => {
          return (
            <Pressable key={event.objectId} onPress={() => goToEvent(event.objectId)}>
              <View style={EVENT_ITEM}>
                <View style={EVENT_IMAGE_WRAPPER}>
                  <Image style={EVENT_IMAGE} source={{ uri: event.image }} />
                </View>
                <View style={EVENT_INFO_WRAPPER}>
                  <Text style={DATE}>
                    {event.startDate
                      .toLocaleDateString(MyLanguage, {
                        weekday: "short",
                        month: "short",
                        day: "numeric",
                      })
                      .split(".")
                      .join("")}
                    {event.endDate &&
                      " - " +
                        event.endDate
                          .toLocaleDateString(MyLanguage, {
                            weekday: "short",
                            month: "short",
                            day: "numeric",
                          })
                          .split(".")
                          .join("")}
                  </Text>
                  <Text style={TITLE} numberOfLines={2}>
                    {event.name}
                  </Text>
                  <Text style={PLACE}>
                    {event.place_id.name}
                    {", "}
                    {event.place_id.city}
                  </Text>
                </View>
                {isme && (
                  <View>
                    <IconButton
                      icon="pencil"
                      color={palette.orange}
                      size={20}
                      onPress={() => goToEditEvent(event.objectId)}
                    />
                    <IconButton
                      icon="delete"
                      color={palette.orange}
                      size={20}
                      onPress={() => toggleDeleteEventModal(event)}
                    />
                  </View>
                )}
              </View>
            </Pressable>
          )
        })}
      </View>
      {isme && showPast && pastEvents.length > 0 && (
        <>
          <View style={{ padding: spacing[4] }} />
          <TouchableOpacity onPress={toggle} style={[ROW, HEADER, VC]}>
            <Text style={HEADER} tx="profile.mypastevents" />
            <Icon name={collapsed ? "chevron-down" : "chevron-up"} color={palette.alphablack} />
          </TouchableOpacity>
          <Collapsible collapsed={collapsed}>
            <View style={[CONTAINER, style]}>
              {pastEvents.map((event) => {
                return (
                  <TouchableOpacity key={event.objectId} onPress={() => goToEvent(event.objectId)}>
                    <View style={EVENT_ITEM}>
                      <View style={EVENT_IMAGE_WRAPPER}>
                        <Image style={EVENT_IMAGE} source={{ uri: event.image }} />
                      </View>
                      <View style={EVENT_INFO_WRAPPER}>
                        <Text style={DATE}>
                          {event.startDate.toLocaleDateString(MyLanguage, {
                            weekday: "short",
                            month: "short",
                            day: "numeric",
                          })}
                        </Text>
                        <Text style={TITLE} numberOfLines={2}>
                          {event.name}
                        </Text>
                        <Text style={PLACE}>
                          {event.place_id.name}
                          {", "}
                          {event.place_id.city}
                        </Text>
                      </View>
                      <View>
                        <IconButton
                          icon="pencil"
                          color={palette.orange}
                          size={20}
                          onPress={() => goToEditEvent(event.objectId)}
                        />
                        <IconButton
                          icon="delete"
                          color={palette.orange}
                          size={20}
                          onPress={() => toggleDeleteEventModal(event.objectId)}
                        />
                      </View>
                    </View>
                  </TouchableOpacity>
                )
              })}
            </View>
          </Collapsible>
        </>
      )}
      <MyModal visible={showDeleteModal} dismissable dismiss={() => setShowDeleteModal(false)}>
        <Text preset="header" tx="event.action.delete.ask" />
        <Text tx="event.action.delete.info" />
        <Button
          tx="common.cancel"
          preset="secondary"
          style={{ marginVertical: spacing[3] }}
          onPress={() => toggleDeleteEventModal(null)}
        />
        <Button tx="event.action.delete.confirm" onPress={() => deleteEvent(eventTobeDeleted)} />
      </MyModal>
      <View style={{ padding: spacing[4] }} />
    </>
  )
})
