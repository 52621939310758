/* eslint-disable generator-star-spacing */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import rnm from "@rn-matrix/core"
import { withEnvironment } from ".."
import { translate } from "../../i18n"

/**
 * Model description here for TypeScript hints.
 */
export const MatrixStoreModel = types
  .model("MatrixStore")
  .props({
    // state: types.enumeration("State", ["pending", "done", "error"]),
    authToken: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    signIn: flow(function* signIn(username, password) {
      // self.state = "pending"

      try {
        __DEV__ && console.log("MX Login attempt")
        // const result: GetLoginResult = yield parseApi.loginUser(username, password)
        // parseapi - dev.back4app.com / functions / hello
        // http: if (result.kind === "ok") {
        //   self.sessiontoken = result.data.sessionToken
        //   self.me = result.data.objectId
        //   self.userStore.setMe()
        //   if (Platform.OS !== "web") {
        //     yield Purchases.identify(self.me)
        //   }
        //   self.isSignedIn = true
        //   self.state = "done"
        //   return "success"
        // } else {
        //   __DEV__ && console.log("Login Failed : ", result)
        //   self.isSignedIn = false
        //   self.sessiontoken = ""
        //   self.state = "done"
        //   return translate("errors." + result.kind)
      } catch (e) {
        console.log(e)
        // self.state = "error"
        return translate("auth.errors.problem")
      }
    }),
    afterCreate() {
      // We are done setting up rootStore
      // self.state = "done"
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type MatrixStoreType = Instance<typeof MatrixStoreModel>
export interface MatrixStore extends MatrixStoreType {}
type MatrixStoreSnapshotType = SnapshotOut<typeof MatrixStoreModel>
export interface MatrixStoreSnapshot extends MatrixStoreSnapshotType {}
