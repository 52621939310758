import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { RadioScreen } from "../screens/radio/radio-screen"
import { PodcastNav } from "./podcast-navigator"
import { ProgramScreen } from "../screens/program/program-screen"
import { ProgramOverviewScreen } from "../screens/program-overview/program-overview-screen"
import { RadioStationAboutScreen } from "../screens/radio-station-about/radio-station-about-screen"
import { HumanScreen } from "../screens/human/human-screen"

const Stack = createStackNavigator()

export function RadioNav() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="Radio"
      detachInactiveScreens
    >
      <Stack.Screen
        options={{ headerShown: false, detachPreviousScreen: true }}
        name="Radio"
        component={RadioScreen}
      />
      <Stack.Screen options={{ headerShown: false }} name="Podcasts" component={PodcastNav} />
      <Stack.Screen options={{ headerShown: false }} name="Program" component={ProgramScreen} />
      <Stack.Screen
        options={{ headerShown: false }}
        name="RadioStationAbout"
        component={RadioStationAboutScreen}
      />
      <Stack.Screen options={{ headerShown: false }} name="Human" component={HumanScreen} />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Overview"
        component={ProgramOverviewScreen}
      />
    </Stack.Navigator>
  )
}
