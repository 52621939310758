import React from "react"
import { observer } from "mobx-react-lite"
import { StatusBar, View, ViewStyle } from "react-native"
import { Container, Header, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color } from "../../theme"
import { EventForm } from "../../components/event-form/event-form"
import { palette } from "../../theme/palette"
import Bugsnag from "@bugsnag/react-native"
import { FLEX1 } from "../../utils/style"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  flex: 1,
}

export const EventCreateScreen = observer(function EventCreateScreen() {
  // Pull in one of our MST stores
  // Bugsnag.leaveBreadcrumb("Screen: Event Create SCreen")

  // Pull in navigation via hook
  const nav = useNavigation()
  const goBack = () => {
    nav.goBack()
  }

  return (
    <Screen style={ROOT} preset="fixed">
      <Container>
        <View style={[{ backgroundColor: palette.white }, FLEX1]}>
          <StatusBar
            animated={true}
            backgroundColor="#FFFFFF20"
            translucent={true}
            barStyle={"dark-content"}
          />
          <Header
            headerTx="event.action.create"
            leftIcon={{ name: "x", color: palette.purple }}
            onLeftPress={goBack}
          />
          <EventForm />
        </View>
      </Container>
    </Screen>
  )
})
