export const simpleHash = (keyString) => {
  let hash = 0
  let charIndex
  for (charIndex = 0; charIndex < keyString.length; ++charIndex) {
    hash += keyString.charCodeAt(charIndex)
    hash += hash << 10
    hash ^= hash >> 6
  }
  hash += hash << 3
  hash ^= hash >> 11
  // 4,294,967,295 is FFFFFFFF, the maximum 32 bit unsigned integer value, used here as a mask.
  return ((hash + (hash << 15)) & 4294967295) >>> 0
}
