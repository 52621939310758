import * as React from "react"
import { observer } from "mobx-react-lite"
import { Screen } from "../screen/screen"
import { useStores } from "../../models"
import { PaywallPopup } from "../paywall-popup/paywall-popup"
import { ScreenProps } from "../screen/screen.props"

/**
 * Describe your component here
 */
export const RestrictedScreen = observer(function RestrictedScreen(props: ScreenProps) {
  const { children, ...rest } = props
  const rootStore = useStores()

  return (
    <Screen {...rest}>
      {rootStore.state === "done" ||
        (rootStore.state === "error" && !rootStore.hasAccess && <PaywallPopup />)}
      {children}
    </Screen>
  )
})
