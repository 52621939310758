/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Image, ImageBackground, TextStyle, View, ViewStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { ProfileEventList } from "../../components/profile-event-list/profile-event-list"
import { translate } from "../../i18n"
import { Button as MyButton } from "../../components/button/button"
import { Dialog, FAB, Paragraph, Portal, Button } from "react-native-paper"
import { useActionSheet } from "@expo/react-native-action-sheet"
import { MB, ROW } from "../../utils/style"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
}

const TITLE: ViewStyle = {
  backgroundColor: color.palette.lighterGrey,
  borderTopRightRadius: 80,
  flexDirection: "row",
  justifyContent: "space-between",
  paddingVertical: spacing[3],
  paddingLeft: spacing[6],
}

const BODY: ViewStyle = {
  paddingHorizontal: spacing[6],
}

const NAME: TextStyle = {
  fontSize: typography.size[5],
  fontWeight: "bold",
  fontFamily: typography.primary,
}

const SUBNAME: TextStyle = {
  fontSize: typography.size[2],
  fontWeight: "normal",
  color: palette.lightGrey,
  fontFamily: typography.primary,
}

const BIO: TextStyle = {
  fontSize: typography.size[3],
  fontWeight: "normal",
  color: palette.darkerpurple,
  fontFamily: typography.primary,
}
const TOP_BUTTONS: ViewStyle = {
  marginRight: 80,
}

const TOP_BUTTON: ViewStyle = {
  marginTop: 10,
  backgroundColor: palette.purple,
  height: 40,
  width: 40,
  marginHorizontal: 10,
}

const EDIT: ViewStyle = {
  backgroundColor: palette.purple,
}

const DELETE: ViewStyle = {
  backgroundColor: palette.angry,
}

const MODAL: ViewStyle = {
  maxWidth: 800,
  marginHorizontal: "auto",
}

export const ProfileScreen = observer(function ProfileScreen() {
  // Pull in one of our MST stores
  const rootStore = useStores()
  const { userStore } = rootStore
  const linkTo = useLinkTo()

  // fetch profile to stay up to date
  useEffect(() => {
    userStore.updateUser(rootStore.me)
  }, [])

  const edit = () => {
    // nav.navigate("EditProfile")
    linkTo("/profile/edit")
  }

  const [deleteDialogVisible, setDeleteDialaogVisisble] = useState(false)

  const showDelete = () => {
    setDeleteDialaogVisisble(true)
  }

  const hideDelete = () => {
    setDeleteDialaogVisisble(false)
  }

  const deleteProfile = async () => {
    await rootStore.deleteProfile()
  }

  // Actionsheet
  const { showActionSheetWithOptions } = useActionSheet()
  const options = [
    translate("profile.actions.delete.0"),
    translate("profile.actions.edit"),
    "Cancel",
  ]
  const destructiveButtonIndex = 0
  const cancelButtonIndex = 2

  const showActions = () => {
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (index) => {
        if (index === 0) {
          // delete
          showDelete()
        }
        if (index === 1) {
          // edit
          edit()
        }
      },
    )
  }

  // Pull in navigation via hook
  return (
    <Screen style={ROOT} preset="scroll">
      <Container>
        <View
          style={{
            backgroundColor: palette.lighterGrey,
            marginTop: 150,
            borderBottomLeftRadius: 100,
            borderTopRightRadius: 100,
          }}
        >
          <View style={[ROW, { justifyContent: "space-between" }]}>
            <View>
              <Image
                source={{
                  uri: userStore.me.avatar || "https://placekitten.com/640/640",
                }}
                resizeMode="contain"
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  justifyContent: "flex-end",
                  marginTop: -100,
                  marginLeft: 25,
                }}
              />
              <View style={TITLE}>
                <View>
                  <Text style={NAME}>
                    {userStore.me.name} {userStore.me.lastName}
                  </Text>
                  <Text style={SUBNAME}>{(userStore.me.home || {}).city}</Text>
                  <Text style={SUBNAME}>
                    {userStore.me.home.region + ", "}
                    {userStore.me.CountryString}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[BODY, { paddingBottom: spacing[5] }]}>
            <Text style={BIO}>{userStore.me.bio}</Text>
            <ProfileEventList userId={userStore.me.objectId} showPast />
            <MyButton tx="profile.actions.edit" preset="secondary" onPress={edit} style={MB} />
            <MyButton
              tx="profile.actions.delete.0"
              preset="primary"
              onPress={showDelete}
              style={MB}
            />
          </View>
          <Portal>
            <Dialog visible={deleteDialogVisible} onDismiss={hideDelete} style={MODAL}>
              <Dialog.Content>
                <Paragraph>{translate("profile.actions.delete.prompt")}</Paragraph>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={hideDelete}>{translate("common.cancel")}</Button>
                <Button color={palette.angry} onPress={deleteProfile}>
                  {translate("profile.actions.delete.0")}
                </Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </View>
      </Container>
    </Screen>
  )
})
