import React from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { Container, Screen } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color } from "../../theme"
import { ProfileForm } from "../../components/profile-form/profile-form"
import Bugsnag from "@bugsnag/react-native"
import { palette } from "../../theme/palette"

const ROOT: ViewStyle = {
  flex: 1,
  backgroundColor: palette.lighterGrey,
}

export const ProfileEditScreen = observer(function ProfileEditScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Profile edit")

  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const nav = useNavigation()
  return (
    <Screen style={ROOT} preset="scroll">
      <Container>
        <ProfileForm />
      </Container>
    </Screen>
  )
})
