import * as React from "react"
import { Image, ImageStyle, Pressable, TextStyle, View, ViewStyle, Text } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import UserAvatar from "react-native-user-avatar"
import { palette } from "../../theme/palette"
import { TouchableOpacity } from "react-native-gesture-handler"
import * as Linking from "expo-linking"
import Autolink from "react-native-autolink"
import { formatUrl } from "../../utils/formatUrl"
import { openUrl } from "../../utils/openUrl"

const CONTAINER: ViewStyle = {
  justifyContent: "flex-start",
  flexDirection: "row",
  borderRadius: 50,
  marginVertical: spacing[2],
  padding: spacing[1],
  backgroundColor: palette.alphawhite,
}

const NAME: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[4],
  color: color.primary,
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: color.primary,
}

const AVATAR: ImageStyle = {
  borderRadius: 40,
  height: 80,
  width: 80,
}

const SMALL_AVATAR: ImageStyle = { height: 50, width: 50, borderRadius: 25 }
const SMALL_NAME: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: color.primary,
}

const SMALL_TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[2],
  color: color.primary,
}

const IMAGE_CONTAINER: ViewStyle = {}

const INFO: ViewStyle = { paddingLeft: spacing[3], justifyContent: "center", flex: 1 }

export interface HumanBubbleProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  human: { type: string; name: string; avatar: string; value: string }
  small?: boolean
}

/**
 * Describe your component here
 */
export const HumanBubble = observer(function HumanBubble(props: HumanBubbleProps) {
  const { style, human, small } = props

  const url = formatUrl(human.value, human.type)
  const handlePress = () => {
    openUrl(url)
  }

  const niceLink = human.value.substr(human.value.indexOf("://") + 3)

  return (
    <Pressable onPress={handlePress}>
      <View style={[CONTAINER, style]}>
        <View style={IMAGE_CONTAINER}>
          <Image style={small ? SMALL_AVATAR : AVATAR} source={{ uri: human.avatar }} />
        </View>
        <View style={INFO}>
          <Text style={small ? SMALL_NAME : NAME} numberOfLines={1}>
            {human.name}
          </Text>
          <Autolink linkStyle={small ? SMALL_TEXT : TEXT} text={niceLink} numberOfLines={1} />
        </View>
      </View>
    </Pressable>
  )
})
