import React from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle } from "react-native"
import { Header, Screen, Text } from "../../components"
import { color } from "../../theme"
import { PlaceForm } from "../../components/place-form/place-form"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { palette } from "../../theme/palette"
import Bugsnag from "@bugsnag/react-native"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
}

export const PlaceCreateScreen = observer(function PlaceCreateScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Create Place")

  const nav = useNavigation()
  const linkTo = useLinkTo()
  const goBack = () => {
    // nav.navigate("Events", { screen: "CreateEvent" })
    linkTo("/events/create")
  }

  return (
    <Screen style={ROOT} preset="scroll">
      <Header
        headerTx="place.create"
        leftIcon={{ name: "x", color: palette.purple }}
        onLeftPress={goBack}
      />
      <PlaceForm />
    </Screen>
  )
})
