import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ChatRoomMessageModel } from "../chat-room-message/chat-room-message"
import { RadioStationModel } from "../radio-station/radio-station"

/**
 * Model description here for TypeScript hints.
 */
export const ChatRoomModel = types.model("ChatRoom").props({
  objectId: types.identifier,
  radio_id: types.reference(RadioStationModel),
  message: ChatRoomMessageModel,
  createdAt: types.string,
})
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ChatRoomType = Instance<typeof ChatRoomModel>
export interface ChatRoom extends ChatRoomType {}
type ChatRoomSnapshotType = SnapshotOut<typeof ChatRoomModel>
export interface ChatRoomSnapshot extends ChatRoomSnapshotType {}
