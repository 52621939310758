/* eslint-disable generator-star-spacing */
import { Instance, SnapshotOut, types, flow } from "mobx-state-tree"
import { GetGeneralResults, ParseApi } from "../../services/api"
import { ChatRoomModel } from "../chat-room/chat-room"
import { withEnvironment } from "../extensions/with-environment"

/**
 * Model description here for TypeScript hints.
 */
export const ChatRoomStoreModel = types
  .model("ChatRoomStore")
  .props({
    chatrooms: types.optional(types.array(ChatRoomModel), []),
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    currentChatroom: types.maybe(types.reference(ChatRoomModel)),
  })
  .extend(withEnvironment)
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    load: flow(function* load() {
      self.state = "pending"
      self.chatrooms.clear()
      console.log("loading Chatrooms...")
      try {
        const parseApi = new ParseApi()
        const result: GetGeneralResults = yield parseApi.getAllOfClass(
          "ChatRoom",
          {},
          {},
          "-createdAt",
        )

        if (result.kind === "ok") {
          if (result.data.results.length > 0) {
            self.chatrooms = result.data.results.map((chatroom) => {
              const message = {
                _id: chatroom.message._id,
                user: {
                  _id: chatroom.message.user._id as string,
                  avatar: chatroom.message.user.avatar,
                  name: chatroom.message.user.name,
                },
                text: chatroom.message.text,
                createdAt: new Date(chatroom.message.createdAt),
                image: chatroom.message.image || "",
                video: chatroom.message.video || "",
                audio: chatroom.message.audio || "",
              }
              return {
                objectId: chatroom.objectId,
                createdAt: chatroom.createdAt,
                radio_id: chatroom.radio_id,
                message: message,
              }
            })
          } else {
            console.log("No Chatrooms found")
          }
        }
        self.state = "done"
        console.log("Done Loading Chatrooms")
      } catch (error) {
        console.log("Failed to load ChatRooms:", error)
        self.state = "error"
      }
    }),
    reload() {
      self.chatrooms.clear()
      this.load()
    },
    addtoStore(message) {
      self.chatrooms.unshift(message)
    },
    afterCreate() {
      this.load()
    },
    getChatRoom(id) {
      return self.chatrooms.filter((chatroom) => chatroom.radio_id === id)
    },
    getChatRoomMessages: flow(function* getChatRoomMessages(radioId) {
      return self.chatrooms
        .filter((chatroom) => chatroom.radio_id.objectId === radioId)
        .map((entry) => {
          return {
            ...entry.message,
          }
        })
    }),
    sendMessage: flow(function* sendMessage(radioId, userId, messages) {
      const parseApi = new ParseApi()
      yield parseApi.sendChatRoomMessage(radioId, userId, Object.assign({}, messages))
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ChatRoomStoreType = Instance<typeof ChatRoomStoreModel>
export interface ChatRoomStore extends ChatRoomStoreType {}
type ChatRoomStoreSnapshotType = SnapshotOut<typeof ChatRoomStoreModel>
export interface ChatRoomStoreSnapshot extends ChatRoomStoreSnapshotType {}
