/* eslint-disable react-native/no-inline-styles */
import React, { useState } from "react"
import {
  StyleSheet,
  View,
  ViewStyle,
  Animated,
  ImageBackground,
  TextStyle,
  Text,
  Platform,
  Image,
} from "react-native"
import { observer } from "mobx-react-lite"
import { DEVICE_HEIGTH, DEVICE_WIDTH, WINDOW_WIDTH } from "../../utils/device"
import { palette } from "../../theme/palette"
import Svg, { Path } from "react-native-svg"
import { Header } from "../header/header"
import { spacing, typography } from "../../theme"
import usePlatform from "../../hooks/usePlatform"

const HEADER: ViewStyle = {
  paddingLeft: 60,
  padding: spacing[4],
  width: DEVICE_WIDTH,
  borderBottomLeftRadius: 100,
  // marginLeft: Platform.OS === "web" ? -1 : 0,
}

const CATEGORY_CONTAINER: ViewStyle = {
  alignItems: "flex-end",
  justifyContent: "center",
  marginTop: -spacing[2],
}

const CATEGORY: TextStyle = {
  color: palette.white,
  fontSize: typography.size[4],
  textAlign: "right",
  paddingRight: spacing[2],
  justifyContent: "center",
  fontVariant: ["small-caps"],
}

const CONTAINER: ViewStyle = {
  flex: 2,
  // justifyContent: "center",
  // alignItems: "stretch",
  width: "100%",
  marginTop: -2,
}

const SWOOSH: ViewStyle = {
  zIndex: 1000,
  marginTop: -79,
  marginLeft: Platform.OS === "web" ? -1 : -2,
  alignSelf: "flex-start",
}

export interface AwesomeContainerProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  imageSource: string
  children?
  header
  headerBackgroundColor?
  category?: string
  goback: any
  dark?: boolean
}

/**
 * Describe your component here
 */
export const AwesomeContainer = observer(function AwesomeContainer(props: AwesomeContainerProps) {
  const { style, dark, imageSource, header, headerBackgroundColor, category, goback } = props
  const { isBig, isWeb, headerImageWidth, headerImageHeight } = usePlatform()

  const styles = StyleSheet.create({
    container: {
      alignContent: "center",
      alignItems: "center",
      backgroundColor: palette.white,
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    topImage: {
      height: isWeb ? headerImageHeight : DEVICE_WIDTH / 1.765,
      // resizeMode: "cover",
      width: isWeb ? "100%" : DEVICE_WIDTH,
    },
  })

  return (
    <>
      <View style={[styles.container, style]}>
        {!isWeb ? (
          <ImageBackground
            source={{ uri: imageSource }}
            style={[styles.topImage, { justifyContent: "flex-start" }]}
            resizeMode="cover"
          >
            <Header
              leftIcon={{ name: "arrow-left", color: dark ? palette.alphablack : palette.white }}
              onLeftPress={goback}
            />
          </ImageBackground>
        ) : (
          <Image source={{ uri: imageSource }} style={styles.topImage} />
        )}
        <Svg height="80" width="80" style={SWOOSH}>
          <Path
            fill={headerBackgroundColor}
            x="0"
            y="0"
            d="m 0,0 c 1,80 1,80 1,80 0,0 0,0 80,0 C 40,80 0,40 0,0 Z"
          />
        </Svg>
        <View style={CONTAINER}>
          <View
            style={[HEADER, { flex: 1, width: "100%", backgroundColor: headerBackgroundColor }]}
          >
            <View>{header}</View>
            {category && (
              <View style={CATEGORY_CONTAINER}>
                <Text style={CATEGORY}>{category}</Text>
              </View>
            )}
          </View>
          {props.children}
        </View>
      </View>
      <View style={{ height: 200 }} />
    </>
  )
})
