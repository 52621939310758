import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { LinkModel } from "../link/link"
import { PodcastDataModel } from "../podcast-data/podcast-data"
import { ProgramModel } from "../program/program"

/**
 * Model description here for TypeScript hints.
 */
export const ProgramDetailModel = types.model("ProgramDetail").props({
  objectId: types.identifier,
  sort: types.number,
  info: types.string,
  day: types.string,
  time: types.string,
  title: types.string,
  links: types.optional(types.array(LinkModel), []),
  program_id: types.reference(ProgramModel),
  hasPodcasts: types.optional(types.boolean, false),
  cover: types.maybe(types.string),
  podcastData: types.maybe(PodcastDataModel),
  podIntroText: types.maybe(types.string),
  podIntroWeb: types.maybe(types.string),
  podIntroPic: types.maybe(types.string),
  podIntroMail: types.maybe(types.string),
  podIntroName: types.maybe(types.string),
  isPublic: types.optional(types.boolean, true),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ProgramDetailType = Instance<typeof ProgramDetailModel>
export interface ProgramDetail extends ProgramDetailType {}
type ProgramDetailSnapshotType = SnapshotOut<typeof ProgramDetailModel>
export interface ProgramDetailSnapshot extends ProgramDetailSnapshotType {}
