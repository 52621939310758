import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { PodcastLibraryScreen } from "../screens"
import { PodcastCategoryScreen } from "../screens/podcast-category/podcast-category-screen"

const Stack = createStackNavigator()

export function PodcastNav() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="PodcastLibrary"
    >
      <Stack.Screen
        options={{ headerShown: false }}
        name="PodcastLibrary"
        component={PodcastLibraryScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="PodcastCategory"
        component={PodcastCategoryScreen}
      />
    </Stack.Navigator>
  )
}
