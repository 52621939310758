import React, { useEffect, useState } from "react"
import { View, TextStyle, ViewStyle, Linking, ActivityIndicator } from "react-native"
import InputScrollView from "react-native-input-scroll-view"
import { Switch, TextInput } from "react-native-paper"
import { translate } from "../../../i18n"
import { spacing, typography } from "../../../theme"
import { palette } from "../../../theme/palette"
import { FLEX01, FLEX09, MB, ROW } from "../../../utils/style"
import { Button } from "../../button/button"
import { CollapsedError } from "../../collapsed-error/CollapsedError"
import { FormPageNavigator } from "../../form-page-navigator/form-page-navigator"
import { FormPageProps } from "../../form-page/form-page"
import { Text } from "../../text/text"
import { RegisterFormValues } from "../register-form"

const TEXT: TextStyle = {
  fontSize: typography.size[3],
  textAlign: "left",
  marginBottom: spacing[5],
}

const BUTTON_TEXT: TextStyle = {
  color: palette.darkpurple,
  fontSize: typography.size[2],
}

const TEXT_LEFT: TextStyle = {
  fontSize: typography.size[3],
  textAlign: "left",
  flex: 1,
  marginTop: spacing[3],
}

const LINK: TextStyle = {
  fontSize: typography.size[3],
  textDecorationLine: "underline",
}
const SWITCH: ViewStyle = {
  margin: spacing[4],
}

export const RegPage3: React.FC<FormPageProps<RegisterFormValues>> = ({
  values,
  touched,
  errors,
  submitForm,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
}) => {
  const [valid, setValid] = useState(false)

  const linkToPrivacy = () => {
    Linking.openURL("https://universalapp.ch/datenschutz")
  }

  const linkToTerms = () => {
    Linking.openURL("https://universalapp.ch/nutzungsbedingungen")
  }

  useEffect(() => {
    if (errors.email || errors.password || errors.passwordCheck || errors.agb) {
      setValid(false)
    } else {
      if (values.email && values.password && values.passwordCheck && values.agb) {
        setValid(true)
      } else {
        setValid(false)
      }
    }
  }, [values, errors])

  return (
    <>
      <View style={FLEX09}>
        <InputScrollView>
          <TextInput
            placeholder={translate("auth.email")}
            value={values.email}
            keyboardType="email-address"
            onChangeText={handleChange("email")}
            returnKeyType="done"
            autoCapitalize="none"
            onBlur={handleBlur("email")}
            style={MB}
            error={touched.email && errors.email}
          />
          <CollapsedError error={touched.email && errors.email} />
          <TextInput
            placeholder={translate("auth.password")}
            value={values.password}
            onChangeText={handleChange("password")}
            onBlur={handleBlur("password")}
            returnKeyType="done"
            autoCapitalize="none"
            secureTextEntry={true}
            style={MB}
            error={touched.password && errors.password}
          />
          <CollapsedError error={touched.password && errors.password} />
          <TextInput
            placeholder={translate("auth.passwordcheck")}
            value={values.passwordCheck}
            onChangeText={handleChange("passwordCheck")}
            onBlur={handleBlur("passwordCheck")}
            returnKeyType="done"
            autoCapitalize="none"
            secureTextEntry={true}
            style={MB}
            error={touched.passwordCheck && errors.passwordCheck}
          />
          <CollapsedError error={touched.passwordCheck && errors.passwordCheck} />
          <View style={[ROW, MB]}>
            <Switch
              trackColor={{ false: palette.lightGrey, true: palette.orange }}
              thumbColor={palette.white}
              value={values.agb}
              onValueChange={(value) => setFieldValue("agb", value)}
              style={SWITCH}
              color={palette.lighterGrey}
            />
            <Text style={TEXT_LEFT}>
              {translate("register.consent.predata")}
              <Text onPress={linkToPrivacy} style={LINK} tx="sub.disclaimer.privacy" />
              {translate("register.consent.and")}
              <Text onPress={linkToTerms} style={LINK} tx="sub.disclaimer.terms" />
            </Text>
          </View>
        </InputScrollView>
      </View>
      <View style={FLEX01}>
        {isSubmitting ? (
          <ActivityIndicator animating color={palette.purple} />
        ) : (
          <FormPageNavigator
            submitForm={submitForm}
            actionTx="register.action.create"
            disabled={!valid || isSubmitting}
          />
        )}
      </View>
    </>
  )
}
