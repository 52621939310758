import React, { useReducer } from "react"
import { observer } from "mobx-react-lite"
import { Image, ViewStyle, View } from "react-native"
import { Button, Container, Header, Screen, Text, TextField } from "../../components"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color, spacing } from "../../theme"
import { LinearGradient } from "expo-linear-gradient"
import { useStores } from "../../models"
import { useNavigation } from "@react-navigation/native"
import { RegisterForm } from "../../components/register-form/register-form"
import { FLEX1 } from "../../utils/style"
import Bugsnag from "@bugsnag/react-native"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import usePlatform from "../../hooks/usePlatform"

const ROOT: ViewStyle = {
  flex: 1,
}

const GRADIENT: ViewStyle = {
  flex: 1,
}

export const RegisterScreen = observer(function RegisterScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Regsitser")

  // Pull in one of our MST stores
  const rootStore = useStores()
  const { isWeb } = usePlatform()
  // Pull in navigation via hook
  const nav = useNavigation()

  if (!rootStore) return null

  return (
    <Screen style={ROOT} preset="fixed">
      <Container menuShown={false}>
        {!isWeb && <UniversalHeader colorful nav={nav} canGoBack rightIcon={null} />}
        <View style={[{ padding: spacing[3] }, FLEX1]}>
          <RegisterForm />
        </View>
      </Container>
    </Screen>
  )
})
