import React, { useState } from "react"
import { TextStyle, ViewStyle, FlatList, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing, typography } from "../../theme"
import { Text } from "../"
import { palette } from "../../theme/palette"
import { useStores } from "../../models"
import { useLinkTo } from "@react-navigation/native"
import { Feather } from "@expo/vector-icons"
import { translate } from "../../i18n"
import { MyModal } from "../my-modal/my-modal"
import { View } from "@dripsy/core"
import usePlatform from "../../hooks/usePlatform"

const BLOCK: ViewStyle = {
  width: "50%",
  paddingHorizontal: spacing[3],
  paddingVertical: spacing[4],
  justifyContent: "center",
  alignItems: "center",
}

const BLOCK_NAME: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 16,
  color: palette.lightGrey,
  paddingTop: spacing[2],
}

const SIDE_BUTTONS: ViewStyle = {
  marginVertical: spacing[2],
  paddingVertical: spacing[4],
  borderTopLeftRadius: 30,
  borderBottomLeftRadius: 30,
  backgroundColor: palette.darkpurple,
}

const TEXT_LENGTH = 75
const TEXT_HEIGHT = 60
const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2

const SIDE_BUTTON_TEXT: TextStyle = {
  transform: [{ rotate: "270deg" }, { translateX: -OFFSET + 3 }, { translateY: OFFSET - 9 }],
  width: TEXT_LENGTH,
  height: TEXT_HEIGHT,
  color: "white",
}

export interface ProgramModalProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
}

const Item = ({ item, onPress }) => (
  <TouchableOpacity onPress={onPress} style={BLOCK}>
    <Feather name={item.icon} size={24} color={palette.orange} />
    <Text style={BLOCK_NAME}>{item.name}</Text>
  </TouchableOpacity>
)

/**
 * Describe your component here
 */
export const ProgramModal = observer(function ProgramModal(props: ProgramModalProps) {
  const { style } = props
  const [visible, setVisible] = useState(false)

  const { programStore, radioStore } = useStores()
  const { isWeb } = usePlatform()
  const linkTo = useLinkTo()

  const data = programStore.getPrograms(radioStore.CurrentRadioStationId)
  if (radioStore.currentRadioStation.hasPodcasts) {
    data.push({
      name: translate("podcast.podcasts"),
      objectId: "podcasts",
      icon: "mic",
    })
  }
  if (radioStore.currentRadioStation.about) {
    data.push({
      name: translate("aboutus"),
      objectId: "about",
      icon: "heart",
    })
  }
  if (programStore.getProgramOverview(radioStore.CurrentRadioStationId).length > 0) {
    data.unshift({
      name: translate("radio.programoverview"),
      objectId: "overview",
      icon: "sun",
    })
  }

  const gotoProgram = (id) => {
    hideModal()
    if (id === "about") {
      // nav.navigate("RadioStationAbout")
      linkTo("/radio/about")
    } else if (id === "podcasts") {
      // nav.navigate("Podcasts")
      linkTo("/radio/media")
    } else if (id === "overview") {
      // nav.navigate("Overview")
      linkTo("/radio/program/overview")
    } else {
      programStore.setCurrentProgram(id)
      // nav.navigate("Program")
      linkTo("/radio/program")
    }
  }

  const showModal = () => {
    setVisible(true)
  }

  const hideModal = () => {
    setVisible(false)
  }

  const renderItem = ({ item }) => {
    return <Item item={item} onPress={() => gotoProgram(item.objectId)} />
  }

  return (
    <View style={style}>
      <MyModal visible={visible} dismiss={hideModal} dismissable>
        <View sx={{ flexDirection: "row", flexWrap: "wrap", minWidth: isWeb && 300 }}>
          <FlatList
            keyExtractor={(item) => item.objectId}
            data={data.slice()}
            renderItem={renderItem}
            numColumns={2}
          />
        </View>
      </MyModal>
      <TouchableOpacity style={SIDE_BUTTONS} onPress={showModal}>
        <View style={{ width: TEXT_HEIGHT, height: TEXT_LENGTH }}>
          <Text style={SIDE_BUTTON_TEXT} tx={"radio.program"} />
        </View>
      </TouchableOpacity>
    </View>
  )
})
