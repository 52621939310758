/* eslint-disable generator-star-spacing */
import Bugsnag from "@bugsnag/react-native"
import { Instance, SnapshotOut, types, flow, applySnapshot } from "mobx-state-tree"
import { GetGeneralResult } from "../../services/api"
import { ParseApi } from "../../services/api/parse-api"
import { withEnvironment } from "../extensions/with-environment"
import { withRootStore } from "../extensions/with-root-store"
import { User, UserModel } from "../user/user"

/**
 * Model description here for TypeScript hints.
 */
export const UserStoreModel = types
  .model("UserStore")
  .props({
    users: types.optional(types.array(UserModel), []),
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    currentUser: types.maybe(types.reference(UserModel)),
    me: types.maybe(types.reference(UserModel)),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    addUser(userData) {
      self.state = "pending"
      // only do this if we are not already in store
      if (!self.users.includes(userData)) {
        self.users.push(userData)
      }
      self.state = "done"
    },
    getUserById(id) {
      return self.users.find((user) => user.objectId === id)
    },
    loadUser: flow(function* loadUser(user: User) {
      applySnapshot(self.users, [user])
      __DEV__ && console.log("loading user ", user.objectId)
      yield new Promise((resolve) => {
        setTimeout(resolve, 200)
      })
    }),
    afterCreate() {
      // make sure users are unique
      self.users = self.users.filter((v, i, a) => a.indexOf(v) === i) as any
      self.state = "done"
    },
    setMe: flow(function* setMe() {
      console.log("setting me in userstore")
      const user = self.users.find((u) => u.objectId === self.rootStore.me) || null
      if (!user) {
        /*
          ugly solution to get users on the fly
        */
        const parseApi = new ParseApi()
        const userResult: GetGeneralResult = yield parseApi.getSomeOfClass("_User", {
          objectId: self.rootStore.me,
        })

        if (userResult.kind === "ok") {
          const first = userResult.data.results[0]
          self.users.push({
            name: first.firstname,
            lastName: first.lastname,
            objectId: first.objectId,
            bio: first.bio || "",
            avatar: first.avatar || undefined,
            home: { city: first.city, country: first.country, region: first.region || "" },
            mxId: first.mxId || "",
          })
          Bugsnag.setUser(first.objectId, first.username, first.firstname + first.lastname)
          console.log("done setting me in userstore")
        }
      }
      self.me = self.rootStore.me
    }),
    setCurrentUser: flow(function* setCurrentUser(id: string) {
      if (self.state !== "pending") {
        self.state = "pending"
        let user = self.users.find((u) => u.objectId === id) || null
        if (user === null) {
          console.log("User ", id, " not found so we're fetching him/her")

          /*
          ugly solution to get users on the fly
        */
          const parseApi = new ParseApi()
          const userResult: GetGeneralResult = yield parseApi.getSomeOfClass("_User", {
            objectId: id,
          })

          if (userResult.kind === "ok") {
            const first = userResult.data.results[0]
            self.users.push({
              name: first.firstname,
              lastName: first.lastname,
              objectId: first.objectId,
              bio: first.bio || "",
              avatar: first.avatar || undefined,
              home: { city: first.city, country: first.country, region: first.region || "" },
              mxId: first.mxId || "",
            })

            // self.users.map(obj => first.objectId === obj.objectId ?  obj);

            yield new Promise((resolve) => {
              setTimeout(resolve, 200)
            })
            user = self.users.find((u) => u.objectId === id)
          }
        }
        self.currentUser = (user.objectId as any) || undefined
        console.log("current User is:", self.currentUser)
        self.state = "done"
      }
    }),
    updateUser: flow(function* updateUser(id: string) {
      // Update the data for an existing user that we already have in our store
      console.log("Updateing User ", id)

      const parseApi = new ParseApi()
      const userResult: GetGeneralResult = yield parseApi.getSomeOfClass("_User", {
        objectId: id,
      })

      if (userResult.kind === "ok") {
        const first = userResult.data.results[0]
        const arr = self.users
        const index = self.users.findIndex((user) => user.objectId === id)

        arr[index] = {
          name: first.firstname,
          lastName: first.lastname,
          objectId: first.objectId,
          bio: first.bio || "",
          avatar: first.avatar || undefined,
          home: { city: first.city, country: first.country, region: first.region || "" },
        } as User

        self.users = arr

        // self.users.map(obj => first.objectId === obj.objectId ?  obj);
      }
    }),
  }))

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
