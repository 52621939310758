import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { MyModal } from "../my-modal/my-modal"
import { useForm, Controller } from "react-hook-form"
import { Button, TextField } from ".."
import { View } from "@dripsy/core"
import { translate } from "../../i18n"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { CollapsedError } from "../collapsed-error/CollapsedError"
import { useToast } from "react-native-toast-notifications"

export interface ChangePasswordModalProps {
  /**
   * An optional style override useful for padding & margin.
   */
  visible: boolean
  dismiss: any
  action: any
}

/**
 * Describe your component here
 */
export const ChangePasswordModal = observer(function ChangePasswordModal(
  props: ChangePasswordModalProps,
) {
  const { visible, dismiss, action } = props
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(translate("register.required.password"))
      .min(8, translate("register.min.password")),
    passwordCheck: Yup.string().oneOf(
      [Yup.ref("password"), null],
      translate("register.invalid.match"),
    ),
  })
  const toast = useToast()

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      passwordCheck: "",
    },
  }
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions)

  const onDismiss = () => dismiss()
  const onReset = () => reset()
  const onCancel = () => {
    onReset()
    onDismiss()
  }

  const onSubmit = async (data) => {
    const result = await action(data.password)
    if (result !== "success") {
      toast.show(result, { type: "sucess" })
    } else {
      toast.show(result, { type: "error" })
      onCancel()
    }
  }

  return (
    <MyModal visible={visible} dismiss={onDismiss} dismissable>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            secureTextEntry
            autoCapitalize={"none"}
            labelTx="auth.password"
          />
        )}
        name="password"
      />
      <CollapsedError error={errors.password?.message} />

      <Controller
        control={control}
        rules={{
          required: true,
          // validate: v => v === password
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            secureTextEntry
            autoCapitalize={"none"}
            labelTx="auth.passwordcheck"
          />
        )}
        name="passwordCheck"
      />
      <CollapsedError error={errors.passwordCheck?.message} />
      <View sx={{ flexDirection: "row" }}>
        <Button text="Cancel" onPress={onCancel} preset="pale" />
        <Button
          text="Submit"
          onPress={handleSubmit(onSubmit)}
          disabled={errors.password || errors.passwordCheck}
        />
      </View>
    </MyModal>
  )
})
