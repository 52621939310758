import * as React from "react"
import { TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, typography } from "../../theme"
import { Text } from "../"
import { Button } from "../button/button"
import * as Linking from "expo-linking"
import { Feather } from "@expo/vector-icons"

const BUTTON: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 100,
}

export interface LinkButtonProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  link: { type: string; value: string; name?: string }
  showIcon?: boolean
  showName?: boolean
}

/**
 * Describe your component here
 */
export const LinkButton = observer(function LinkButton(props: LinkButtonProps) {
  const { style, link, showIcon = true, showName = true } = props

  console.log(props)

  let linkUrl
  let iconName
  if (link.type === "email") {
    linkUrl = "mailto://".concat(link.value)
    iconName = "mail"
  }
  if (link.type === "web") {
    if (link.value.includes("http://")) {
      linkUrl = link.value.replace("http://", "https://")
    } else if (link.value.includes("https://")) {
      linkUrl = link.value
    } else {
      linkUrl = "https://".concat(link.value)
    }
    iconName = "globe"
  }
  if (link.type === "tel") {
    linkUrl = "tel://".concat(link.value)
    iconName = "phone"
  }

  const follow = (linkUrl) => {
    Linking.openURL(linkUrl)
  }

  return (
    <Button style={[BUTTON, style]} preset="primary" onPress={() => follow(linkUrl)}>
      {showIcon && <Feather name={iconName} size={24} color={"white"} />}
      {showName && <Text>{link.name}</Text>}
    </Button>
  )
})
