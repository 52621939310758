/* eslint-disable react-native/no-inline-styles */
import React from "react"
import { observer } from "mobx-react-lite"
import { Platform, Pressable, TextStyle, View, ViewStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { spacing, typography } from "../../theme"
import { useStores } from "../../models"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { palette } from "../../theme/palette"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import { HumanBubble } from "../../components/human-bubble/human-bubble"
import { AwesomeContainer } from "../../components/awesome-container/awesome-container"
import { TouchableOpacity } from "react-native-gesture-handler"
import Autolink from "react-native-autolink"
import { PodcastBubble } from "../../components/podcast-bubble/podcast-bubble"
import Bugsnag from "@bugsnag/react-native"

const ROOT: ViewStyle = {
  // flex: 1,
}

const PROGRAM_TITLE: TextStyle = {
  color: palette.white,
  fontWeight: "bold",
  fontSize: typography.size[5],
  fontFamily: typography.secondary,
}

const PROGRAM_INFO: ViewStyle = {
  paddingLeft: 40,
  padding: spacing[3],
}

const DETAIL: ViewStyle = {
  marginVertical: spacing[2],
}

const DETAIL_TITLE: TextStyle = {
  fontSize: typography.size[4],
  fontWeight: "bold",
  fontFamily: typography.primary,
}

const DETAIL_DAY: TextStyle = {
  fontSize: typography.size[3],
  fontFamily: typography.primary,
}

const DETAIL_INFO: TextStyle = {
  fontSize: typography.size[2],
  fontFamily: typography.secondary,
}

const CONTENT: ViewStyle = {
  paddingLeft: 40,
  padding: spacing[4],
  width: "100%",
}

const CONTENT_CONTAINER: ViewStyle = {
  paddingBottom: spacing[5],
  // overflow: "hidden",
  borderBottomLeftRadius: 70,
}

export const ProgramScreen = observer(function ProgramScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Program")

  // Pull in one of our MST stores
  const { programStore } = useStores()

  // Pull in navigation via hook
  const nav = useNavigation()

  // uselink for naviagation on web
  const linkTo = useLinkTo()

  const gotoPodcasts = (id) => {
    programStore.setCurrentProgramDetail(id)
    linkTo("/radio/media/category")
  }

  if (!programStore.currentProgram) return <LoadingScreen />

  const details = (
    <>
      {programStore.getCurrentProgramDetails().map((program) => {
        return (
          <View style={DETAIL} key={program.objectId}>
            <Text style={DETAIL_TITLE}>{program.title}</Text>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View>
                <Text style={DETAIL_DAY}>{program.day}</Text>
                <Text style={DETAIL_DAY}>{program.time}</Text>
              </View>
            </View>
            <Text style={DETAIL_INFO}>{program.info}</Text>
            {program.links.map((human, index) => (
              <HumanBubble key={index} human={human} small />
            ))}
            {program.hasPodcasts && (
              <Pressable onPress={() => gotoPodcasts(program.objectId)}>
                <PodcastBubble />
              </Pressable>
            )}
          </View>
        )
      })}
    </>
  )

  return (
    <Screen style={ROOT} preset="scroll">
      <Container gradient>
        <AwesomeContainer
          goback={nav.goBack}
          imageSource={programStore.currentProgram.cover}
          headerBackgroundColor={palette.paleorange}
          header={
            <>
              <Text style={PROGRAM_TITLE}>{programStore.currentProgram.name}</Text>
            </>
          }
        >
          <View style={PROGRAM_INFO}>
            <Autolink
              text={programStore.currentProgram.info}
              renderText={(text) => <Text style={DETAIL_INFO}>{text}</Text>}
            />
          </View>
          <View style={CONTENT_CONTAINER}>
            <View style={CONTENT}>{details}</View>
          </View>
        </AwesomeContainer>
      </Container>
    </Screen>
  )
})
