import React, { useState } from "react"
import {
  View,
  TouchableOpacity,
  Image,
  TextStyle,
  ViewStyle,
  ActivityIndicator,
} from "react-native"
import { DEVICE_WIDTH } from "../../../utils/device"
import { translate } from "../../../i18n"
import { EventFormValues, PageProps } from "../event-form"
import Svg, { Path } from "react-native-svg"
import { palette } from "../../../theme/palette"
import { spacing, typography } from "../../../theme"
import { Text } from "../../text/text"
import { Icon } from "../.."
import { useStores } from "../../../models"
import { Chip } from "react-native-paper"
import { MyLanguage } from "../../../i18n/i18n"
import Autolink from "react-native-autolink"
// import * as ImagePicker from "expo-image-picker"
import { EVENT_ASPECT_RATIO, FLEX01, FLEX09, FLEX1 } from "../../../utils/style"
import { EventFormBottomButtons } from "../../event-form-bottom-buttons/event-form-bottom-buttons"
import { ScrollView } from "react-native-gesture-handler"
import useImagePicker from "../../../hooks/useImagePicker"
import { FormPageProps } from "../../form-page/form-page"
import { useToast } from "react-native-toast-notifications"
import usePlatform from "../../../hooks/usePlatform"

const HEADER: ViewStyle = {
  paddingLeft: 70,
  padding: spacing[2],
  width: DEVICE_WIDTH,
  borderBottomLeftRadius: 80,
}

const CATEGORY_CONTAINER: ViewStyle = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
}

const BUTTON_GROUP: ViewStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: spacing[2],
  justifyContent: "center",
  width: "100%",
}

const CATEGORY: TextStyle = {
  color: palette.purple,
  fontSize: typography.size[2],
  textAlign: "right",
  paddingRight: spacing[2],
  justifyContent: "center",
  fontVariant: ["small-caps"],
}

const SWOOSH: ViewStyle = {
  zIndex: 1000,
  marginTop: -80,
  alignSelf: "flex-start",
  marginLeft: -1,
}

const TITLE: TextStyle = {
  color: palette.white,
  fontSize: 30,
  fontWeight: "bold",
}

const DATE: TextStyle = {
  color: palette.white,
  fontSize: 15,
}

const CONTENT: ViewStyle = {
  paddingLeft: 40,
  padding: spacing[4],
}

const CONTENT_BLOCK: ViewStyle = {
  flexDirection: "row",
  justifyContent: "flex-start",
  marginVertical: spacing[2],
}

const CONTENT_ICON: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  paddingHorizontal: spacing[5],
}

const CONTENT_INFO: ViewStyle = {
  justifyContent: "flex-start",
}

const TEXT_BOLD: TextStyle = {
  fontWeight: "bold",
  fontFamily: typography.secondary,
  fontSize: typography.size[2],
}
const TEXT_LIGHT: TextStyle = {
  fontWeight: "normal",
  fontFamily: typography.secondary,
  fontSize: typography.size[2],
}

const TEXT_DESC: TextStyle = {
  color: palette.lightGrey,
  fontWeight: "normal",
  fontFamily: typography.secondary,
  fontSize: typography.size[2],
}

const PLACEHOLDER: ViewStyle = {
  width: DEVICE_WIDTH,
  height: DEVICE_WIDTH * 0.43,
  justifyContent: "center",
  alignItems: "center",
}

export const Page4: React.FC<FormPageProps<EventFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  previousPage,
  submitForm,
  action,
  isSubmitting,
  isValid,
}) => {
  const { eventStore, userStore } = useStores()
  const [place] = useState(eventStore.getPlace(values.placeId))
  const { loading, pick } = useImagePicker()
  const [valid, setValid] = React.useState(false)
  const toast = useToast()
  const { isWeb, headerImageWidth, headerImageHeight } = usePlatform()

  const pickImage = async (handlechange) => {
    const image = await pick(500 * EVENT_ASPECT_RATIO, 500)
    console.log(image)
    if (image) {
      try {
        const url = new URL(image)
        handlechange(image)
      } catch (_) {
        toast.show(image)
      }
    }
  }

  let dateString
  let timeString
  let endString

  if (values.hasEndDate) {
    if (values.startUTC.toDateString() !== values.endUTC.toDateString()) {
      dateString = `${values.startUTC
        .toLocaleDateString(MyLanguage)
        .toUpperCase()} - ${values.endUTC.toLocaleDateString(MyLanguage).toUpperCase()}`

      timeString = values.startUTC.toLocaleString(MyLanguage, {
        dateStyle: "short",
        timeStyle: "short",
      })

      endString = values.endUTC.toLocaleString(MyLanguage, {
        dateStyle: "short",
        timeStyle: "short",
      })
    } else {
      dateString = values.startUTC
        .toLocaleDateString(MyLanguage, {
          weekday: "short",
          month: "short",
          day: "numeric",
        })
        .toUpperCase()
      timeString = values.startUTC.toLocaleTimeString(MyLanguage, {
        hour: "2-digit",
        minute: "2-digit",
      })

      endString = values.endUTC.toLocaleTimeString(MyLanguage, {
        hour: "2-digit",
        minute: "2-digit",
      })
    }
  } else {
    dateString = values.startUTC
      .toLocaleDateString(MyLanguage, {
        weekday: "short",
        month: "short",
        day: "numeric",
      })
      .toUpperCase()

    timeString = values.startUTC.toLocaleTimeString(MyLanguage, {
      hour: "2-digit",
      minute: "2-digit",
    })
  }

  React.useEffect(() => {
    if (errors.image) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [values, errors])

  const check = () => {
    console.log(values, errors)
    Object.keys(errors).forEach((key) => {
      // Toast.showWithGravity(errors[key], Toast.SHORT, Toast.CENTER, ["UIAlertController"])
      toast.show(errors[key])
    })
    if (isValid) {
      submitForm()
    }
  }

  return (
    <View>
      <View>
        <ScrollView showsVerticalScrollIndicator={false}>
          <TouchableOpacity onPress={() => pickImage(handleChange("image"))}>
            {loading ? (
              <View
                style={{
                  width: headerImageWidth,
                  height: headerImageHeight,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ActivityIndicator animating={true} color={palette.purple} />
              </View>
            ) : (
              <>
                {values.image ? (
                  <Image
                    source={{ uri: values.image }}
                    style={{
                      width: headerImageWidth,
                      height: headerImageHeight,
                    }}
                  />
                ) : (
                  <>
                    <View
                      style={{
                        width: headerImageWidth,
                        height: headerImageHeight,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon name="camera" color={palette.purple} />
                      <Text tx="event.pick.image" />
                    </View>
                  </>
                )}
              </>
            )}
          </TouchableOpacity>
          <Svg height="80" width="80" style={SWOOSH}>
            <Path
              fill={palette.paleorange}
              x="0"
              y="0"
              d="m 0,0 c 1,80 1,80 1,80 0,0 0,0 80,0 C 40,80 0,40 0,0 Z"
            />
          </Svg>
          <View style={[HEADER, { backgroundColor: palette.paleorange }]}>
            <View>
              <Text style={DATE}>{dateString}</Text>
              <Text style={TITLE}>{values.name}</Text>
            </View>
          </View>
          <View style={CONTENT}>
            {/* Time */}
            <View style={CONTENT_BLOCK}>
              <View style={CONTENT_ICON}>
                <Icon name="sunrise" size={24} color={palette.lightGrey} />
              </View>
              <View style={CONTENT_INFO}>
                <Text style={TEXT_BOLD}>{timeString}</Text>
              </View>
            </View>
            {values.hasEndDate && (
              <View style={CONTENT_BLOCK}>
                <View style={CONTENT_ICON}>
                  <Icon name="sunset" size={24} color={palette.lightGrey} />
                </View>
                <View style={CONTENT_INFO}>
                  <Text style={TEXT_BOLD}>{endString}</Text>
                </View>
              </View>
            )}

            {/* Place */}
            {place && (
              <View style={CONTENT_BLOCK}>
                <View style={CONTENT_ICON}>
                  <Icon name="map-pin" size={24} color={palette.lightGrey} />
                </View>
                <View style={CONTENT_INFO}>
                  <Text style={TEXT_BOLD}>{place.name}</Text>
                  <Text style={TEXT_LIGHT}>
                    {place.zipcode} {place.city}
                  </Text>
                </View>
              </View>
            )}

            {/* User */}
            {userStore.me && (
              <View style={CONTENT_BLOCK}>
                <View style={CONTENT_ICON}>
                  <Icon name="user" size={24} color={palette.lightGrey} />
                </View>
                <View style={CONTENT_INFO}>
                  <Text style={TEXT_BOLD}>
                    {translate("event.hostedBy")} {userStore.me.name} {userStore.me.lastName}
                  </Text>
                </View>
              </View>
            )}

            {/* Description */}
            <Autolink
              text={values.desc}
              style={TEXT_DESC}
              linkStyle={{ color: palette.orange, fontWeight: "600" }}
            />

            {/* Tags */}
            {values.tags && (
              <View style={CONTENT_BLOCK}>
                <View style={BUTTON_GROUP}>
                  {values.tags.map((tag) => {
                    return (
                      <Chip
                        style={{ marginRight: spacing[1], marginBottom: spacing[1] }}
                        key={tag.id}
                      >
                        <Text tx={"tags." + tag.item} />
                      </Chip>
                    )
                  })}
                </View>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
      <View style={{ alignItems: "center" }}>
        {isSubmitting ? (
          <ActivityIndicator animating />
        ) : (
          <EventFormBottomButtons
            previousPage={previousPage}
            submitForm={check}
            action={action}
            disabled={false}
          />
        )}
      </View>
    </View>
  )
}
