import * as React from "react"
import { Text, TouchableOpacity, ViewStyle } from "react-native"
import { viewPresets, textPresets } from "./button.presets"
import { ButtonProps } from "./button.props"
import { mergeAll, flatten } from "ramda"
import { translate } from "../../i18n"
import { Sx, View } from "@dripsy/core"

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Button(props: ButtonProps) {
  // grab the props
  const {
    preset = "primary",
    tx,
    text,
    style: styleOverride,
    textStyle: textStyleOverride,
    children,
    disabled,
    sx,
    ...rest
  } = props

  const disabledView: ViewStyle = {
    opacity: 0.5,
  }
  let viewStyle
  if (disabled) {
    viewStyle = mergeAll(
      flatten([viewPresets[preset], disabledView || viewPresets.primary, styleOverride]),
    )
  } else {
    viewStyle = mergeAll(flatten([viewPresets[preset] || viewPresets.primary, styleOverride]))
  }

  const textStyle = mergeAll(
    flatten([textPresets[preset] || textPresets.primary, textStyleOverride]),
  )

  const content = children || <Text style={textStyle}>{tx ? translate(tx) : text}</Text>

  return (
    <View sx={sx}>
      <TouchableOpacity
        activeOpacity={0.7}
        style={viewStyle}
        disabled={disabled || false}
        {...rest}
      >
        {content}
      </TouchableOpacity>
    </View>
  )
}
