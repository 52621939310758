import * as React from "react"
import { ErrorMessage } from "../../error-message/error-message"
import { translate } from "../../../i18n"
import { TextInput } from "react-native-paper"
import { spacing } from "../../../theme"
import { EVENT_TAGS } from "../../../utils/tags"
import { TagButton } from "../../tag-button/tag-button"
import { Text } from "../../text/text"
import { FLEX01, FLEX09, FLEX1, TEXT_CENTER } from "../../../utils/style"
import { ScrollView, View, ViewStyle } from "react-native"
import InputScrollView from "react-native-input-scroll-view"
import { Button } from "../../button/button"
import { EventFormBottomButtons } from "../../event-form-bottom-buttons/event-form-bottom-buttons"
import { CollapsedError } from "../../collapsed-error/CollapsedError"
import { FormPageProps } from "../../form-page/form-page"
import { EventFormValues } from "../event-form"
import usePlatform from "../../../hooks/usePlatform"

export const Page3b: React.FC<FormPageProps<EventFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  previousPage,
  nextPage,
}) => {
  const [valid, setValid] = React.useState(false)
  const { isWeb } = usePlatform()
  React.useEffect(() => {
    if (errors.tags) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [values, errors])

  return (
    <View style={[{ margin: spacing[4] }, !isWeb && FLEX1]}>
      <View style={FLEX1}>
        <InputScrollView>
          <Text tx="event.pick.tags" style={TEXT_CENTER} />
          <TagButton
            tags={EVENT_TAGS}
            selected={values.tags}
            onChange={(selected) => setFieldValue("tags", selected)}
          />
          <CollapsedError error={errors.tags && touched.tags} />
        </InputScrollView>
      </View>
      <View style={!isWeb && FLEX01}>
        <EventFormBottomButtons nextPage={nextPage} previousPage={previousPage} disabled={!valid} />
      </View>
    </View>
  )
}
