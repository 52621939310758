import * as React from "react"
import { TextStyle, View, ViewStyle, Image, ImageStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { TextInput, HelperText, ActivityIndicator } from "react-native-paper"
import * as Yup from "yup"
import { Formik } from "formik"
import { useStores } from "../../models"
import { translate } from "../../i18n"
import { palette } from "../../theme/palette"
import { Button } from "../button/button"
import { MyView } from "../my-view/my-view"
import { Icon } from "../icon/icon"
import useImagePicker from "../../hooks/useImagePicker"
import { CollapsedError } from "../collapsed-error/CollapsedError"
import { FLEX1, MB, ROW } from "../../utils/style"

const CONTAINER: ViewStyle = {
  margin: spacing[3],
}

const SIDE_BY_SIDE: ViewStyle = {
  flexDirection: "row",
}

const EQUAL: ViewStyle = {
  width: "50%",
}

const SIDE_30: ViewStyle = {
  width: "30%",
}

const SIDE_70: ViewStyle = {
  width: "70%",
}

const IMG_CONTAINER: ViewStyle = {
  alignItems: "center",
  justifyContent: "center",
  padding: spacing[2],
}

const PLACE_IMG: ImageStyle = {
  height: 100,
  width: 100,
  borderRadius: 20,
}

const IMG_PLACEHOLDER: ViewStyle = {
  height: 100,
  width: 100,
  borderRadius: 20,
  backgroundColor: palette.lighterGrey,
  alignItems: "center",
  justifyContent: "center",
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(translate("place.required.name")),
  street: Yup.string().required(translate("place.required.street")),
  streetNr: Yup.number().required(translate("place.required.streetNr")),
  city: Yup.string().required(translate("place.required.city")),
  link: Yup.string(),
  zipcode: Yup.number().required(translate("place.required.zipcode")),
  image: Yup.string().url(translate("place.required.zipcode")),
})

export interface PlaceFormProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  onCreatePlace: any
  onCancel: any
}

export interface FormValues {
  name: string
  street: string
  streetNr: string
  city: string
  zipcode: string
  link: string
  image: string
}

/**
 * Describe your component here
 */
export const PlaceForm = observer(function PlaceForm(props: PlaceFormProps) {
  const { style, onCreatePlace, onCancel } = props
  const { eventStore } = useStores()
  const { loading, pick } = useImagePicker()

  const [showLink, setShowLink] = React.useState(false)

  const [] = React.useState(false)

  const addLink = () => {
    setShowLink(true)
  }

  const pickImage = async (handleChange) => {
    const image = await pick()
    console.log(image)
    if (image) {
      handleChange(image)
    }
  }

  return (
    <View style={[CONTAINER, style]}>
      <Formik
        initialValues={{
          name: "",
          street: "",
          streetNr: "",
          zipcode: "",
          city: "",
          link: "",
          image: "",
        }}
        onSubmit={async (values) => {
          console.log(values)
          const placeId = await eventStore.createPlace(values)
          if (placeId !== "error") {
            onCreatePlace(placeId)
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          isValid,
          isSubmitting,
          touched,
          handleBlur,
        }) => (
          <>
            <View style={IMG_CONTAINER}>
              {loading && (
                <View style={IMG_PLACEHOLDER}>
                  <ActivityIndicator animating={true} />
                </View>
              )}
              {!loading && !values.image ? (
                <View style={IMG_PLACEHOLDER}>
                  <Button
                    onPress={() => {
                      pickImage(handleChange("image"))
                    }}
                    preset="pale"
                  >
                    <Icon name="camera" color={palette.purple} />
                    <Text tx="place.addImage" style={{ color: palette.purple }} />
                  </Button>
                </View>
              ) : (
                <Image source={{ uri: values.image }} style={PLACE_IMG} />
              )}
            </View>
            <TextInput
              label={translate("place.name")}
              value={values.name}
              onChangeText={handleChange("name")}
              autoCapitalize="none"
              onBlur={handleBlur("name")}
              error={touched.name && errors.name}
              underlineColor={palette.purple}
              style={MB}
            />
            <CollapsedError error={touched.name && errors.name} />
            <View style={SIDE_BY_SIDE}>
              <View style={SIDE_70}>
                <TextInput
                  style={{ marginRight: spacing[2] }}
                  label={translate("place.street")}
                  value={values.street}
                  onChangeText={handleChange("street")}
                  autoCapitalize="none"
                  // onBlur={handleBlur("street")}
                  error={touched.street && errors.street}
                  underlineColor={palette.purple}
                />
                <HelperText type="error" visible={errors.street}>
                  {errors.street}
                </HelperText>
              </View>
              <View style={SIDE_30}>
                <TextInput
                  label={translate("place.streetNr")}
                  value={values.streetNr}
                  onChangeText={handleChange("streetNr")}
                  autoCapitalize="none"
                  onBlur={handleBlur("streetNr")}
                  error={touched.streetNr && errors.streetNr}
                  underlineColor={palette.purple}
                />
                <HelperText type="error" visible={errors.streetNr}>
                  {errors.streetNr}
                </HelperText>
              </View>
            </View>
            <View style={SIDE_BY_SIDE}>
              <View style={EQUAL}>
                <TextInput
                  style={{ marginRight: spacing[2] }}
                  label={translate("place.zipcode")}
                  value={values.zipcode}
                  onChangeText={handleChange("zipcode")}
                  autoCapitalize="none"
                  onBlur={handleBlur("zipcode")}
                  error={touched.zipcode && errors.zipcode}
                  underlineColor={palette.purple}
                />
                <HelperText type="error" visible={errors.zipcode}>
                  {errors.zipcode}
                </HelperText>
              </View>
              <View style={EQUAL}>
                <TextInput
                  label={translate("place.city")}
                  value={values.city}
                  onChangeText={handleChange("city")}
                  autoCapitalize="none"
                  onBlur={handleBlur("city")}
                  error={touched.city && errors.city}
                  underlineColor={palette.purple}
                />
                <HelperText type="error" visible={errors.city}>
                  {errors.city}
                </HelperText>
              </View>
            </View>
            {!showLink && (
              <Button
                onPress={addLink}
                preset="pale"
                style={{ flexDirection: "row", justifyContent: "flex-start" }}
              >
                <Icon name="plus" color={palette.purple} />
                <Text tx="place.addLink" style={{ color: palette.purple }} />
              </Button>
            )}
            <MyView hide={!showLink}>
              <TextInput
                label={translate("place.link")}
                value={values.link}
                onChangeText={handleChange("link")}
                autoCapitalize="none"
                onBlur={handleBlur("link")}
                error={touched.link && errors.link}
                underlineColor={palette.purple}
              />
            </MyView>

            <View style={{ marginTop: 20 }}>
              {isSubmitting ? (
                <ActivityIndicator animating />
              ) : (
                <View style={ROW}>
                  <Button style={FLEX1} preset="pale" onPress={onCancel} tx="common.cancel" />
                  <View style={FLEX1}>
                    <Button
                      onPress={handleSubmit}
                      disabled={!isValid || isSubmitting}
                      preset="secondary"
                      tx="place.create"
                    />
                  </View>
                </View>
              )}
            </View>
          </>
        )}
      </Formik>
    </View>
  )
})
