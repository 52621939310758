import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { PlaceModel } from "../place/place"
import regionData from "../../../node_modules/country-region-data/data"

/**
 * Model description here for TypeScript hints.
 */
export const UserModel = types
  .model("User")
  .props({
    objectId: types.identifier,
    name: types.string,
    lastName: types.string,
    bio: types.maybe(types.string),
    home: types.maybe(PlaceModel),
    avatar: types.optional(types.string, ""), // url to avatar img
    mxId: types.maybe(types.string),
  })
  .views((self) => ({
    get initials() {
      return self.name.charAt(0) + self.lastName.charAt(0)
    },
    get CountryString() {
      const country = regionData.find((country) => country.countryShortCode === self.home.country)
      return country.countryName
    },
    get RegionString() {
      const country = regionData.find((country) => country.countryShortCode === self.home.country)
      const region = country.regions?.find((region) => region.shortCode === self.home.region)
      if (!region) return null
      return region.name
    },
  }))
type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {})
