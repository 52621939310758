import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { GeoPointModel } from "../geo-point/geo-point"
import { LinkModel } from "../link/link"

/**
 * Model description here for TypeScript hints.
 */
export const EventVenueModel = types
  .model("EventVenue")
  .props({
    objectId: types.identifier,
    name: types.maybe(types.string),
    street: types.maybe(types.string),
    street_nr: types.maybe(types.string),
    city: types.maybe(types.string),
    zipcode: types.maybe(types.string),
    country: types.maybe(types.string),
    location: types.maybe(GeoPointModel),
    link: types.maybe(LinkModel),
    image: types.maybe(types.string),
  })
  .views((self) => ({
    get Query() {
      return `${self.name} ${self.street} ${self.street_nr} ${self.zipcode} ${self.city}`
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type EventVenueType = Instance<typeof EventVenueModel>
export interface EventVenue extends EventVenueType {}
type EventVenueSnapshotType = SnapshotOut<typeof EventVenueModel>
export interface EventVenueSnapshot extends EventVenueSnapshotType {}
