import * as React from "react"
import { Image, ImageStyle } from "react-native"

export interface LogoProps {
  /**
   * An optional style override useful for padding & margin.
   */
  rgb?: boolean
}
const style: ImageStyle = {
  height: 300,
  width: 300,
  aspectRatio: 1,
}

/**
 * Describe your component here
 */
export const Logo = function Logo(props: LogoProps) {
  const { rgb } = props

  const colorImage = (
    <Image
      style={style}
      source={{
        uri: require("../../../assets/logo/logo-rgb.png"),
      }}
    />
  )

  const whiteImage = (
    <Image
      style={style}
      source={{
        uri: require("../../../assets/logo/logo-white.png"),
      }}
    />
  )

  return (
    <>
      <>{rgb ? colorImage : whiteImage}</>
    </>
  )
}
