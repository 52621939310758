import * as Font from "expo-font"

export const initFonts = async () => {
  // Refer to ./assets/fonts/custom-fonts.md for instructions.
  // ...
  // Welcome back! Just uncomment this and replace/append with your font file names!
  // ⬇
  await Font.loadAsync({
    Montserrat: require("./Montserrat-Regular.ttf"),
    "Montserrat-Bold": require("./Montserrat-Bold.ttf"),
    "Montserrat-Medium": require("./Montserrat-Medium.ttf"),
    Minimo: require("./Minimo.otf"),
    "Minimo-Bold": require("./Minimo-Bold.otf"),
    "Minimo-Medium": require("./Minimo-Medium.otf"),
  })
}
