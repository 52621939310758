import * as React from "react"
import Svg, { LinearGradient, Stop, Circle, Path } from "react-native-svg"

function Nature(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={600}
      height={400}
      viewBox="0 0 800 600"
      {...props}
    >
      <LinearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={354.387}
        y1={103.333}
        x2={354.387}
        y2={424.566}
      >
        <Stop offset={0} stopColor="#ffdc2e" />
        <Stop offset={0.04} stopColor="#ffde39" />
        <Stop offset={0.302} stopColor="#ffea7f" />
        <Stop offset={0.539} stopColor="#fff3b6" />
        <Stop offset={0.741} stopColor="#fff9de" />
        <Stop offset={0.902} stopColor="#fffdf6" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <Circle fill="url(#prefix__a)" cx={354.387} cy={268} r={156} />
      <LinearGradient
        id="prefix__b"
        gradientUnits="userSpaceOnUse"
        x1={306}
        y1={373}
        x2={402}
        y2={373}
      >
        <Stop offset={0} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path
        fill="url(#prefix__b)"
        d="M402 320v120h-96V320h32.387c1.1 0 1.613-.9 1.613-2v-12h28v12c0 1.1 1.287 2 2.387 2H402z"
      />
      <LinearGradient
        id="prefix__c"
        gradientUnits="userSpaceOnUse"
        x1={166.145}
        y1={209}
        x2={182.193}
        y2={209}
      >
        <Stop offset={0} stopColor="#ddd" />
        <Stop offset={0.15} stopColor="#cfcfcf" />
        <Stop offset={0.439} stopColor="#bbb" />
        <Stop offset={0.724} stopColor="#aeaeae" />
        <Stop offset={1} stopColor="#aaa" />
      </LinearGradient>
      <Path
        fill="url(#prefix__c)"
        d="M182.193 206c0-4.411-3.492-8-7.903-8s-8.145 3.589-8.145 8c0 3.717 1.855 6.821 5.855 7.716V220h4v-6.284c4-.895 6.193-4 6.193-7.716zm-7.806 4c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"
      />
      <LinearGradient
        id="prefix__d"
        gradientUnits="userSpaceOnUse"
        x1={302.145}
        y1={246.333}
        x2={302.145}
        y2={273.298}
      >
        <Stop offset={0} stopColor="#f9cdaf" />
        <Stop offset={0.536} stopColor="#f8caab" />
        <Stop offset={1} stopColor="#f7c4a1" />
      </LinearGradient>
      <Path
        fill="url(#prefix__d)"
        d="M302.387 248H314v12c0 6.627-5.179 12-11.807 12s-11.903-5.372-11.903-12 5.469-12 12.097-12z"
      />
      <LinearGradient
        id="prefix__e"
        gradientUnits="userSpaceOnUse"
        x1={302.145}
        y1={253}
        x2={302.145}
        y2={266.35}
      >
        <Stop offset={0} stopColor="#efbc9a" />
        <Stop offset={0.715} stopColor="#f3c09e" />
        <Stop offset={1} stopColor="#f7c4a1" />
      </LinearGradient>
      <Path
        fill="url(#prefix__e)"
        d="M302.387 254H308v6c0 3.314-2.493 6-5.807 6s-5.903-2.686-5.903-6 2.783-6 6.097-6z"
      />
      <Path
        opacity={0.05}
        d="M296.387 260c0 .34.02.68.1 1 .46-2.84 2.92-5 5.9-5H306v-2h-3.613c-3.32 0-6 2.68-6 6z"
      />
      <LinearGradient
        id="prefix__f"
        gradientUnits="userSpaceOnUse"
        x1={406.145}
        y1={246.333}
        x2={406.145}
        y2={273.298}
      >
        <Stop offset={0} stopColor="#f9cdaf" />
        <Stop offset={0.536} stopColor="#f8caab" />
        <Stop offset={1} stopColor="#f7c4a1" />
      </LinearGradient>
      <Path
        fill="url(#prefix__f)"
        d="M406.387 248H394v12c0 6.627 5.566 12 12.193 12s12.097-5.372 12.097-12-5.276-12-11.903-12z"
      />
      <LinearGradient
        id="prefix__g"
        gradientUnits="userSpaceOnUse"
        x1={406.145}
        y1={253}
        x2={406.145}
        y2={266.35}
      >
        <Stop offset={0} stopColor="#efbc9a" />
        <Stop offset={0.715} stopColor="#f3c09e" />
        <Stop offset={1} stopColor="#f7c4a1" />
      </LinearGradient>
      <Path
        fill="url(#prefix__g)"
        d="M406.387 254H400v6c0 3.314 2.88 6 6.193 6s6.097-2.686 6.097-6-2.59-6-5.903-6z"
      />
      <LinearGradient
        id="prefix__h"
        gradientUnits="userSpaceOnUse"
        x1={354.097}
        y1={180.001}
        x2={354.097}
        y2={309.469}
      >
        <Stop offset={0.1} stopColor="#ffc294" />
        <Stop offset={0.6} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path
        fill="url(#prefix__h)"
        d="M402 261.999l.193.001c0 26.51-21.587 48-48.097 48S306 288.509 306 262v-28c0-26.51 21.49-48 48-48 26.509 0 48 21.49 48 47.999v28z"
      />
      <Path
        opacity={0.05}
        d="M412.387 260c0 .34-.02.68-.1 1-.46-2.84-2.92-5-5.9-5H402v-2h4.387c3.32 0 6 2.68 6 6z"
      />
      <LinearGradient
        id="prefix__i"
        gradientUnits="userSpaceOnUse"
        x1={354}
        y1={259.75}
        x2={354}
        y2={271.595}
      >
        <Stop offset={0} stopColor="#f9cdaf" />
        <Stop offset={0.536} stopColor="#f8caab" />
        <Stop offset={1} stopColor="#f7c4a1" />
      </LinearGradient>
      <Path fill="url(#prefix__i)" d="M358 268a4 4 0 01-8 0v-4a4 4 0 018 0v4z" />
      <LinearGradient
        id="prefix__j"
        gradientUnits="userSpaceOnUse"
        x1={354.387}
        y1={282.25}
        x2={354.387}
        y2={297.302}
      >
        <Stop offset={0} stopColor="#f9808f" />
        <Stop offset={0.391} stopColor="#f97792" />
        <Stop offset={1} stopColor="#f96099" />
      </LinearGradient>
      <Path
        fill="url(#prefix__j)"
        d="M370.387 282c0 6-2.84 10.46-7.16 13.32-2.52 1.7-5.56 2.68-8.84 2.68s-6.32-.98-8.84-2.68c-4.32-2.86-7.16-7.32-7.16-13.32h32z"
      />
      <Path
        fill="#FFF"
        d="M370 282h-32v-2c0-1.105 1.282-2 2.387-2h28c1.105 0 1.613.895 1.613 2v2z"
      />
      <LinearGradient
        id="prefix__k"
        gradientUnits="userSpaceOnUse"
        x1={354.387}
        y1={291.5}
        x2={354.387}
        y2={298.251}
      >
        <Stop offset={0} stopColor="#ea5770" />
        <Stop offset={0.552} stopColor="#ea4f79" />
        <Stop offset={1} stopColor="#ea4684" />
      </LinearGradient>
      <Path
        fill="url(#prefix__k)"
        d="M363.227 295.32c-2.52 1.7-5.56 2.68-8.84 2.68s-6.32-.98-8.84-2.68c1.68-3.16 5.02-3.32 8.84-3.32s7.16.16 8.84 3.32z"
      />
      <LinearGradient
        id="prefix__l"
        gradientUnits="userSpaceOnUse"
        x1={374}
        y1={241.833}
        x2={374}
        y2={246.333}
      >
        <Stop offset={0} stopColor="#705340" />
        <Stop offset={1} stopColor="#93614c" />
      </LinearGradient>
      <Path fill="url(#prefix__l)" d="M382 244a2 2 0 01-2 2h-12a2 2 0 110-4h12a2 2 0 012 2z" />
      <LinearGradient
        id="prefix__m"
        gradientUnits="userSpaceOnUse"
        x1={334}
        y1={241.833}
        x2={334}
        y2={246.333}
      >
        <Stop offset={0} stopColor="#705340" />
        <Stop offset={1} stopColor="#93614c" />
      </LinearGradient>
      <Path fill="url(#prefix__m)" d="M342 244a2 2 0 01-2 2h-12a2 2 0 110-4h12a2 2 0 012 2z" />
      <LinearGradient
        id="prefix__n"
        gradientUnits="userSpaceOnUse"
        x1={320.399}
        y1={204.664}
        x2={347.387}
        y2={204.664}
      >
        <Stop offset={0} stopColor="#ffffed" stopOpacity={0} />
        <Stop offset={0.403} stopColor="#fffff6" stopOpacity={0.161} />
        <Stop offset={1} stopColor="#fff" stopOpacity={0.4} />
      </LinearGradient>
      <Path
        fill="url(#prefix__n)"
        d="M323.396 215a3 3 0 01-2.442-4.739c5.496-7.729 13.538-13.349 22.646-15.826a2.995 2.995 0 013.682 2.107 2.998 2.998 0 01-2.107 3.682c-7.77 2.114-14.636 6.914-19.33 13.514a3 3 0 01-2.449 1.262z"
      />
      <LinearGradient
        id="prefix__o"
        gradientUnits="userSpaceOnUse"
        x1={350.799}
        y1={236}
        x2={357.975}
        y2={236}
      >
        <Stop offset={0} stopColor="#efbc9a" />
        <Stop offset={0.715} stopColor="#f3c09e" />
        <Stop offset={1} stopColor="#f7c4a1" />
      </LinearGradient>
      <Path
        fill="url(#prefix__o)"
        d="M357.684 236.703l-2.594 2.594a.995.995 0 01-1.406 0l-2.594-2.594a.995.995 0 010-1.406l2.594-2.594a.995.995 0 011.406 0l2.594 2.594a.995.995 0 010 1.406z"
      />
      <LinearGradient
        id="prefix__p"
        gradientUnits="userSpaceOnUse"
        x1={112}
        y1={484}
        x2={624}
        y2={484}
      >
        <Stop offset={0} stopColor="#ededed" />
        <Stop offset={1} stopColor="#ddd" />
      </LinearGradient>
      <Path fill="url(#prefix__p)" d="M112 480h512v8H112z" />
      <LinearGradient
        id="prefix__q"
        gradientUnits="userSpaceOnUse"
        x1={448.387}
        y1={331.458}
        x2={586.386}
        y2={331.458}
      >
        <Stop offset={0} stopColor="#b6dd54" />
        <Stop offset={0.143} stopColor="#b2dc54" />
        <Stop offset={0.292} stopColor="#a5d753" />
        <Stop offset={0.443} stopColor="#91d051" />
        <Stop offset={0.595} stopColor="#74c64f" />
        <Stop offset={0.749} stopColor="#4eb94d" />
        <Stop offset={0.902} stopColor="#21a949" />
        <Stop offset={1} stopColor="#009e47" />
      </LinearGradient>
      <Path
        fill="url(#prefix__q)"
        d="M487.907 296.02c-1.96-1.66-4.84-4.32-7.92-7.88-.58-.68-1.16-1.38-1.74-2.12-.92-1.16-1.84-2.4-2.74-3.7-.3-.42-.6-.88-.88-1.32-.6-.9-1.16-1.84-1.72-2.8-.28-.48-.56-.96-.82-1.46a52.038 52.038 0 01-2.28-4.72c-.3-.66-.56-1.34-.82-2.04-.22-.58-.42-1.16-.6-1.74-.18-.5-.34-1-.48-1.52-.16-.48-.3-.96-.42-1.46-.22-.86-.44-1.74-.6-2.62-.1-.46-.2-.92-.28-1.38-.14-.96-.28-1.94-.38-2.92-.06-.4-.08-.8-.1-1.22-.04-.36-.06-.72-.08-1.08-.12-2.6-.02-5.28.34-8.04 4-30 22-36 44-32 .78.14 1.52.32 2.26.56 4.68 1.54 8.36 4.98 11.24 9.4 9.42 14.36 10.5 39.08 10.5 42.04 0 4 1 10.26 8 12 8 2 12-8 12-18 0-16 6.05-26.325 20-24 12 2 12 20 12 20l-2 92s-10 44-26 64-8 34-8 34h-63.999s28 2-10-32c-14-12-24.872-34-26.872-46-4.364-16 4-34 24-34 11.207 0 21.496 6.255 25.309 8.871 1.708 1.172 3.603 2.069 5.638 2.462 4.019.778 9.625.194 10.071-8.896.136-2.766-.387-5.53-1.291-8.147-6.863-19.851-23.816-32.97-25.336-34.27z"
      />
      <LinearGradient
        id="prefix__r"
        gradientUnits="userSpaceOnUse"
        x1={306}
        y1={353}
        x2={402}
        y2={353}
      >
        <Stop offset={0} stopColor="#ff981a" />
        <Stop offset={0.484} stopColor="#ff951a" />
        <Stop offset={0.775} stopColor="#ff8d1a" />
        <Stop offset={1} stopColor="#ff801a" />
      </LinearGradient>
      <Path fill="url(#prefix__r)" d="M330.387 320S338 356 402 356v30h-96v-66h24.387z" />
      <LinearGradient
        id="prefix__s"
        gradientUnits="userSpaceOnUse"
        x1={306}
        y1={392}
        x2={402}
        y2={392}
      >
        <Stop offset={0} stopColor="#ff8312" />
        <Stop offset={0.405} stopColor="#ff8012" />
        <Stop offset={0.648} stopColor="#ff7811" />
        <Stop offset={0.848} stopColor="#ff6a0f" />
        <Stop offset={1} stopColor="#ff590d" />
      </LinearGradient>
      <Path fill="url(#prefix__s)" d="M306 342s24 44 96 44v56h-96V342z" />
      <LinearGradient
        id="prefix__t"
        gradientUnits="userSpaceOnUse"
        x1={343.579}
        y1={394.09}
        x2={402.053}
        y2={394.09}
      >
        <Stop offset={0} stopColor="#ff8700" />
        <Stop offset={0.34} stopColor="#ff8301" />
        <Stop offset={0.691} stopColor="#ff7604" />
        <Stop offset={1} stopColor="#ff6508" />
      </LinearGradient>
      <Path
        fill="url(#prefix__t)"
        d="M402 386v36s-41.807-20-55.307-36c-9.467-11.22-15.697-18.56-16.497-19.82C345.317 376.4 368 386 402 386z"
      />
      <LinearGradient
        id="prefix__u"
        gradientUnits="userSpaceOnUse"
        x1={306}
        y1={410}
        x2={382.387}
        y2={410}
      >
        <Stop offset={0} stopColor="#ff6c22" />
        <Stop offset={0.606} stopColor="#ff6e1f" />
        <Stop offset={0.969} stopColor="#ff7517" />
        <Stop offset={1} stopColor="#ff7616" />
      </LinearGradient>
      <Path fill="url(#prefix__u)" d="M306 380s14.387 38 76.387 60H306v-60z" />
      <LinearGradient
        id="prefix__v"
        gradientUnits="userSpaceOnUse"
        x1={412}
        y1={318.5}
        x2={412}
        y2={399.006}
      >
        <Stop offset={0} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path fill="url(#prefix__v)" d="M422 400h-20v-80h4.387c8.837 0 15.613 7.163 15.613 16v64z" />
      <LinearGradient
        id="prefix__w"
        gradientUnits="userSpaceOnUse"
        x1={480.387}
        y1={346.666}
        x2={480.387}
        y2={371.002}
      >
        <Stop offset={0} stopColor="#ffe552" />
        <Stop offset={0.197} stopColor="#ffe75b" />
        <Stop offset={0.519} stopColor="#ffed73" />
        <Stop offset={0.923} stopColor="#fff69b" />
        <Stop offset={1} stopColor="#fff8a3" />
      </LinearGradient>
      <Path
        fill="url(#prefix__w)"
        d="M481.442 346.336l1.35 4.556a8.119 8.119 0 005.477 5.477l4.556 1.35c1.051.311 1.051 1.799 0 2.111l-4.556 1.35a8.119 8.119 0 00-5.477 5.477l-1.35 4.556c-.311 1.051-1.799 1.051-2.111 0l-1.35-4.556a8.119 8.119 0 00-5.477-5.477l-4.556-1.35c-1.051-.311-1.051-1.799 0-2.111l4.556-1.35a8.119 8.119 0 005.477-5.477l1.35-4.556c.311-1.051 1.8-1.051 2.111 0z"
      />
      <LinearGradient
        id="prefix__x"
        gradientUnits="userSpaceOnUse"
        x1={401.964}
        y1={403.28}
        x2={422.773}
        y2={396.608}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.1} />
      </LinearGradient>
      <Path fill="url(#prefix__x)" d="M422 400v-4l-19.613 4z" />
      <LinearGradient
        id="prefix__y"
        gradientUnits="userSpaceOnUse"
        x1={296}
        y1={319.5}
        x2={296}
        y2={400}
      >
        <Stop offset={0} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path fill="url(#prefix__y)" d="M286 400h20v-80h-3.613C293.55 320 286 327.163 286 336v64z" />
      <LinearGradient
        id="prefix__z"
        gradientUnits="userSpaceOnUse"
        x1={228.387}
        y1={346.666}
        x2={228.387}
        y2={371.002}
      >
        <Stop offset={0} stopColor="#ffe552" />
        <Stop offset={0.197} stopColor="#ffe75b" />
        <Stop offset={0.519} stopColor="#ffed73" />
        <Stop offset={0.923} stopColor="#fff69b" />
        <Stop offset={1} stopColor="#fff8a3" />
      </LinearGradient>
      <Path
        fill="url(#prefix__z)"
        d="M227.331 346.336l-1.35 4.556a8.119 8.119 0 01-5.477 5.477l-4.556 1.35c-1.051.311-1.051 1.799 0 2.111l4.556 1.35a8.119 8.119 0 015.477 5.477l1.35 4.556c.311 1.051 1.799 1.051 2.111 0l1.35-4.556a8.119 8.119 0 015.477-5.477l4.556-1.35c1.051-.311 1.051-1.799 0-2.111l-4.556-1.35a8.119 8.119 0 01-5.477-5.477l-1.35-4.556c-.311-1.051-1.799-1.051-2.111 0z"
      />
      <LinearGradient
        id="prefix__A"
        gradientUnits="userSpaceOnUse"
        x1={306.88}
        y1={403.319}
        x2={285.115}
        y2={396.559}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.1} />
      </LinearGradient>
      <Path fill="url(#prefix__A)" d="M286 400v-4l20.387 4z" />
      <LinearGradient
        id="prefix__B"
        gradientUnits="userSpaceOnUse"
        x1={340}
        y1={310.96}
        x2={368}
        y2={310.96}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.13} />
      </LinearGradient>
      <Path
        fill="url(#prefix__B)"
        d="M368 307.92v4c-4 1.36-9.12 2.08-14 2.08s-10-.72-14-2.08v-4c4 1.36 9.12 2.08 14 2.08s10-.72 14-2.08z"
      />
      <LinearGradient
        id="prefix__C"
        gradientUnits="userSpaceOnUse"
        x1={541.193}
        y1={321.5}
        x2={541.193}
        y2={449.317}
      >
        <Stop offset={0} stopColor="#302926" stopOpacity={0.05} />
        <Stop offset={0.284} stopColor="#2c2523" stopOpacity={0.078} />
        <Stop offset={0.579} stopColor="#1f1b19" stopOpacity={0.108} />
        <Stop offset={0.877} stopColor="#0b0909" stopOpacity={0.138} />
        <Stop offset={1} stopOpacity={0.15} />
      </LinearGradient>
      <Path
        fill="url(#prefix__C)"
        d="M554 326s-31.689 21.732-15.807 112c0 8-2.506 8.483-5.696 9.135-2.21.451-4.111.866-4.111.866H554V326z"
      />
      <Path
        fill="#1B8C44"
        d="M564.704 279.051a1.006 1.006 0 00-1.266.633c-8.719 26.162-25.297 46.421-29.025 50.767a1771.363 1771.363 0 00-7.544-13.677c-13.76-24.76-27.986-50.361-29.486-66.863-.049-.553-.586-.941-1.086-.906a1.001 1.001 0 00-.906 1.086c1.539 16.924 15.871 42.715 29.73 67.654 4.426 7.965 8.605 15.486 12.379 22.719 15.16 29.056 5.886 65.784-2.679 88.338-1.817-8.653-8.857-33.91-31.784-53.561a1 1 0 10-1.301 1.519c27.361 23.453 31.619 55.049 31.658 55.365.008.066.039.123.059.184v.001c-4.162 10.369-7.823 16.998-7.938 17.2a.999.999 0 101.742.98c.363-.645 35.988-65.004 12.016-110.953a543.669 543.669 0 00-3.842-7.209c2.543-2.902 20.617-24.155 29.904-52.011a1.001 1.001 0 00-.631-1.266z"
      />
      <LinearGradient
        id="prefix__D"
        gradientUnits="userSpaceOnUse"
        x1={534.696}
        y1={355.911}
        x2={686.836}
        y2={355.911}
      >
        <Stop offset={0} stopColor="#00e35e" />
        <Stop offset={1} stopColor="#00b248" />
      </LinearGradient>
      <Path
        fill="url(#prefix__D)"
        d="M572.699 448c-2.8-4-.854-6.928 3.687-8 19.769-4.669 48.857-15.742 50-26 .153-1.378.641-5.757-2-8-3.314-2.815-9.264-.319-10 0-2.703 1.352-5.952 1.124-8.394-.657a13.75 13.75 0 01-1.606-1.343c-2-2-2-8 6-12s32-6 50-18 18-24 4-24c-6.609 1.102-11.398 2.81-14.368 4.122a4.7 4.7 0 01-6.005-2.038l-.088-.161a4.722 4.722 0 011.392-6.074A538.434 538.434 0 01662.386 334s20-12 24-38c3.076-19.995-10.408-28.162-16.8-30.868-2.025-.857-4.19-1.281-6.388-1.307-8.197-.096-29.589 2.008-32.811 26.174-4 30-16 28-20 20l-12-24s-6-12-20-4c-10.125 5.786 2 40 2 40s8 26-4 30-10-16-10-16 2-16-12-10-26 46-16 94c8 28 0 28 0 28h34.312z"
      />
      <LinearGradient
        id="prefix__E"
        gradientUnits="userSpaceOnUse"
        x1={704.386}
        y1={295.061}
        x2={630.386}
        y2={295.061}
      >
        <Stop offset={0} stopColor="#ffffed" stopOpacity={0} />
        <Stop offset={0.403} stopColor="#fffff6" stopOpacity={0.081} />
        <Stop offset={1} stopColor="#fff" stopOpacity={0.2} />
      </LinearGradient>
      <Path
        fill="url(#prefix__E)"
        d="M686.386 296c-2.18 14.12-9.06 24.12-14.92 30.3 3.76-5.74 7.08-13.08 8.48-22.12 4-26-20-32-20-32s-15.6-1.96-26.38 7.1c7.238-14.413 23.529-15.589 30.047-15.448 1.925.042 3.82.418 5.602 1.146 6.224 2.542 20.308 10.631 17.171 31.022z"
      />
      <LinearGradient
        id="prefix__F"
        gradientUnits="userSpaceOnUse"
        x1={482}
        y1={466}
        x2={582}
        y2={466}
      >
        <Stop offset={0} stopColor="#7c483e" />
        <Stop offset={1} stopColor="#896257" />
      </LinearGradient>
      <Path
        fill="url(#prefix__F)"
        d="M570.386 480h-76c-6.627 0-12.387-5.373-12.387-12v-16h100v16c.001 6.627-4.985 12-11.613 12z"
      />
      <Path
        fill="#1B8C44"
        d="M655.348 300.276a.998.998 0 00-.686-1.236.987.987 0 00-1.236.686c-3.305 11.565-20.486 35.629-48.14 63.005-.485-7.975-2.866-40.421-10.067-51.222-.309-.461-.932-.52-1.387-.215-.459.307-.584 1.053-.277 1.512 7.756 11.633 9.812 51.348 9.834 51.746 0 0 .007.026.008.038a480.972 480.972 0 01-15.676 14.665c-8.84 7.909-15.621 15.953-20.854 23.651-1.853-2.923-9.48-16.21-9.48-36.905a1 1 0 10-2 0c0 20.01 7.438 34.2 10.223 38.772-15.973 24.485-16.223 44.954-16.223 45.228a1 1 0 102 0c0-.231.212-15.388 10.587-35.109.076.035.147.078.233.093.178.031 14.381 2.57 31.252 2.57 4.232 0 8.631-.16 13.018-.559a1 1 0 00.906-1.086c-.049-.551-.59-.951-1.086-.906-20.051 1.829-40.357-1.413-43.344-1.921 5.579-10.137 13.833-21.361 26.1-32.337a482.091 482.091 0 0010.763-9.927c.11.077.235.134.375.162.1.02 5.178.996 13.928.996 8.863 0 21.49-1.002 36.525-5.01a1.002 1.002 0 00-.516-1.934c-25.789 6.878-44.308 4.786-48.598 4.143 30.928-29.694 50.242-56.387 53.818-68.9z"
      />
      <LinearGradient
        id="prefix__G"
        gradientUnits="userSpaceOnUse"
        x1={534.181}
        y1={255.457}
        x2={463.065}
        y2={255.457}
      >
        <Stop offset={0} stopColor="#ffffed" stopOpacity={0} />
        <Stop offset={0.366} stopColor="#fffff5" stopOpacity={0.073} />
        <Stop offset={1} stopColor="#fff" stopOpacity={0.2} />
      </LinearGradient>
      <Path
        fill="url(#prefix__G)"
        d="M523.886 225.96c-1.68-.94-3.5-1.6-5.5-1.96-22-4-40 2-44 32-2.3 17.32 6.04 31.3 13.52 40.02-1.96-1.66-4.84-4.32-7.92-7.88-.56-.68-1.16-1.38-1.74-2.12-.92-1.16-1.84-2.4-2.74-3.7-.3-.42-.6-.88-.88-1.32-.6-.9-1.16-1.84-1.72-2.8-.28-.48-.56-.96-.82-1.46a52.038 52.038 0 01-2.28-4.72c-.3-.66-.56-1.34-.82-2.04-.22-.58-.42-1.16-.6-1.74-.18-.5-.34-1-.48-1.52-.16-.48-.3-.96-.42-1.46-.22-.86-.44-1.74-.6-2.62-.1-.46-.2-.92-.28-1.38-.14-.96-.28-1.94-.38-2.92-.06-.4-.08-.8-.1-1.22-.04-.36-.06-.72-.08-1.08-.12-2.6-.02-5.28.34-8.04 4-30 22-36 44-32 .78.14 1.52.32 2.26.56 4.68 1.54 8.36 4.98 11.24 9.4z"
      />
      <LinearGradient
        id="prefix__H"
        gradientUnits="userSpaceOnUse"
        x1={114.387}
        y1={270}
        x2={227.691}
        y2={270}
      >
        <Stop offset={0} stopColor="#7ba1e2" />
        <Stop offset={1} stopColor="#7b1ae2" />
      </LinearGradient>
      <Circle fill="url(#prefix__H)" cx={174.387} cy={270} r={54} />
      <LinearGradient
        id="prefix__I"
        gradientUnits="userSpaceOnUse"
        x1={128.387}
        y1={270}
        x2={220.387}
        y2={270}
      >
        <Stop offset={0} stopColor="ivory" />
        <Stop offset={0.462} stopColor="#f9f9ef" />
        <Stop offset={1} stopColor="#ebebeb" />
      </LinearGradient>
      <Circle fill="url(#prefix__I)" cx={174.387} cy={270} r={46} />
      <LinearGradient
        id="prefix__J"
        gradientUnits="userSpaceOnUse"
        x1={476}
        y1={452}
        x2={588}
        y2={452}
      >
        <Stop offset={0} stopColor="#b16464" />
        <Stop offset={1} stopColor="#936864" />
      </LinearGradient>
      <Path
        fill="url(#prefix__J)"
        d="M588 454a2 2 0 01-2 2H478a2 2 0 01-2-2v-4a2 2 0 012-2h108a2 2 0 012 2v4z"
      />
      <LinearGradient
        id="prefix__K"
        gradientUnits="userSpaceOnUse"
        x1={490.387}
        y1={459}
        x2={582}
        y2={459}
      >
        <Stop offset={0} stopColor="#302926" stopOpacity={0} />
        <Stop offset={0.284} stopColor="#2c2523" stopOpacity={0.043} />
        <Stop offset={0.579} stopColor="#1f1b19" stopOpacity={0.087} />
        <Stop offset={0.877} stopColor="#0b0909" stopOpacity={0.132} />
        <Stop offset={1} stopOpacity={0.15} />
      </LinearGradient>
      <Path fill="url(#prefix__K)" d="M490.387 456H582v6z" />
      <LinearGradient
        id="prefix__L"
        gradientUnits="userSpaceOnUse"
        x1={173}
        y1={265.125}
        x2={173}
        y2={237.959}
      >
        <Stop offset={0} stopColor="#353535" />
        <Stop offset={1} stopColor="#797979" />
      </LinearGradient>
      <Path fill="url(#prefix__L)" d="M174 239a1 1 0 00-2 0v30a1 1 0 002 0v-30z" />
      <LinearGradient
        id="prefix__M"
        gradientUnits="userSpaceOnUse"
        x1={172}
        y1={271}
        x2={204}
        y2={271}
      >
        <Stop offset={0} stopColor="#353535" />
        <Stop offset={1} stopColor="#797979" />
      </LinearGradient>
      <Path fill="url(#prefix__M)" d="M204 271a1 1 0 01-1 1h-30a1 1 0 010-2h30a1 1 0 011 1z" />
      <LinearGradient
        id="prefix__N"
        gradientUnits="userSpaceOnUse"
        x1={168.387}
        y1={270}
        x2={180.387}
        y2={270}
      >
        <Stop offset={0} stopColor="#73767a" />
        <Stop offset={0.042} stopColor="#727579" />
        <Stop offset={0.529} stopColor="#65686c" />
        <Stop offset={1} stopColor="#616468" />
      </LinearGradient>
      <Circle fill="url(#prefix__N)" cx={174.387} cy={270} r={6} />
      <LinearGradient
        id="prefix__O"
        gradientUnits="userSpaceOnUse"
        x1={130.862}
        y1={476}
        x2={204.387}
        y2={476}
      >
        <Stop offset={0} stopColor="#ddd" />
        <Stop offset={0.15} stopColor="#cfcfcf" />
        <Stop offset={0.439} stopColor="#bbb" />
        <Stop offset={0.724} stopColor="#aeaeae" />
        <Stop offset={1} stopColor="#aaa" />
      </LinearGradient>
      <Path
        fill="url(#prefix__O)"
        d="M196.708 480H138.54a7.957 7.957 0 01-7.115-4.397l-.35-.699a2.006 2.006 0 011.794-2.903h69.508a2.006 2.006 0 011.794 2.903l-.35.7a7.95 7.95 0 01-7.113 4.396z"
      />
      <LinearGradient
        id="prefix__P"
        gradientUnits="userSpaceOnUse"
        x1={168}
        y1={441.639}
        x2={168}
        y2={472.061}
      >
        <Stop offset={0} stopColor="#f9e0ac" />
        <Stop offset={1} stopColor="#fce086" />
      </LinearGradient>
      <Path
        fill="url(#prefix__P)"
        d="M178 472h-20v-28c0-1.105 1.282-2 2.387-2h16c1.105 0 1.613.895 1.613 2v28z"
      />
      <Path fill="#3F3F3F" d="M166 434h4v8h-4z" />
      <LinearGradient
        id="prefix__Q"
        gradientUnits="userSpaceOnUse"
        x1={160.387}
        y1={427.464}
        x2={176.387}
        y2={427.464}
      >
        <Stop offset={0} stopColor="#ffe552" />
        <Stop offset={1} stopColor="#ff9617" />
      </LinearGradient>
      <Path
        fill="url(#prefix__Q)"
        d="M176.387 430a8 8 0 01-16 0c0-4.352 4.208-9.755 6.52-12.396a1.956 1.956 0 012.959 0c2.313 2.64 6.521 8.044 6.521 12.396z"
      />
      <LinearGradient
        id="prefix__R"
        gradientUnits="userSpaceOnUse"
        x1={145.538}
        y1={470}
        x2={158.258}
        y2={470}
      >
        <Stop offset={0} stopColor="#f9e0ac" />
        <Stop offset={1} stopColor="#fce086" />
      </LinearGradient>
      <Path fill="url(#prefix__R)" d="M145.624 472H158v-4h-8.376a4 4 0 00-4 4z" />
      <LinearGradient
        id="prefix__S"
        gradientUnits="userSpaceOnUse"
        x1={189.947}
        y1={470}
        x2={177.915}
        y2={470}
      >
        <Stop offset={0} stopColor="#f9e0ac" />
        <Stop offset={1} stopColor="#fce086" />
      </LinearGradient>
      <Path fill="url(#prefix__S)" d="M178 472h11.624a4 4 0 00-4-4H178v4z" />
      <LinearGradient
        id="prefix__T"
        gradientUnits="userSpaceOnUse"
        x1={390}
        y1={439.5}
        x2={390}
        y2={480.576}
      >
        <Stop offset={0} stopColor="#ff7d43" />
        <Stop offset={0.395} stopColor="#ff7a42" />
        <Stop offset={0.711} stopColor="#ff7040" />
        <Stop offset={1} stopColor="#ff5f3c" />
      </LinearGradient>
      <Path
        fill="url(#prefix__T)"
        d="M446 460c0 11.046-8.954 20-20 20h-72c-11.046 0-20-8.954-20-20s8.954-20 20-20h72c11.046 0 20 8.954 20 20z"
      />
      <Path
        opacity={0.1}
        fill="#020202"
        d="M446 460c0 11.046-8.954 20-20 20h-72c-11.046 0-20-8.954-20-20s8.954-20 20-20h72c11.046 0 20 8.954 20 20z"
      />
      <LinearGradient
        id="prefix__U"
        gradientUnits="userSpaceOnUse"
        x1={374}
        y1={468.52}
        x2={446.387}
        y2={468.52}
      >
        <Stop offset={0} stopColor="#302926" stopOpacity={0} />
        <Stop offset={1} stopOpacity={0.13} />
      </LinearGradient>
      <Path
        fill="url(#prefix__U)"
        d="M374 474v6h52.387c11.04 0 20-8.96 20-20 0-1-.08-2-.24-2.96-1.46 9.6-9.76 16.96-19.76 16.96H374z"
      />
      <LinearGradient
        id="prefix__V"
        gradientUnits="userSpaceOnUse"
        x1={318.193}
        y1={435.333}
        x2={318.193}
        y2={479.414}
      >
        <Stop offset={0} stopColor="#ff7d43" />
        <Stop offset={0.395} stopColor="#ff7a42" />
        <Stop offset={0.711} stopColor="#ff7040" />
        <Stop offset={1} stopColor="#ff5f3c" />
      </LinearGradient>
      <Path
        fill="url(#prefix__V)"
        d="M374 480h-91.613c-11.046 0-20-8.955-20-20 0-11.046 8.954-20 20-20h88c2.209 0 3.613 1.79 3.613 4v36z"
      />
      <LinearGradient
        id="prefix__W"
        gradientUnits="userSpaceOnUse"
        x1={371.473}
        y1={465}
        x2={410.453}
        y2={465}
      >
        <Stop offset={0} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path
        fill="url(#prefix__W)"
        d="M406.387 450H374v30h18.387a2 2 0 000-4h-2c-.56 0-1-.44-1-1s.44-1 1-1h7a3 3 0 100-6h-5c-.56 0-1-.44-1-1s.44-1 1-1h9a3 3 0 100-6h-5c-.56 0-1-.44-1-1s.44-1 1-1h10a4 4 0 000-8z"
      />
      <LinearGradient
        id="prefix__X"
        gradientUnits="userSpaceOnUse"
        x1={370}
        y1={438.333}
        x2={370}
        y2={479.335}
      >
        <Stop offset={0} stopColor="#ff9976" />
        <Stop offset={0.38} stopColor="#ff9773" />
        <Stop offset={0.609} stopColor="#ff8f6b" />
        <Stop offset={0.797} stopColor="#ff825d" />
        <Stop offset={0.963} stopColor="#ff7049" />
        <Stop offset={1} stopColor="#ff6b43" />
      </LinearGradient>
      <Path fill="url(#prefix__X)" d="M366 480h8v-36c0-2.209-1.404-4-3.613-4H366v40z" />
      <LinearGradient
        id="prefix__Y"
        gradientUnits="userSpaceOnUse"
        x1={262.387}
        y1={468.52}
        x2={366}
        y2={468.52}
      >
        <Stop offset={0} stopColor="#302926" stopOpacity={0} />
        <Stop offset={0.238} stopColor="#211c1a" stopOpacity={0.031} />
        <Stop offset={0.71} stopColor="#090807" stopOpacity={0.092} />
        <Stop offset={1} stopOpacity={0.13} />
      </LinearGradient>
      <Path
        fill="url(#prefix__Y)"
        d="M366 474v6h-83.613c-11.04 0-20-8.96-20-20 0-1 .08-2 .24-2.96 1.46 9.6 9.76 16.96 19.76 16.96H366z"
      />
      <LinearGradient
        id="prefix__Z"
        gradientUnits="userSpaceOnUse"
        x1={362}
        y1={457}
        x2={366}
        y2={457}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.1} />
      </LinearGradient>
      <Path fill="url(#prefix__Z)" d="M362 440h4v34h-4z" />
      <LinearGradient
        id="prefix__aa"
        gradientUnits="userSpaceOnUse"
        x1={378.167}
        y1={465}
        x2={374.167}
        y2={465}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.15} />
      </LinearGradient>
      <Path fill="url(#prefix__aa)" d="M374 450h4v30h-4z" />
      <LinearGradient
        id="prefix__ab"
        gradientUnits="userSpaceOnUse"
        x1={402}
        y1={400}
        x2={516.386}
        y2={400}
      >
        <Stop offset={0} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path
        fill="url(#prefix__ab)"
        d="M512.386 400h-20.528c1.588-2 2.528-5.026 2.528-8 0-4.379-2.092-8.565-5.596-11.197a3.997 3.997 0 00-5.6.795 3.997 3.997 0 00.795 5.6 5.958 5.958 0 012.4 4.803c0 3.309-2.691 6-6 6s-6-2.691-6-6a5.95 5.95 0 012.402-4.801 4.003 4.003 0 00.797-5.602 4.004 4.004 0 00-5.602-.797A14.08 14.08 0 00466.384 392c0 2.974.94 6 2.528 8H402v10c0 5.523 2.01 10 10.387 10h18.705c10.181 0 20.348-.777 30.411-2.326L472.387 416h29.937a4.062 4.062 0 004.062-4.062 3.938 3.938 0 00-3.938-3.938h9.937a4 4 0 00.001-8z"
      />
      <LinearGradient
        id="prefix__ac"
        gradientUnits="userSpaceOnUse"
        x1={308.99}
        y1={400}
        x2={192.386}
        y2={400}
      >
        <Stop offset={0} stopColor="#ffdec7" />
        <Stop offset={1} stopColor="#ffd8bd" />
      </LinearGradient>
      <Path
        fill="url(#prefix__ac)"
        d="M239.859 400c1.588-2 2.528-5.026 2.528-8 0-4.379-2.092-8.565-5.596-11.197a3.997 3.997 0 00-5.6.795 3.997 3.997 0 00.795 5.6 5.958 5.958 0 012.4 4.803c0 3.309-2.691 6-6 6s-6-2.691-6-6a5.95 5.95 0 012.402-4.801 4.003 4.003 0 00.797-5.602 4.004 4.004 0 00-5.602-.797A14.08 14.08 0 00214.385 392c0 2.974.94 6 2.528 8h-20.528a4 4 0 000 8h9.937a3.937 3.937 0 00-3.937 3.938 4.062 4.062 0 004.062 4.062h29.937l10.884 1.674A200.022 200.022 0 00277.679 420h18.705c8.376 0 9.613-4.478 9.613-10v-10h-66.138z"
      />
      <LinearGradient
        id="prefix__ad"
        gradientUnits="userSpaceOnUse"
        x1={238.055}
        y1={417.332}
        x2={202.792}
        y2={406.379}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.07} />
      </LinearGradient>
      <Path fill="url(#prefix__ad)" d="M206.387 408H236v8h-29.613a4 4 0 010-8z" />
      <LinearGradient
        id="prefix__ae"
        gradientUnits="userSpaceOnUse"
        x1={425.071}
        y1={417.457}
        x2={389.029}
        y2={406.262}
        gradientTransform="matrix(-1 0 0 1 895 0)"
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.07} />
      </LinearGradient>
      <Path fill="url(#prefix__ae)" d="M502.387 408H472v8h30.387a4 4 0 000-8z" />
      <LinearGradient
        id="prefix__af"
        gradientUnits="userSpaceOnUse"
        x1={176}
        y1={448.214}
        x2={176}
        y2={471.922}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.1} />
      </LinearGradient>
      <Path fill="url(#prefix__af)" d="M174 448h4v24h-4z" />
      <Path fill="#D85641" d="M304 436h20v2h-20z" />
      <Path fill="#5E4343" d="M480 460h20v2h-20z" />
      <Path fill="#D6C372" d="M154 464h20v2h-20z" />
      <LinearGradient
        id="prefix__ag"
        gradientUnits="userSpaceOnUse"
        x1={174.387}
        y1={271.25}
        x2={174.387}
        y2={224.183}
      >
        <Stop offset={0} stopColor="#302926" stopOpacity={0} />
        <Stop offset={0.284} stopColor="#2c2523" stopOpacity={0.028} />
        <Stop offset={0.579} stopColor="#1f1b19" stopOpacity={0.058} />
        <Stop offset={0.877} stopColor="#0b0909" stopOpacity={0.088} />
        <Stop offset={1} stopOpacity={0.1} />
      </LinearGradient>
      <Path
        fill="url(#prefix__ag)"
        d="M220.387 270c0 .68-.02 1.34-.06 2-1.04-24.48-21.22-44-45.94-44s-44.9 19.52-45.94 44c-.04-.66-.06-1.32-.06-2 0-25.4 20.6-46 46-46s46 20.6 46 46z"
      />
      <LinearGradient
        id="prefix__ah"
        gradientUnits="userSpaceOnUse"
        x1={374.388}
        y1={256.75}
        x2={374.388}
        y2={268.25}
      >
        <Stop offset={0} stopColor="#ce956e" />
        <Stop offset={0.557} stopColor="#cc926a" />
        <Stop offset={1} stopColor="#c98b61" />
      </LinearGradient>
      <Path
        fill="url(#prefix__ah)"
        d="M374.387 268a9.998 9.998 0 01-9.682-7.487 2.002 2.002 0 011.436-2.438 2.002 2.002 0 012.438 1.436 5.994 5.994 0 005.809 4.489 5.999 5.999 0 005.812-4.498 2 2 0 113.874.998 10 10 0 01-9.687 7.5z"
      />
      <LinearGradient
        id="prefix__ai"
        gradientUnits="userSpaceOnUse"
        x1={334.64}
        y1={256.75}
        x2={334.64}
        y2={268.25}
      >
        <Stop offset={0} stopColor="#ce956e" />
        <Stop offset={0.557} stopColor="#cc926a" />
        <Stop offset={1} stopColor="#c98b61" />
      </LinearGradient>
      <Path
        fill="url(#prefix__ai)"
        d="M334.639 268a10 10 0 01-9.682-7.486 2.001 2.001 0 013.873-1.003 5.997 5.997 0 005.809 4.49 5.999 5.999 0 005.812-4.498 2 2 0 113.873.998 9.997 9.997 0 01-9.685 7.499z"
      />
      <Path fill="#D85641" d="M304 432h12v2h-12z" />
      <Circle fill="#C9C9C9" cx={174.387} cy={310} r={2} />
      <Circle fill="#C9C9C9" cx={174.387} cy={230} r={2} />
      <Circle fill="#C9C9C9" cx={214.387} cy={270} r={2} />
      <Circle fill="#C9C9C9" cx={134.387} cy={270} r={2} />
      <Circle fill="#C9C9C9" cx={202.671} cy={298.284} r={2} />
      <Circle fill="#C9C9C9" cx={146.103} cy={241.715} r={2} />
      <Circle fill="#C9C9C9" cx={202.671} cy={241.715} r={2} />
      <Circle fill="#C9C9C9" cx={146.103} cy={298.284} r={2} />
      <LinearGradient
        id="prefix__aj"
        gradientUnits="userSpaceOnUse"
        x1={577.451}
        y1={280.833}
        x2={577.451}
        y2={350.872}
      >
        <Stop offset={0} stopColor="#302926" stopOpacity={0.05} />
        <Stop offset={0.284} stopColor="#2c2523" stopOpacity={0.078} />
        <Stop offset={0.579} stopColor="#1f1b19" stopOpacity={0.108} />
        <Stop offset={0.877} stopColor="#0b0909" stopOpacity={0.138} />
        <Stop offset={1} stopOpacity={0.15} />
      </LinearGradient>
      <Path
        fill="url(#prefix__aj)"
        d="M577.906 351.32c5.94-7.94.04-27.14.04-27.14s-12.14-34.22-2-40c.14-.08.28-.16.4-.22-6.18 9.2 4.04 38.04 4.04 38.04s7.28 23.66-2.48 29.32z"
      />
      <LinearGradient
        id="prefix__ak"
        gradientUnits="userSpaceOnUse"
        x1={396.036}
        y1={348}
        x2={403.185}
        y2={348}
      >
        <Stop offset={0} stopColor="#793a00" stopOpacity={0} />
        <Stop offset={1} stopColor="#793a00" stopOpacity={0.13} />
      </LinearGradient>
      <Path fill="url(#prefix__ak)" d="M402 340v16h-5.613z" />
      <LinearGradient
        id="prefix__al"
        gradientUnits="userSpaceOnUse"
        x1={448.39}
        y1={349.57}
        x2={514.548}
        y2={349.57}
      >
        <Stop offset={0} stopColor="#286a00" stopOpacity={0} />
        <Stop offset={0.517} stopColor="#286600" stopOpacity={0.052} />
        <Stop offset={1} stopColor="#285b00" stopOpacity={0.1} />
      </LinearGradient>
      <Path
        fill="url(#prefix__al)"
        d="M514.527 341.54c-.44 9.1-6.04 9.68-10.06 8.9-2.04-.4-3.94-1.28-5.64-2.46-3.82-2.62-14.1-8.88-25.32-8.88-15.88 0-24.42 11.34-25.08 24.04-.68-13.88 7.92-27.14 25.08-27.14 11.22 0 21.5 6.26 25.32 8.88 1.7 1.16 3.6 2.06 5.64 2.46 3.92.76 9.34.2 10-8.22.08.8.101 1.621.06 2.42z"
      />
    </Svg>
  )
}

export default Nature
