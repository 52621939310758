import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const PlaceModel = types.model("Place").props({
  city: types.maybe(types.string),
  region: types.maybe(types.string),
  country: types.maybe(types.string),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type PlaceType = Instance<typeof PlaceModel>
export interface Place extends PlaceType {}
type PlaceSnapshotType = SnapshotOut<typeof PlaceModel>
export interface PlaceSnapshot extends PlaceSnapshotType {}
