import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { UserByIdReference } from "../user-store/user-store"

/**
 * Model description here for TypeScript hints.
 */
export const ChatUserModel = types.model("ChatUser").props({
  _id: types.string,
  name: types.string,
  avatar: types.maybe(types.string),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ChatUserType = Instance<typeof ChatUserModel>
export interface ChatUser extends ChatUserType {}
type ChatUserSnapshotType = SnapshotOut<typeof ChatUserModel>
export interface ChatUserSnapshot extends ChatUserSnapshotType {}
