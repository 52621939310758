import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { RadioStationModel } from "../radio-station/radio-station"

/**
 * Model description here for TypeScript hints.
 */
export const ProgramModel = types.model("Program").props({
  objectId: types.identifier,
  name: types.string,
  info: types.string,
  cover: types.string,
  radio_id: types.reference(RadioStationModel),
  hasPodcasts: types.optional(types.boolean, false),
  icon: types.string,
  isPublic: types.optional(types.boolean, true),
})
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ProgramType = Instance<typeof ProgramModel>
export interface Program extends ProgramType {}
type ProgramSnapshotType = SnapshotOut<typeof ProgramModel>
export interface ProgramSnapshot extends ProgramSnapshotType {}
