import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const LinkModel = types.model("Link").props({
  type: types.enumeration("type", ["email", "web", "tel"]),
  value: types.string, // the effective value hans@gruber.com
  name: types.maybe(types.string), // if we want to display Hans Gruber instead of hans@gruber.com or http://hans.gruber
  avatar: types.maybe(types.string),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type LinkType = Instance<typeof LinkModel>
export interface Link extends LinkType {}
type LinkSnapshotType = SnapshotOut<typeof LinkModel>
export interface LinkSnapshot extends LinkSnapshotType {}
