import React from "react"
import { StyleProp, ViewStyle } from "react-native"
import { Text } from "../text/text"
import { Button } from "../button/button"
import { useLinkTo } from "@react-navigation/native"
import { MyModal } from "../my-modal/my-modal"

/**
 * Describe your component here$
 */
export const PaywallPopup = function PaywallPopup() {
  const linkTo = useLinkTo()

  const linkToPaywall = () => {
    linkTo("/subscription")
  }

  return (
    <MyModal visible={true} dismissable={false}>
      <Text preset="header" tx="sub.noaccess" />
      <Text preset="bold" tx="sub.freemonthnomore" />
      <Button onPress={linkToPaywall} tx="sub.getSubButtonText" sx={{ mt: "$xl" }} />
    </MyModal>
  )
}
