import * as React from "react"
import { DrawerItem } from "@react-navigation/drawer"
import { Feather } from "@expo/vector-icons"
import { palette } from "../../theme/palette"
import useMusic from "../../hooks/useMusic"
import { useLinkTo } from "@react-navigation/native"

export interface LogoutItemProps {
  /**
   * An optional style override useful for padding & margin.
   */
  logout: any
}

/**
 * Describe your component here
 */
export const LogoutItem = (props: LogoutItemProps) => {
  const linkTo = useLinkTo()
  const { stopMusic } = useMusic()

  const logUserOutAndStopMusic = () => {
    stopMusic()
    linkTo("/goodbye")
  }

  return (
    <DrawerItem
      label="Logout"
      onPress={() => logUserOutAndStopMusic()}
      icon={() => <Feather name="power" size={20} color={"white"} />}
      labelStyle={{ color: palette.white }}
    />
  )
}
