import { ImageStyle, TextStyle, ViewStyle } from "react-native"
import { spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"

export const METADATA_CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  marginHorizontal: spacing[4],
}

export const TRACK: TextStyle = {
  fontFamily: typography.secondary,
  fontSize: typography.size[5],
  color: palette.white,
  textAlign: "center",
}

export const TRACK_OPEN: TextStyle = {
  fontFamily: typography.secondary,
  fontSize: typography.size[4],
  color: palette.white,
}

export const ARTIST: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[4],
  color: palette.alphawhite,
}

export const ARTIST_OPEN: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: palette.alphawhite,
}

export const VOLUME_CONTAINER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}

export const NO_VOLUME_CONTAINER: ViewStyle = {
  display: "none",
}

export const VOLUME_SLIDER: ViewStyle = {
  paddingHorizontal: spacing[5],
  width: "100%",
}

export const BOTTOM_CONTAINER: ViewStyle = {
  backgroundColor: palette.darkerpurple,
  // justifyContent: "space-between",
  borderTopLeftRadius: 50,
  borderTopRightRadius: 50,
  alignItems: "center",
  paddingBottom: 80,
  bottom: 0,
}

export const CIRCLE_BUTTON: ViewStyle = {
  padding: spacing[2],
  marginVertical: spacing[4],
  width: 50,
  height: 50,
  justifyContent: "center",
  alignContent: "center",
}

export const CHAT_AVATAR: ImageStyle = {
  height: 30,
  width: 30,
  resizeMode: "cover",
  borderRadius: 100,
}
