/**
 * Welcome to the main entry point of the app. In this file, we'll
 * be kicking off our app.
 *
 * Most of this file is boilerplate and you shouldn't need to modify
 * it very often. But take some time to look through and understand
 * what is going on here.
 *
 * The app navigation resides in ./app/navigation, so head over there
 * if you're interested in adding screens and navigators.
 */
import "./i18n"
import "./utils/ignore-warnings"
import React, { useState, useEffect, useRef } from "react"
import { NavigationContainerRef } from "@react-navigation/native"
import { SafeAreaProvider, initialWindowMetrics } from "react-native-safe-area-context"
import { initFonts } from "./theme/fonts" // expo
import * as storage from "./utils/storage"
import {
  useBackButtonHandler,
  RootNavigator,
  canExit,
  setRootNavigation,
  useNavigationPersistence,
} from "./navigation"
import { RootStore, RootStoreProvider, setupRootStore } from "./models"
import { enableScreens } from "react-native-screens"
import { ToastProvider } from "react-native-toast-notifications"
import { Portal } from "react-native-paper"
import { Platform, View } from "react-native"
import { AudioPlayerProvider } from "react-use-audio-player"
import { Audio } from "expo-av"
import { ActionSheetProvider } from "@expo/react-native-action-sheet"
import { ToggleStorybook } from "../storybook/toggle-storybook"
import theme from "./theme/theme"
import { DripsyProvider } from "@dripsy/core"

// import Purchases from "react-native-purchases"

// import "node-libs-react-native/globals"
// import "@rn-matrix/core/shim.js"

// import { polyfillGlobal } from "react-native/Libraries/Utilities/PolyfillFunctions"
// import rnm from "@rn-matrix/core"
// import TrackPlayer from "react-native-track-player"

// polyfillGlobal("URL", () => require("whatwg-url").URL)

// rnm.initAuth()

enableScreens()

const { REVENUECAT_API_KEY } = require("./config/env")

export const NAVIGATION_PERSISTENCE_KEY = "NAVIGATION_STATE"
export const APP_VERSION = "0.0.1"

// in your index.js file
if (Platform.OS === "android") {
  require("@formatjs/intl-getcanonicallocales/polyfill")
  require("@formatjs/intl-locale/polyfill")

  require("@formatjs/intl-pluralrules/polyfill")
  require("@formatjs/intl-pluralrules/locale-data/en.js") // USE YOUR OWN LANGUAGE OR MULTIPLE IMPORTS YOU WANT TO SUPPORT

  require("@formatjs/intl-displaynames/polyfill")
  require("@formatjs/intl-displaynames/locale-data/en.js") // USE YOUR OWN LANGUAGE OR MULTIPLE IMPORTS YOU WANT TO SUPPORT

  require("@formatjs/intl-listformat/polyfill")
  require("@formatjs/intl-listformat/locale-data/en.js") // USE YOUR OWN LANGUAGE OR MULTIPLE IMPORTS YOU WANT TO SUPPORT

  require("@formatjs/intl-numberformat/polyfill")
  require("@formatjs/intl-numberformat/locale-data/en.js") // USE YOUR OWN LANGUAGE OR MULTIPLE IMPORTS YOU WANT TO SUPPORT

  require("@formatjs/intl-relativetimeformat/polyfill")
  require("@formatjs/intl-relativetimeformat/locale-data/en.js") // USE YOUR OWN LANGUAGE OR MULTIPLE IMPORTS YOU WANT TO SUPPORT

  require("@formatjs/intl-datetimeformat/polyfill")
  require("@formatjs/intl-datetimeformat/locale-data/en.js") // USE YOUR OWN LANGUAGE OR MULTIPLE IMPORTS YOU WANT TO SUPPORT

  require("@formatjs/intl-datetimeformat/add-golden-tz.js")

  // https://formatjs.io/docs/polyfills/intl-datetimeformat/#default-timezone

  if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
    // If you are using react-native-cli
    // let RNLocalize = require('react-native-localize');
    // Intl.DateTimeFormat.__setDefaultTimeZone(RNLocalize.getTimeZone());

    //  Are you using Expo, use this instead of previous 2 lines
    Intl.DateTimeFormat.__setDefaultTimeZone(require("expo-localization").timezone)
  }
}

/**
 * This is the root component of our app.
 */

function App() {
  const navigationRef = useRef<NavigationContainerRef>()
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  setRootNavigation(navigationRef)
  useBackButtonHandler(navigationRef, canExit)
  const { initialNavigationState, onNavigationStateChange } = useNavigationPersistence(
    storage,
    NAVIGATION_PERSISTENCE_KEY,
  )

  let myIntitalState

  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      // check if this installation is fresh
      // setAppId(await AsyncStorage.getItem("UniversapAppInstId"))
      // if (!appId) {
      //   console.log("New Installation")
      //   const Installation = Parse.Installation
      //   const installation = new Installation()

      //   installation.set("deviceType", Platform.OS)
      //   installation.set("localeIdentifier", "de")
      //   installation.set("appVersion", APP_VERSION)
      //   installation.set("appName", "Universal App")

      //   await installation.save()

      //   AsyncStorage.setItem("UniversapAppInstId", installation.id)
      // } else {
      //   console.log("found existing Installation")
      // }
      await initFonts() // expo
      await setupRootStore().then(setRootStore)
      // await rootStore.setInstallationId()

      //   Purchases.setDebugLogsEnabled(true)
      //   Purchases.setup(REVENUECAT_API_KEY)

      // setup trackplayer to be used everywhere yay :)
      //   TrackPlayer.setupPlayer().then(() => {
      //     // The player is ready to be used
      //     TrackPlayer.updateOptions({
      //       // background mode
      //       stopWithApp: true,

      //       // the controls we allow in media Control view
      //       capabilities: [TrackPlayer.CAPABILITY_PLAY, TrackPlayer.CAPABILITY_PAUSE],
      //       compactCapabilities: [TrackPlayer.CAPABILITY_PLAY, TrackPlayer.CAPABILITY_PAUSE],
      //     })
      //   })
      Audio.setAudioModeAsync({
        staysActiveInBackground: true,
      })
    })()
  }, [])

  // Before we show the app, we have to wait for our state to be ready.
  // In the meantime, don't render anything. This will be the background
  // color set in native by rootView's background color. You can replace
  // with your own loading component if you wish.
  if (!rootStore) return null
  // rootStore.restore()
  // otherwise, we're ready to render the app
  // if no User is logged in we show the root Nav

  return (
    <ToggleStorybook>
      <DripsyProvider theme={theme}>
        <RootStoreProvider value={rootStore}>
          <SafeAreaProvider initialMetrics={initialWindowMetrics}>
            <ActionSheetProvider>
              <AudioPlayerProvider>
                <View style={{ overflow: "hidden", flex: 1 }}>
                  <Portal.Host>
                    <ToastProvider placement={"bottom"}>
                      <RootNavigator
                        ref={navigationRef}
                        // we don't care about our last state we want radio
                        // initialState={myIntitalState}
                        onStateChange={onNavigationStateChange}
                      />
                    </ToastProvider>
                  </Portal.Host>
                </View>
              </AudioPlayerProvider>
            </ActionSheetProvider>
          </SafeAreaProvider>
        </RootStoreProvider>
      </DripsyProvider>
    </ToggleStorybook>
  )
}

export default App
