/* eslint-disable generator-star-spacing */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const ChatStoreModel = types
  .model("ChatStore")
  .props({
    state: types.optional(types.enumeration("State", ["pending", "done", "error"]), "pending"),
    currentChatId: types.maybe(types.string),
    currentChatName: types.maybe(types.string),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    afterCreate() {
      self.state = "done"
    },
    setCurrentChatId(roomId: string) {
      self.currentChatId = roomId
    },
    setCurrentChatName(roomName: string) {
      self.currentChatId = roomName
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ChatStoreType = Instance<typeof ChatStoreModel>
export interface ChatStore extends ChatStoreType {}
type ChatStoreSnapshotType = SnapshotOut<typeof ChatStoreModel>
export interface ChatStoreSnapshot extends ChatStoreSnapshotType {}
