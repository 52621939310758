import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { AccountScreen, GoodbyeScreen, ResetPasswordScreen } from "../screens"

export type AccountParamList = {
  MyAccount: undefined
  ResetPassword: undefined
  GoodBye: undefined
}

const Stack = createStackNavigator<AccountParamList>()
export const AccountNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ cardStyle: { backgroundColor: "transparent" }, headerShown: false }}
    >
      <Stack.Screen name="MyAccount" component={AccountScreen} />
      <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
      <Stack.Screen name="GoodBye" component={GoodbyeScreen} />
    </Stack.Navigator>
  )
}
