import * as React from "react"
import { Image, ImageStyle } from "react-native"

/**
 * Describe your component here
 */
export const Banner = function Banner(props: { rgb: boolean }) {
  const { rgb } = props

  const style: ImageStyle = {
    height: 35,
    aspectRatio: 1669 / 425,
    resizeMode: "contain",
    alignSelf: "flex-start",
  }

  const whiteBanner = (
    <Image
      style={style}
      source={{
        uri: require("../../../assets/logo/banner-white.png"),
      }}
    />
  )

  const colorBanner = (
    <Image
      style={style}
      source={{
        uri: require("../../../assets/logo/banner-white.png"),
      }}
    />
  )

  return <>{rgb ? colorBanner : whiteBanner}</>
}
