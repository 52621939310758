import React from "react"
import { View, ViewStyle, TextStyle, Platform } from "react-native"
import { HeaderProps } from "./header.props"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
import { spacing } from "../../theme"
import { translate } from "../../i18n/"
import { palette } from "../../theme/palette"
import { getStatusBarHeight } from "react-native-status-bar-height"
import { Link, useRoute } from "@react-navigation/native"

// static styles
const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingHorizontal: spacing[4],
  alignItems: "center",
  paddingTop: spacing[3],
  paddingBottom: spacing[3],
  justifyContent: "flex-start",
  backgroundColor: palette.tinyalphawhite,
}
const TITLE: TextStyle = { textAlign: "center" }
const TITLE_MIDDLE: ViewStyle = { flex: 1, justifyContent: "center" }
const LEFT: ViewStyle = { width: 32 }
const RIGHT: ViewStyle = { width: 32 }
const WEB_RIGHT: ViewStyle = { width: 48 }

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function Header(props: HeaderProps) {
  const {
    onLeftPress,
    onRightPress,
    onMenuPress,
    rightIcon,
    leftIcon,
    menuIcon,
    headerText,
    headerTx,
    style,
    titleStyle,
    children,
    web,
    onLinkTo,
  } = props
  const header = headerText || (headerTx && translate(headerTx)) || ""
  const route = useRoute()
  const slop = { top: 5, bottom: 5, left: 5, right: 5 }

  return (
    <View
      // eslint-disable-next-line react-native/no-inline-styles
      style={[ROOT, style, { marginTop: Platform.OS === "android" ? getStatusBarHeight() : 0 }]}
    >
      {leftIcon && (
        <Button preset="link" onPress={onLeftPress} hitSlop={slop}>
          <Icon name={leftIcon.name} color={leftIcon.color} size={leftIcon.size} />
        </Button>
      )}
      {/* // ) : (
      //   //<View style={LEFT} />
      // )} */}
      {children ? (
        <View style={TITLE_MIDDLE}>{children}</View>
      ) : (
        <View style={TITLE_MIDDLE}>
          <Text style={{ ...TITLE, ...titleStyle }} text={header} />
        </View>
      )}
      {web && (
        <>
          <View style={WEB_RIGHT}>
            <Link to="/events">
              <Button
                preset="webHeader"
                hitSlop={slop}
                style={{
                  backgroundColor: route.name === "Events" ? palette.tinyalphawhite : undefined,
                }}
              >
                <Icon name="calendar" color={rightIcon.color} size={rightIcon.size} />
              </Button>
            </Link>
          </View>
          <View style={WEB_RIGHT}>
            <Link to="/radio">
              <Button
                preset="webHeader"
                hitSlop={slop}
                style={{
                  backgroundColor: route.name === "Radio" ? palette.tinyalphawhite : undefined,
                }}
              >
                <Icon name="radio" color={rightIcon.color} size={rightIcon.size} />
              </Button>
            </Link>
          </View>
          <View style={WEB_RIGHT}>
            <Link to="/profile">
              <Button
                preset="webHeader"
                hitSlop={slop}
                style={{
                  backgroundColor: route.name === "Profile" ? palette.tinyalphawhite : undefined,
                }}
              >
                <Icon name="user" color={rightIcon.color} size={rightIcon.size} />
              </Button>
            </Link>
          </View>
          <View style={RIGHT} />
        </>
      )}

      {rightIcon ? (
        <View style={web ? WEB_RIGHT : RIGHT}>
          <Button preset="link" onPress={onRightPress} hitSlop={slop}>
            <Icon name={rightIcon.name} color={rightIcon.color} size={rightIcon.size} />
          </Button>
        </View>
      ) : (
        <View style={RIGHT} />
      )}
      {menuIcon && (
        <View style={RIGHT}>
          <Button preset="link" onPress={onMenuPress} hitSlop={slop}>
            <Icon name={menuIcon.name} color={menuIcon.color} size={menuIcon.size} />
          </Button>
        </View>
      )}
    </View>
  )
}
