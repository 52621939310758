import * as React from "react"
import { IconProps } from "./icon.props"
import { Feather } from "@expo/vector-icons"
import { palette } from "../../theme/palette"
import Box from "../box/box"

export function Icon(props: IconProps) {
  const { style: styleOverride, name, containerStyle } = props
  // const style: ImageStyle = { ...ROOT, ...styleOverride }
  let { size, color } = props

  if (!size) {
    size = 24
  }

  if (!color) {
    color = palette.morealphawhite
  }

  return (
    // <Box style={containerStyle}>
    <Feather name={name} size={size} color={color} />
    // </Box>
  )
}
