/* eslint-disable react-native/no-color-literals */
import React from "react"
import { observer } from "mobx-react-lite"
import { Screen, Button, Text, Container } from "../../components"
import { useStores } from "../../models"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { LoginForm } from "../../components/login-form/login-form"
import { TextStyle, View, ViewStyle } from "react-native"
import { spacing, typography } from "../../theme"
import Bugsnag from "@bugsnag/react-native"
import { palette } from "../../theme/palette"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import usePlatform from "../../hooks/usePlatform"

const ROOT: ViewStyle = {
  flex: 1,
}

const BOX: ViewStyle = {
  paddingVertical: spacing[4],
  paddingHorizontal: spacing[4],
}

const BUTTON: ViewStyle = {
  paddingVertical: spacing[2],
  marginVertical: spacing[2],
}

const FORM: ViewStyle = {
  marginVertical: spacing[2],
}

const TEXT: TextStyle = {
  color: palette.darkpurple,
  fontSize: typography.size[3],
  fontWeight: "bold",
}

export const LoginScreen = observer(function LoginScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Login Screen")

  const rootStore = useStores()
  const { isWeb } = usePlatform()
  const navigation = useNavigation()
  const linkTo = useLinkTo()

  const goToForgotPassword = () => {
    // navigation.navigate("ForgotPassword")
    linkTo("/passwordreset")
  }

  if (!rootStore) return null

  return (
    <Screen style={ROOT} preset="fixed">
      <Container gradient light menuShown={false}>
        {!isWeb && <UniversalHeader nav={navigation} canGoBack nologo colorful />}
        <View style={BOX}>
          <Text style={TEXT} tx="auth.loginmessage" />
          <LoginForm style={FORM} />
          <Button
            preset="pale"
            style={BUTTON}
            tx="auth.forgotpassword"
            onPress={goToForgotPassword}
          />
        </View>
      </Container>
    </Screen>
  )
})
