import React from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TextStyle } from "react-native"
import { Container, Screen, Text } from "../../components"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { UniversalHeader } from "../../components/universal-header/universal-header"
import { RedeemForm } from "../../components/redeem-form/redeem-form"
import { Gradient } from "../../components/gradient/gradient"
import { palette } from "../../theme/palette"
import Bugsnag from "@bugsnag/react-native"
import usePlatform from "../../hooks/usePlatform"
import { FLEX1 } from "../../utils/style"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
}

const TEXT: TextStyle = {
  fontSize: typography.size[3],
  color: palette.darkerpurple,
  textAlign: "center",
  lineHeight: typography.size[5],
  margin: spacing[3],
}

export const RedeemScreen = observer(function RedeemScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Redeem")
  const { isWeb } = usePlatform()
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  const nav = useNavigation()
  return (
    <Screen style={[ROOT, isWeb && FLEX1]} preset="scroll">
      <Container gradient>
        {!isWeb && <UniversalHeader nav={nav} />}
        <View style={{ padding: spacing[4] }}>
          <Text style={TEXT} tx="sub.redeemText" />
          <RedeemForm />
        </View>
      </Container>
    </Screen>
  )
})
