import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { translate } from "../../../i18n"
import { TextInput, Switch } from "react-native-paper"
import { palette } from "../../../theme/palette"
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates"
import { spacing } from "../../../theme"
import { Text } from "../../text/text"
import Collapsible from "react-native-collapsible"
import { CollapsedError } from "../../collapsed-error/CollapsedError"
import { FLEX01, FLEX09, FLEX1, MATERIAL_INPUT, MB, ROW } from "../../../utils/style"
import { EventFormBottomButtons } from "../../event-form-bottom-buttons/event-form-bottom-buttons"
import InputScrollView from "react-native-input-scroll-view"
import { TextInputMask } from "react-native-masked-text"
import parse from "date-fns/parse"
import format from "date-fns/format"
import { FormPageProps } from "../../form-page/form-page"
import { EventFormValues } from "../event-form"
import usePlatform from "../../../hooks/usePlatform"
export const Page1: React.FC<FormPageProps<EventFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  nextPage,
}) => {
  const [showStartDatePicker, setShowStartDatePicker] = useState(false)
  const [showStartTimePicker, setShowStartTimePicker] = useState(false)

  const [showEndDatePicker, setShowEndDatePicker] = useState(false)
  const [showEndTimePicker, setShowEndTimePicker] = useState(false)

  const [valid, setValid] = useState(false)
  const { isWeb } = usePlatform()

  useEffect(() => {
    if (errors.name || errors.startDate || errors.startTime || errors.endDate || errors.endTime) {
      setValid(false)
    } else {
      if (values.name) {
        setValid(true)
      } else {
        setValid(false)
      }
    }
  }, [values, errors])

  const makeTheDateAndNextPage = () => {
    const newStartDate = new Date(values.startUTC)
    newStartDate.setHours(
      parseInt(values.startTime.substr(0, 2)),
      parseInt(values.startTime.substr(3, 2)),
    )
    setFieldValue("startUTC", newStartDate)

    if (values.hasEndDate) {
      const newEndDate = new Date(values.endUTC)
      newEndDate.setHours(
        parseInt(values.endTime.substr(0, 2)),
        parseInt(values.endTime.substr(3, 2)),
      )
      setFieldValue("endUTC", newEndDate)
    }
    nextPage()
  }

  return (
    <View style={[{ margin: spacing[4] }, !isWeb && FLEX1]}>
      <View style={!isWeb && FLEX09}>
        <InputScrollView>
          <TextInput
            label={translate("event.name")}
            value={values.name}
            onChangeText={handleChange("name")}
            autoCapitalize="none"
            onBlur={handleBlur("name")}
            error={touched.name && errors.name}
            underlineColor={palette.purple}
            style={MB}
          />
          <CollapsedError error={touched.name && errors.name} />
          <TextInput
            label={translate("event.startDate")}
            right={
              <TextInput.Icon
                name="calendar"
                color={palette.purple}
                onPress={() => setShowStartDatePicker(true)}
              />
            }
            value={values.startDate}
            placeholder={"DD.MM.YYYY"}
            blurOnSubmit={true}
            underlineColor={palette.purple}
            style={MB}
            error={errors.startDate}
            render={() => (
              <TextInputMask
                style={MATERIAL_INPUT}
                placeholder={"DD.MM.YYYY"}
                type={"datetime"}
                options={{
                  format: "DD.MM.YYYY",
                }}
                // dont forget to set the "value" and "onChangeText" props
                value={values.startDate}
                onChangeText={(text) => {
                  setFieldValue("startDate", text)
                  setFieldValue("startUTC", parse(values.startDate, "dd.MM.yyyy", new Date()))
                }}
              />
            )}
          />
          <CollapsedError error={errors.startDate} />
          <DatePickerModal
            mode="single"
            visible={showStartDatePicker}
            onDismiss={() => setShowStartDatePicker(false)}
            date={values.startUTC}
            onConfirm={({ date }) => {
              setFieldValue("startUTC", date)
              setFieldValue("startDate", format(date, "dd.MM.yyyy"))
              setShowStartDatePicker(false)
            }}
            validRange={{
              startDate: new Date(), // optional
            }}
            onChange={({ date }) => {
              setFieldValue("startUTC", date)
              setFieldValue("startDate", format(date, "dd.MM.yyyy"))
              setShowStartDatePicker(false)
            }}
            saveLabel={translate("event.action.save")} // optional
            label={translate("event.pick.date")} // optional
          />
          <TextInput
            label={translate("event.startTime")}
            value={values.startTime}
            // onTouchEnd={() => setShowTime(true)}
            right={
              <TextInput.Icon
                name="clock"
                color={palette.purple}
                onPressIn={() => setShowStartTimePicker(true)}
              />
            }
            style={MB}
            blurOnSubmit={true}
            underlineColor={palette.purple}
            error={errors.startTime}
            render={() => (
              <TextInputMask
                style={MATERIAL_INPUT}
                type={"datetime"}
                options={{
                  format: "HH:mm",
                }}
                // dont forget to set the "value" and "onChangeText" props
                value={values.startTime}
                onChangeText={(text) => {
                  setFieldValue("startTime", text)
                }}
              />
            )}
          />
          <CollapsedError error={errors.startTime} />
          <TimePickerModal
            locale="de-CH"
            visible={showStartTimePicker}
            onDismiss={() => setShowStartTimePicker(false)}
            onConfirm={({ hours, minutes }) => {
              let minsindual
              if (minutes < 10) {
                minsindual = "0" + minutes
              } else {
                minsindual = minutes
              }
              let hoursindual
              if (hours < 10) {
                hoursindual = "0" + hours
              } else {
                hoursindual = hours
              }
              setFieldValue("startTime", hoursindual + ":" + minsindual)
              setShowStartTimePicker(false)
            }}
            label={translate("event.pick.time")} // optional
          />
          <View style={ROW}>
            <Text tx="event.addEndDate" />
            <Switch
              value={values.hasEndDate}
              onValueChange={(bool) => setFieldValue("hasEndDate", bool)}
            />
          </View>
          <Collapsible collapsed={!values.hasEndDate}>
            <TextInput
              label={translate("event.endDate")}
              style={MB}
              value={values.endDate}
              right={
                <TextInput.Icon
                  name="calendar"
                  color={palette.purple}
                  onPress={() => setShowEndDatePicker(true)}
                />
              }
              blurOnSubmit={true}
              underlineColor={palette.purple}
              error={touched.endDate && errors.endDate}
              render={() => (
                <TextInputMask
                  style={MATERIAL_INPUT}
                  placeholder={"DD.MM.YYYY"}
                  type={"datetime"}
                  options={{
                    format: "DD.MM.YYYY",
                  }}
                  // dont forget to set the "value" and "onChangeText" props
                  value={values.endDate}
                  onChangeText={(text) => {
                    setFieldValue("endDate", text)
                    setFieldValue("endUTC", parse(values.endDate, "dd.MM.yyyy", new Date()))
                  }}
                />
              )}
            />
            <CollapsedError error={errors.endDate} />
            <DatePickerModal
              locale={"de"}
              mode="single"
              visible={showEndDatePicker}
              onDismiss={() => setShowEndDatePicker(false)}
              date={values.endUTC}
              onConfirm={({ date }) => {
                setFieldValue("endDate", date)
                setShowEndDatePicker(false)
              }}
              validRange={{
                startDate: values.startUTC, // optional
              }}
              onChange={({ date }) => {
                setFieldValue("endUTC", date)
                setFieldValue("endDate", format(date, "dd.MM.yyyy"))
                setShowEndDatePicker(false)
              }}
              saveLabel={translate("event.action.save")} // optional
              label={translate("event.pick.date")} // optional
            />
            <TextInput
              label={translate("event.endTime")}
              value={values.endTime}
              placeholder={"HH:MM"}
              right={
                <TextInput.Icon
                  name="clock"
                  color={palette.purple}
                  onPress={() => setShowEndTimePicker(true)}
                />
              }
              onChangeText={(text) => {
                if (text.length === 2) {
                  setFieldValue("endTime", text + ":")
                } else {
                  setFieldValue("endTime", text)
                }
              }}
              blurOnSubmit={true}
              underlineColor={palette.purple}
              error={errors.endTime}
              render={() => (
                <TextInputMask
                  style={MATERIAL_INPUT}
                  type={"datetime"}
                  options={{
                    format: "HH:mm",
                  }}
                  // dont forget to set the "value" and "onChangeText" props
                  value={values.endTime}
                  onChangeText={(text) => {
                    setFieldValue("endTime", text)
                  }}
                />
              )}
            />
            <CollapsedError error={errors.endTime} />
            <TimePickerModal
              // locale={'en'} optional, default: automatic

              locale={"de"}
              visible={showEndTimePicker}
              onDismiss={() => setShowEndTimePicker(false)}
              onConfirm={({ hours, minutes }) => {
                let minsindual
                if (minutes < 10) {
                  minsindual = "0" + minutes
                } else {
                  minsindual = minutes
                }
                let hoursindual
                if (hours < 10) {
                  hoursindual = "0" + hours
                } else {
                  hoursindual = hours
                }
                setFieldValue("endTime", hoursindual + ":" + minsindual)
                setShowEndTimePicker(false)
              }}
              label={translate("event.pick.time")} // optional
            />
          </Collapsible>
        </InputScrollView>
      </View>
      <View style={!isWeb && FLEX01}>
        <EventFormBottomButtons
          nextPage={makeTheDateAndNextPage}
          disabled={!valid}
          persistKeyboard={!values.hasEndDate}
        />
      </View>
    </View>
  )
}
