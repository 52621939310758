import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  Dimensions,
  ImageStyle,
  Linking,
  Platform,
  Pressable,
  StatusBar,
  TextStyle,
  View,
  ViewStyle,
} from "react-native"
import { Button, Container, HumanModal, Icon, Screen, Text } from "../../components"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { color, spacing, typography } from "../../theme"
import { useIsFocused, useLinkTo, useNavigation } from "@react-navigation/native"
import { palette } from "../../theme/palette"
import { useStores } from "../../models"
import { AwesomeContainer } from "../../components/awesome-container/awesome-container"
import { translate } from "../../i18n"
import { Chip, IconButton } from "react-native-paper"
import Autolink from "react-native-autolink"
import { Event } from "../../models/event/event"
import { MyLanguage } from "../../i18n/i18n"
import { getStatusBarHeight } from "react-native-status-bar-height"
import Collapsible from "react-native-collapsible"
import { FLEX1, LINK, ROW } from "../../utils/style"
import Bugsnag from "@bugsnag/react-native"
import openMap from "react-native-open-maps"
import usePlatform from "../../hooks/usePlatform"
import { WINDOW_HEIGHT } from "../../utils/device"
import Box from "../../components/box/box"

const ROOT: ViewStyle = {
  backgroundColor: color.palette.white,
  paddingTop: getStatusBarHeight(),
  // flex: 1,
  paddingBottom: 80,
  borderBottomLeftRadius: 80,
}

const TITLE: TextStyle = {
  color: palette.white,
  fontSize: typography.size[6],
  fontWeight: "bold",
  lineHeight: typography.size[7],
}

const DATE: TextStyle = {
  color: palette.white,
  fontSize: 15,
}

const BUTTON_GROUP: ViewStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: spacing[2],
  justifyContent: "center",
  width: "100%",
}

const CONTENT: ViewStyle = {
  paddingLeft: 40,
  padding: spacing[4],
  // width: "100%",
  // flex: 1,
}

const CONTENT_BLOCK: ViewStyle = {
  flexDirection: "row",
  justifyContent: "flex-start",
  marginVertical: spacing[2],
}

const CONTENT_ICON: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  paddingHorizontal: spacing[5],
}

const CONTENT_INFO: ViewStyle = {
  justifyContent: "flex-start",
  flex: 1,
}

const TEXT_BOLD: TextStyle = {
  fontWeight: "bold",
  fontFamily: typography.secondary,
  fontSize: typography.size[2],
  flex: 1,
}
const TEXT_LIGHT: TextStyle = {
  fontWeight: "normal",
  fontFamily: typography.secondary,
  fontSize: typography.size[2],
}

const TEXT_DESC: TextStyle = {
  color: palette.lightGrey,
  fontWeight: "normal",
  fontFamily: typography.secondary,
  fontSize: typography.size[2],
}

export const EventDetailsScreen = observer(function EventDetailsScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Event Details Screen")

  // Pull in one of our MST stores
  const { eventStore, userStore } = useStores()
  const [visibleHumanModal, setVisisbleHumanModal] = useState(false)
  const isFocused = useIsFocused()
  const linkTo = useLinkTo()
  const { isWeb } = usePlatform()

  const nav = useNavigation()

  const goToMap = (query: string) => {
    openMap({ query: query, provider: Platform.OS === "ios" ? "apple" : "google" })
  }

  const goToEvents = () => {
    // nav.navigate("Events")
    linkTo("/events")
  }

  const goToEditEvent = () => {
    // nav.navigate("EditEvent")
    linkTo("/events/edit")
  }

  const showHumanModal = () => {
    if (isFocused && eventStore.currentEvent.user_id !== userStore.me.objectId) {
      userStore.setCurrentUser(eventStore.currentEvent.user_id)
      setVisisbleHumanModal(true)
    }
  }

  const hideHumanModal = () => {
    setVisisbleHumanModal(false)
  }

  const gotoHuman = () => {
    hideHumanModal()
    linkTo("/human")

    // nav.navigate("Human")
  }

  if (!eventStore.currentEvent) nav.goBack()

  const event: Event = eventStore.currentEvent

  useEffect(() => {
    userStore.setCurrentUser(eventStore.currentEvent.user_id)
  }, [eventStore.currentEvent])

  let dateString
  let timeString
  let endString

  if (event.endDate !== null) {
    if (event.startDate.toDateString() !== event.endDate.toDateString()) {
      dateString = `${event.startDate
        .toLocaleDateString(MyLanguage)
        .toUpperCase()} - ${event.endDate.toLocaleDateString(MyLanguage).toUpperCase()}`

      timeString = event.startDate.toLocaleString(MyLanguage, {
        dateStyle: "short",
        timeStyle: "short",
      })

      endString = event.endDate.toLocaleString(MyLanguage, {
        dateStyle: "short",
        timeStyle: "short",
      })
    } else {
      dateString = event.startDate
        .toLocaleDateString(MyLanguage, {
          weekday: "short",
          month: "short",
          day: "numeric",
        })
        .toUpperCase()

      timeString = event.startDate.toLocaleTimeString(MyLanguage, {
        hour: "2-digit",
        minute: "2-digit",
      })
      endString = event.endDate.toLocaleTimeString(MyLanguage, {
        hour: "2-digit",
        minute: "2-digit",
      })
    }
  } else {
    dateString = event.startDate
      .toLocaleDateString(MyLanguage, {
        weekday: "short",
        month: "short",
        day: "numeric",
      })
      .toUpperCase()

    timeString = event.startDate.toLocaleTimeString(MyLanguage, {
      hour: "2-digit",
      minute: "2-digit",
    })
  }

  if (!userStore.currentUser) return null

  return (
    <>
      <Screen style={ROOT} preset="scroll" statusBar="dark-content">
        <Container>
          <AwesomeContainer
            goback={goToEvents}
            imageSource={event.image}
            headerBackgroundColor={palette.paleorange}
            header={
              <View style={[ROW, { justifyContent: "space-between" }]}>
                <View style={FLEX1}>
                  <Text style={DATE}>{dateString}</Text>
                  <View style={ROW}>
                    {event.user_id === userStore.me.objectId && (
                      <IconButton onPress={goToEditEvent} icon="pencil" color={palette.white} />
                    )}
                    <Text style={TITLE}>{event.name}</Text>
                  </View>
                </View>
              </View>
            }
            style={[
              { marginBottom: 100 },
              Platform.OS === "android" && { marginTop: -StatusBar.currentHeight },
            ]}
          >
            <View style={CONTENT}>
              {/* Time */}
              <View style={CONTENT_BLOCK}>
                <View style={CONTENT_ICON}>
                  <Icon name="sunrise" size={24} color={palette.lightGrey} />
                </View>
                <View style={CONTENT_INFO}>
                  <Text style={TEXT_BOLD}>{timeString}</Text>
                </View>
              </View>
              {event.endDate && (
                <View style={CONTENT_BLOCK}>
                  <View style={CONTENT_ICON}>
                    <Icon name="sunset" size={24} color={palette.lightGrey} />
                  </View>
                  <View style={CONTENT_INFO}>
                    <Text style={TEXT_BOLD}>{endString}</Text>
                  </View>
                </View>
              )}

              {/* Place */}
              {event.place_id && (
                <>
                  <Pressable
                    onPress={() => {
                      goToMap(event.place_id.Query)
                    }}
                    style={CONTENT_BLOCK}
                  >
                    <View style={CONTENT_ICON}>
                      <Icon name="map-pin" size={24} color={palette.lightGrey} />
                    </View>
                    <View style={CONTENT_INFO}>
                      <Text style={TEXT_BOLD}>{eventStore.currentEvent.place_id.name}</Text>
                      <Text style={TEXT_LIGHT}>
                        {eventStore.currentEvent.place_id.zipcode}{" "}
                        {eventStore.currentEvent.place_id.city}
                      </Text>
                    </View>
                  </Pressable>
                </>
              )}

              {/* User */}
              {isFocused && eventStore.currentEvent.user_id && (
                <Pressable onPress={showHumanModal}>
                  <View style={CONTENT_BLOCK}>
                    <View style={CONTENT_ICON}>
                      <Icon name="user" size={24} color={palette.lightGrey} />
                    </View>
                    <View style={CONTENT_INFO}>
                      <Text>{translate("event.hostedBy")}</Text>
                      <Text style={TEXT_BOLD}>
                        {userStore.currentUser?.name} {userStore.currentUser?.lastName}
                      </Text>
                    </View>
                  </View>
                </Pressable>
              )}
              {/* Tags */}

              <View style={CONTENT_BLOCK}>
                {eventStore.currentEvent.tags && (
                  <View style={BUTTON_GROUP}>
                    {eventStore.currentEvent.tags.map((tag) => {
                      return (
                        <Chip
                          style={{ marginRight: spacing[1], marginBottom: spacing[1] }}
                          key={tag.id}
                        >
                          <Text tx={"tags." + tag.item} />
                        </Chip>
                      )
                    })}
                  </View>
                )}
              </View>
              <View style={CONTENT_BLOCK}>
                <Autolink text={eventStore.currentEvent.desc} style={TEXT_DESC} linkStyle={LINK} />
              </View>
            </View>
          </AwesomeContainer>
        </Container>
      </Screen>
      <HumanModal
        user={userStore.currentUser}
        visible={visibleHumanModal}
        dismiss={hideHumanModal}
        goto={gotoHuman}
      />
    </>
  )
})
