import { useEffect, useState } from "react"
import { Audio, AVPlaybackStatus } from "expo-av"

export default function useMusic() {
  const [sound, setSound] = useState<Audio.Sound>()
  const [status, setStatus] = useState<AVPlaybackStatus>()

  const onPlaybackStatusUpdate = (playbackStatus: AVPlaybackStatus) => {
    setStatus(playbackStatus)
  }

  async function playMusic(url: string, downloadFirst = false) {
    console.log("Loading Sound")
    const { sound } = await Audio.Sound.createAsync(
      { uri: url },
      { shouldPlay: true },
      onPlaybackStatusUpdate,
      downloadFirst,
    )

    setSound(sound)
  }

  useEffect(() => {
    return sound
      ? () => {
          console.log("Unloading Sound")
          sound.unloadAsync()
        }
      : undefined
  }, [sound])

  async function pauseMusic() {
    sound && (await sound.pauseAsync())
  }

  async function stopMusic() {
    sound && (await sound.stopAsync())
    const status = sound && (await sound.unloadAsync())
    setStatus(status)
  }

  const volume = async (vol) => {
    await sound.setVolumeAsync(vol)
  }

  const togglePlayPause = async () => {
    if (status && status.isLoaded && status.isPlaying) {
      await sound.pauseAsync()
    } else {
      await sound.playAsync()
    }
  }

  const seek = async (pos) => {
    await sound.playFromPositionAsync(pos)
  }

  return {
    volume,
    togglePlayPause,
    pauseMusic,
    playMusic,
    stopMusic,
    seek,
    status,
  }
}
