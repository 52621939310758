import { Platform, TextStyle, ViewStyle } from "react-native"
import { spacing } from "../theme"
import { palette } from "../theme/palette"
import { DEVICE_WIDTH } from "./device"

export const BORDER_RADIUS = 40
export const EVENT_ASPECT_RATIO = 1.765

export const TEXT_CENTER: TextStyle = {
  textAlign: "center",
}

export const LINK: TextStyle = { color: palette.orange, fontWeight: "600" }

export const FLEX1: ViewStyle = {
  flex: 1,
}

export const FLEX09: ViewStyle = { flex: 0.9 }

export const FLEX01: ViewStyle = { flex: 0.1 }

export const ROW: ViewStyle = {
  flexDirection: "row",
}

export const VC: ViewStyle = { alignItems: "center" }
export const VS: ViewStyle = { alignItems: "flex-start" }

export const MB: ViewStyle = {
  marginBottom: spacing[2],
}

export const SHADOW: ViewStyle = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
  width: Platform.OS === "web" ? "100%" : DEVICE_WIDTH + 10,
  marginLeft: Platform.OS === "web" ? 0 : -5,
}

export const ONLY_SHADOW: ViewStyle = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5,
}

export const BLR: ViewStyle = {
  borderBottomLeftRadius: 1.5 * BORDER_RADIUS,
}

export const MATERIAL_INPUT: TextStyle = {
  paddingLeft: 12,
  paddingTop: 26,
  fontSize: 16,
}

export const MATERIAL_FIELD: ViewStyle = {
  padding: spacing[3],
  backgroundColor: palette.textfieldgrey,
  borderBottomColor: palette.textfieldunderlinegrey,
  borderBottomWidth: 1,
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
}
