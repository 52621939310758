/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React, { useEffect } from "react"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { EventNav } from "./event-navigator"
import { RadioNav } from "./radio-navigator"
import { Feather } from "@expo/vector-icons"
import { palette } from "../theme/palette"
import { ProfileNav } from "./profile-navigator"
import { Avatar } from "react-native-paper"
import { useStores } from "../models"
import { Platform } from "react-native"
import { useLinkTo } from "@react-navigation/native"
import usePlatform from "../hooks/usePlatform"
// import { ChatNav } from "./chat-navigator"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type PrimaryParamList = {
  Home: undefined
  Radio: undefined
  Events: undefined
  Chat: undefined
  Profile: undefined
}

// Our main navigation
const Tab = createBottomTabNavigator<PrimaryParamList>()
export function MainNavigator() {
  const rootStore = useStores()
  const linkTo = useLinkTo()
  const { isWeb, isPortrait } = usePlatform()

  // useEffect(() => {
  //   rootStore && rootStore.checkForUpdates()
  // }, [])

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        // eslint-disable-next-line react/display-name
        tabBarIcon: ({ focused, color, size }) => {
          let iconName = null
          if (route.name === "Radio") {
            iconName = focused ? "radio" : "radio"
          } else if (route.name === "Events") {
            iconName = focused ? "calendar" : "calendar"
          } else if (route.name === "Chat") {
            iconName = focused ? "message-circle" : "message-circle"
          } else if (route.name === "Profile") {
            iconName = focused ? "user" : "user"
          }

          if (iconName === "user") {
            if (rootStore.isSignedIn && rootStore.me.avatar) {
              return <Avatar.Image size={30} source={{ uri: rootStore.me.avatar }} />
            } else {
              return <Feather name={iconName} size={size} color={color} />
            }
          } else {
            return <Feather name={iconName} size={size} color={color} />
          }
        },
      })}
      tabBarOptions={{
        activeTintColor: palette.orange,
        inactiveTintColor: palette.darkpurple,
        showLabel: false,
        keyboardHidesTabBar: Platform.select({ ios: false, android: true }),
      }}
      initialRouteName="Radio"
    >
      {/* <Tab.Screen name="Chat" component={ChatNav} options={{ tabBarBadge: 3 }} /> */}
      <Tab.Screen
        name="Events"
        component={EventNav}
        listeners={({ navigation }) => ({
          tabPress: () => {
            linkTo("/events")
          },
        })}
        options={{
          tabBarVisible: Platform.select({ web: !isWeb || isPortrait, default: true }),
        }}
      />

      <Tab.Screen
        name="Radio"
        component={RadioNav}
        listeners={({ navigation }) => ({
          tabPress: () => {
            // navigation.navigate("Radio", { screen: "Radio" })
            linkTo("/radio")
          },
        })}
        options={{ tabBarVisible: Platform.select({ web: !isWeb || isPortrait, default: true }) }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileNav}
        options={{ tabBarVisible: Platform.select({ web: !isWeb || isPortrait, default: true }) }}
      />
    </Tab.Navigator>
  )
}

MainNavigator.displayName = "MainNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["Radio", "Events"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
