import React from "react"
import { observer } from "mobx-react-lite"
import { Image, ImageStyle, TextStyle, ViewStyle } from "react-native"
import { Button, Container, Logo, Screen, Text } from "../../components"
import { spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { useStores } from "../../models"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import usePlatform from "../../hooks/usePlatform"
import { View } from "@dripsy/core"
const ROOT: ViewStyle = {
  flex: 1,
}

const BOLD: TextStyle = { fontWeight: "bold" }

const TITLE: TextStyle = {
  ...BOLD,
  fontSize: typography.size[7],
  lineHeight: 38,
  textAlign: "center",
  color: palette.alphawhite,
  marginBottom: spacing[5],
  paddingHorizontal: spacing[4],
}
const TAGLINE: TextStyle = {
  color: palette.lessalphawhite,
  fontSize: typography.size[4],
  textAlign: "center",
  marginBottom: spacing[4] + spacing[1],
  paddingHorizontal: spacing[6],
}

const LOGO: ViewStyle = {}

const BOTTOM: ViewStyle = {
  width: "100%",
  marginTop: 80,
}

const BUTTON: ViewStyle = {
  marginBottom: spacing[3],
}

const BUTTON_TEXT: TextStyle = {
  lineHeight: 30,
}

export const WelcomeScreen = observer(function WelcomeScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Welcome")

  // Pull in one of our MST stores
  const rootStore = useStores()
  const { isWeb } = usePlatform()

  // Pull in navigation via hook
  const linkTo = useLinkTo()

  const goToLogin = () => {
    // navigation.navigate("Login")
    linkTo("/login")
  }
  const goToRegister = () => {
    // navigation.navigate("Register")
    linkTo("/register")
  }

  // if we are still trying to validate the session show loader
  if (rootStore.state === "pending") return <LoadingScreen />

  return (
    <Screen style={ROOT} preset="scroll">
      <Container gradient menuShown={false}>
        <View sx={{ flex: 3 }}>
          <View sx={{ flex: 3, alignItems: "center", justifyContent: "space-around" }}>
            <View
              sx={{
                flex: isWeb ? 4 : 2,
                alignItems: "center",
                justifyContent: "center",
                padding: "$xxl",
                margin: "$xxl",
              }}
            >
              <Logo />
            </View>
            <View sx={{ flex: 1 }}>
              <Text style={TITLE} preset="header" tx="welcomeScreen.welcome" />
              <Text style={TAGLINE} preset="secondary" tx="welcomeScreen.text" />
            </View>
          </View>
          <View sx={{ flex: 1, py: isWeb && 100, justifyContent: "flex-end" }}>
            <View sx={{ marginBottom: 20 }}>
              <Button preset="primary" onPress={goToLogin} tx="welcomeScreen.login" />
            </View>
            <View sx={{ marginBottom: 20 }}>
              <Button preset="secondary" onPress={goToRegister} tx="welcomeScreen.register" />
            </View>
          </View>
        </View>
      </Container>
    </Screen>
  )
})
