import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ProgramDetailModel } from "../program-detail/program-detail"

/**
 * Timetable Model
 * used by ProgramOverview to show the schedules of radio shows.
 */
export const TimeTableModel = types.model("TimeTable").props({
  time: types.string,
  name: types.string,
  programDetail: types.maybeNull(types.reference(ProgramDetailModel)),
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type TimeTableType = Instance<typeof TimeTableModel>
export interface TimeTable extends TimeTableType {}
type TimeTableSnapshotType = SnapshotOut<typeof TimeTableModel>
export interface TimeTableSnapshot extends TimeTableSnapshotType {}
