import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const TagModel = types.model("Tag").props({
  id: types.string,
  item: types.string,
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type TagType = Instance<typeof TagModel>
export interface Tag extends TagType {}
type TagSnapshotType = SnapshotOut<typeof TagModel>
export interface TagSnapshot extends TagSnapshotType {}
