import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { ChatUserModel } from "../chat-user/chat-user"
import { UserModel } from "../user/user"

/**
 * Model description here for TypeScript hints.
 */
export const ChatRoomMessageModel = types.model("ChatRoomMessage").props({
  _id: types.identifier,
  text: types.string,
  createdAt: types.union(types.Date, types.number),
  user: ChatUserModel,
  image: types.string,
  video: types.string,
  audio: types.string,
})
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ChatRoomMessageType = Instance<typeof ChatRoomMessageModel>
export interface ChatRoomMessage extends ChatRoomMessageType {}
type ChatRoomMessageSnapshotType = SnapshotOut<typeof ChatRoomMessageModel>
export interface ChatRoomMessageSnapshot extends ChatRoomMessageSnapshotType {}
