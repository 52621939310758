import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { simpleHash } from "../../utils/hash"
import { LinkModel } from "../link/link"

/**
 * Model description here for TypeScript hints.
 */
export const RadioStationModel = types
  .model("RadioStation")
  .props({
    objectId: types.identifier,
    index: types.optional(types.number, 0),
    name: types.string,
    language: types.string,
    stream_url: types.string, // the url of the stream
    logo_url: types.optional(types.string, ""),
    radio_id: types.string, // a human readable ID not a reference
    hasPodcasts: types.optional(types.boolean, false),
    about: types.optional(types.string, ""), // text about the Radiostation an d the people behind it
    about_image: types.optional(types.string, ""), // a group Photo
    contact: types.optional(types.array(LinkModel), []),
    humans: types.optional(types.array(LinkModel), []),
  })
  .views((self) => ({
    get RadioStationHash() {
      return simpleHash(self.objectId)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type RadioStationType = Instance<typeof RadioStationModel>
export interface RadioStation extends RadioStationType {}
type RadioStationSnapshotType = SnapshotOut<typeof RadioStationModel>
export interface RadioStationSnapshot extends RadioStationSnapshotType {}
