/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react-native/sort-styles */
import React, { createRef, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  Image,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
  FlatList,
  TouchableOpacity,
  Dimensions,
  ImageBackground,
  ImageStyle,
  SectionList,
  KeyboardAvoidingView,
  ScrollView,
  StatusBar,
  Platform,
  Pressable,
} from "react-native"
import { Container, Header, Icon, Screen, Text } from "../../components"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../../models"
import { palette } from "../../theme/palette"
import { useStores } from "../../models"
import { useLinkTo, useNavigation } from "@react-navigation/native"
import { LinearGradient } from "expo-linear-gradient"
import { spacing, typography } from "../../theme"
import { LoadingScreen } from "../../components/loading-screen/loading-screen"
import Accordion from "react-native-collapsible/Accordion"
import * as Animatable from "react-native-animatable"
import Svg, { Path } from "react-native-svg"
import { BORDER_RADIUS, FLEX1, SHADOW, ONLY_SHADOW } from "../../utils/style"
import { StylePropType } from "react-native-gifted-chat/lib/utils"
import Bugsnag from "@bugsnag/react-native"
import { props } from "ramda"
import { getStatusBarHeight } from "react-native-status-bar-height"
import { offsets } from "../../components/screen/screen.presets"
import usePlatform from "../../hooks/usePlatform"

const SECTION_COVER_IMAGE: ImageStyle = {
  width: "100%",
  height: 100,
  resizeMode: "cover",
}

const ITEM_WRAPPER: ViewStyle = {
  marginVertical: spacing[1],
  marginHorizontal: spacing[2],
  borderRadius: spacing[2],
  overflow: "hidden",
  elevation: 1,
}

const COVER_IMAGE: ViewStyle = {
  height: 150,
  width: "100%",
  borderRadius: spacing[5],
}

const TITLE_OVERLAY: ViewStyle = {
  backgroundColor: palette.alphablack,
  paddingVertical: spacing[2],
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
}

const TITLE: TextStyle = {
  fontSize: typography.size[3],
  color: palette.darkerpurple,
}

const DESC: TextStyle = {
  fontSize: typography.size[2],
  color: palette.orange,
}

const SECTION_TITLE: TextStyle = {
  fontSize: typography.size[5],
  color: palette.morealphawhite,
  padding: spacing[2],
  paddingLeft: 30,
}

const CONTAINER: ViewStyle = {
  backgroundColor: Platform.OS === "web" ? null : palette.white,
  paddingLeft: spacing[3],
  paddingTop: spacing[3],
}

const SWOOSH: ViewStyle = {
  zIndex: 1000,
  marginTop: -79,
  marginLeft: -2,
  alignSelf: "flex-start",
}

const EVENT_ITEM: ViewStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: spacing[3],
}

const EVENT_IMAGE_WRAPPER: ViewStyle = {
  //   width: 150,
  //   height: 150,
  paddingRight: spacing[3],
}
const EVENT_IMAGE: ImageStyle = {
  width: 140,
  // height: 59,
  aspectRatio: 1.765,
  borderRadius: spacing[4],
  resizeMode: "cover",
}

const EVENT_INFO_WRAPPER: ViewStyle = {
  borderTopLeftRadius: spacing[4],
  borderBottomLeftRadius: spacing[4],
  backgroundColor: palette.white,
  paddingVertical: spacing[1],
  paddingLeft: spacing[3],
  paddingRight: 0,
  flexGrow: 2,
  flex: 1,
  justifyContent: "space-evenly",
}

export const PodcastLibraryScreen = observer(function PodcastLibraryScreen() {
  // Bugsnag.leaveBreadcrumb("Screen: Podcast Library")

  // This actually has little to do with podcasts yet.
  // We display a list of all Programs assosiated with the current Radiostation
  // onpress we navigate to a screen showing the podcasts of the Program.

  const { radioStore, programStore, podcastStore } = useStores()

  const [activeSections, setActiveSections] = useState([])
  const nav = useNavigation()
  const linkTo = useLinkTo()

  function gotoCategory(id) {
    programStore.setCurrentProgramDetail(id)
    // nav.navigate("PodcastCategory")
    linkTo("/radio/media/category")
  }

  function setActive(indexes) {
    console.log(indexes)
    setActiveSections(indexes)
  }

  const SectionHeader = (section, index, isActive) => {
    return (
      <Animatable.View
        duration={400}
        style={[styles.header, isActive ? styles.active : styles.inactive, ONLY_SHADOW]}
        transition="backgroundColor"
      >
        <Image style={styles.sectionCover} source={{ uri: section.cover }} />
        <View style={styles.sectionTitleWrapper}>
          <Text style={TITLE}>{section.title}</Text>
          <Icon name={isActive ? "chevron-up" : "chevron-down"} color={palette.darkpurple} />
        </View>
      </Animatable.View>
    )
  }

  const Item = ({ item, onPress }) => (
    <Pressable onPress={onPress} style={CONTAINER}>
      <View style={EVENT_ITEM}>
        <View style={[EVENT_IMAGE_WRAPPER]}>
          <Image style={EVENT_IMAGE} source={{ uri: item.cover }} />
        </View>
        <View style={[EVENT_INFO_WRAPPER, SHADOW]}>
          <Text style={TITLE} numberOfLines={1}>
            {item.title}
          </Text>
          {item.podIntroText ? (
            <Text style={DESC} numberOfLines={2}>
              {item.podIntroText}
            </Text>
          ) : null}
        </View>
      </View>
    </Pressable>
  )

  const SectionItem = (section, isActive) => {
    return (
      <Animatable.View duration={400} transition="backgroundColor">
        {section.data.map((item) => {
          return (
            <Item key={item.objectId} item={item} onPress={() => gotoCategory(item.objectId)} />
          )
        })}
      </Animatable.View>
    )
  }

  const data: any[] = programStore.getSectionedPrograms(radioStore.CurrentRadioStationId).reverse()

  if (!programStore || programStore.state === "pending") {
    // our pretty loading screen
    return <LoadingScreen />
  }

  return (
    <Screen preset="fixed">
      <Container>
        <Accordion
          activeSections={activeSections}
          sections={data}
          touchableComponent={TouchableOpacity}
          expandMultiple={false}
          renderHeader={SectionHeader}
          renderContent={SectionItem}
          duration={400}
          onChange={setActive}
          keyExtractor={(item) => data.indexOf(item)}
          renderAsFlatList
        />
      </Container>
    </Screen>
  )
})

const styles = StyleSheet.create({
  active: {
    elevation: 4,
  },
  inactive: {
    elevation: 1,
  },
  header: {
    backgroundColor: palette.white,
    borderTopLeftRadius: BORDER_RADIUS,
    marginLeft: spacing[2],
    borderBottomLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: Platform.OS === "web" ? BORDER_RADIUS : 0,

    overflow: "visible",
    marginBottom: spacing[2],
  },
  sectionCover: {
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: Platform.OS === "web" ? BORDER_RADIUS : 0,
    height: 160,
    resizeMode: "cover",
    borderBottomLeftRadius: BORDER_RADIUS,
  },
  sectionTitleWrapper: {
    // flex: 2,
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: spacing[5],
    paddingLeft: Platform.OS === "web" ? BORDER_RADIUS : 0,
    flexDirection: "row",
  },
  sectionTitle: {
    fontSize: typography.size[5],
    color: palette.darkpurple,
    padding: spacing[2],
    paddingLeft: 30,
  },
  content: {
    backgroundColor: palette.lessalphawhite,
  },
  currentState: {
    color: palette.purple,
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "left",
    padding: 10,
  },
  title: {
    color: palette.black,
  },
  podItem: {
    padding: spacing[2],
    borderRadius: spacing[2],
    borderColor: palette.purple,
    borderWidth: 1,
    margin: spacing[2],
  },
  podItemTitle: {
    color: palette.darkpurple,
  },
  cover: {
    width: Dimensions.get("window").width,
    height: 70,
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
    marginLeft: spacing[2],
    marginTop: spacing[2],
  },
  name: {
    fontSize: typography.size[5],
    color: palette.alphawhite,
    padding: spacing[2],
    paddingLeft: 80,
  },
  desc: {
    color: palette.alphawhite,
    padding: spacing[2],
    paddingLeft: 80,
  },
})
