import { ViewStyle, TextStyle } from "react-native"
import { color, spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"

/**
 * All text will start off looking like this.
 */
const BASE_VIEW: ViewStyle = {
  paddingVertical: spacing[2],
  paddingHorizontal: spacing[2],
  borderRadius: 4,
  justifyContent: "center",
  alignItems: "center",
}

const BASE_TEXT: TextStyle = {
  paddingHorizontal: spacing[3],
}

/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const viewPresets = {
  /**
   * A smaller piece of secondard information.
   */
  primary: {
    ...BASE_VIEW,
    backgroundColor: palette.orange,
    borderRadius: 100,
    marginLeft: spacing[3],
    marginRight: spacing[3],
  } as ViewStyle,

  secondary: {
    ...BASE_VIEW,
    backgroundColor: palette.purple,
    borderRadius: 100,
    marginLeft: spacing[3],
    marginRight: spacing[3],
  } as ViewStyle,

  danger: {
    ...BASE_VIEW,
    backgroundColor: palette.angry,
    borderRadius: 100,
    marginLeft: spacing[3],
    marginRight: spacing[3],
  } as ViewStyle,

  secondaryOutline: {
    ...BASE_VIEW,
    borderColor: palette.purple,
    borderWidth: 3,
    borderRadius: 100,
    marginLeft: spacing[3],
    marginRight: spacing[3],
  } as ViewStyle,

  pale: {
    ...BASE_VIEW,
    marginLeft: spacing[3],
    marginRight: spacing[3],
  } as ViewStyle,

  webHeader: {
    ...BASE_VIEW,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginHorizontal: 5,
    borderRadius: 20,
    width: 40,
    height: 40,
    alignItems: "center",
  } as ViewStyle,

  /**
   * A button without extras.
   */
  link: {
    ...BASE_VIEW,
    paddingHorizontal: 0,
    paddingVertical: 0,
    alignItems: "flex-start",
  } as ViewStyle,
}

export const textPresets = {
  primary: { ...BASE_TEXT, fontSize: typography.size[4], color: palette.white } as TextStyle,
  secondary: { ...BASE_TEXT, fontSize: typography.size[4], color: palette.white } as TextStyle,

  link: {
    ...BASE_TEXT,
    color: color.text,
    paddingHorizontal: 0,
    paddingVertical: 0,
  } as TextStyle,

  pale: {
    fontSize: typography.size[3],
    color: color.secondary,
  },
}

/**
 * A list of preset names.
 */
export type ButtonPresetNames = keyof typeof viewPresets
