import React, { useEffect } from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { ProfileScreen } from "../screens/profile/profile-screen"
import { ProfileEditScreen } from "../screens/profile-edit/profile-edit-screen"
import { EventEditScreen } from "../screens/event-edit/event-edit-screen"

const Stack = createStackNavigator()

export function ProfileNav() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen options={{ headerShown: false }} name="Profile" component={ProfileScreen} />
      <Stack.Screen
        options={{ headerShown: false }}
        name="EditProfile"
        component={ProfileEditScreen}
      />
      <Stack.Screen options={{ headerShown: false }} name="EditEvent" component={EventEditScreen} />
    </Stack.Navigator>
  )
}
