import React from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { EventsScreen } from "../screens/events/events-screen"
import { EventDetailsScreen } from "../screens/event-details/event-details-screen"
import { EventCreateScreen } from "../screens/event-create/event-create-screen"
import { PlaceCreateScreen } from "../screens/place-create/place-create-screen"
import { EventEditScreen } from "../screens/event-edit/event-edit-screen"
import { HumanScreen } from "../screens/human/human-screen"

const Stack = createStackNavigator()

export function EventNav() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen options={{ headerShown: false }} name="Events" component={EventsScreen} />
      <Stack.Screen
        options={{ headerShown: false }}
        name="Details"
        component={EventDetailsScreen}
      />
      <Stack.Screen
        options={{ headerShown: false }}
        name="CreateEvent"
        component={EventCreateScreen}
      />
      <Stack.Screen options={{ headerShown: false }} name="EditEvent" component={EventEditScreen} />
      <Stack.Screen
        options={{ headerShown: false }}
        name="CreatePlace"
        component={PlaceCreateScreen}
      />
      <Stack.Screen options={{ headerShown: false }} name="Human" component={HumanScreen} />
    </Stack.Navigator>
  )
}
