import * as React from "react"
import { translate } from "../../../i18n"
import { TextInput } from "react-native-paper"
import { spacing } from "../../../theme"
import { FLEX01, FLEX09, FLEX1 } from "../../../utils/style"
import { Platform, View, ViewStyle } from "react-native"
import InputScrollView from "react-native-input-scroll-view"
import { EventFormBottomButtons } from "../../event-form-bottom-buttons/event-form-bottom-buttons"
import { CollapsedError } from "../../collapsed-error/CollapsedError"
import { FormPageProps } from "../../form-page/form-page"
import { EventFormValues } from "../event-form"
import usePlatform from "../../../hooks/usePlatform"

export const Page3: React.FC<FormPageProps<EventFormValues>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
  previousPage,
  nextPage,
}) => {
  const [valid, setValid] = React.useState(false)
  const { isWeb } = usePlatform()
  console.log("Page 3")
  React.useEffect(() => {
    if (errors.desc) {
      setValid(false)
    } else {
      setValid(true)
    }
  }, [values, errors])

  return (
    <View style={[{ margin: spacing[4] }, !isWeb && FLEX1]}>
      <View style={!isWeb && FLEX09}>
        {Platform.OS === "web" ? (
          <TextInput
            placeholder={translate("event.desc")}
            // eslint-disable-next-line react-native/no-inline-styles
            value={values.desc}
            onChangeText={(text) => setFieldValue("desc", text)}
            // onBlur={handleBlur("desc")}
            returnKeyType="done"
            multiline
            numberOfLines={10}
          />
        ) : (
          <InputScrollView keyboardShouldPersistTaps="always">
            <TextInput
              placeholder={translate("event.desc")}
              // eslint-disable-next-line react-native/no-inline-styles
              value={values.desc}
              onChangeText={(text) => setFieldValue("desc", text)}
              // onBlur={handleBlur("desc")}
              returnKeyType="done"
              multiline
              numberOfLines={10}
            />
          </InputScrollView>
        )}
        <CollapsedError error={touched.desc && errors.desc} />
      </View>
      <View style={!isWeb && FLEX01}>
        <EventFormBottomButtons
          nextPage={nextPage}
          previousPage={previousPage}
          disabled={!valid}
          persistKeyboard
        />
      </View>
    </View>
  )
}
