import React from "react"

export const apiStates = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
}

const { REVENUECAT_API_KEY } = require("../config/env")

export const usePurchasesApi = (uri) => {
  const url = "https://api.revenuecat.com/v1/" + uri
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: REVENUECAT_API_KEY,
    "X-Platform": "stripe",
  }
  const [data, setData] = React.useState({
    state: apiStates.LOADING,
    error: "",
    data: [],
  })

  const setPartData = (partialData) => setData({ ...data, ...partialData })

  React.useEffect(() => {
    setPartData({
      state: apiStates.LOADING,
    })
    const fetchFromRevenueCat = async () => {
      try {
        const response = await fetch(url, { headers })
        const result = await response.json()
        console.log(result)
        setPartData({
          state: apiStates.SUCCESS,
          data: result,
        })
      } catch (e) {
        setPartData({
          state: apiStates.ERROR,
          error: e.message,
        })
      }
    }
    fetchFromRevenueCat()
  }, [])

  const setAttributes = async (key: string, value: string) => {
    try {
      const response = await fetch(url + "/attributes", {
        method: "POST",
        headers,
        body: JSON.stringify({ attributes: { [key]: { value: value } } }),
      })
      const result = await response.json()
      console.log(result)
    } catch (e) {
      console.log(e)
    }
  }

  return { data, setAttributes }
}
