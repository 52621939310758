import { flow, Instance, SnapshotOut, types } from "mobx-state-tree"
import { GetGeneralResults, ParseApi } from "../../services/api"
import { withEnvironment } from "../extensions/with-environment"
import { Meta, MetaModel } from "../meta/meta"

/**
 * Model description here for TypeScript hints.
 */
export const MetaStoreModel = types
  .model("MetaStore")
  .props({
    meta: types.optional(types.array(MetaModel), []),
    state: types.enumeration("State", ["pending", "done", "error"]),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .extend(withEnvironment)
  .actions((self) => ({
    load: flow(function* load() {
      self.state = "pending"
      console.log("loading Meta...")
      try {
        const parseApi = new ParseApi()
        const result: GetGeneralResults = yield parseApi.getAllOfClass("Meta")

        if (result.kind === "ok") {
          if (result.data.results.length > 0) {
            self.meta = result.data.results.map((meta) => {
              return {
                locale: meta.locale,
                vision: meta.vision || "",
                about: meta.about || "",
                support: meta.support || "",
                timeline: meta.timeline || "",
                privacy: meta.privacy || "",
                faq: meta.support_json || [],
              }
            }) as any
          } else {
            console.log("No Meta found")
          }
        }
        self.state = "done"
        console.log("Done Loading Meta")
      } catch (error) {
        console.log("Failed to load Meta:", error)
        self.state = "error"
      }
    }),
    afterCreate() {
      this.load()
      self.state = "done"
    },
    getMeta(locale: string): Meta {
      return self.meta.find((meta) => meta.locale === locale)
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type MetaStoreType = Instance<typeof MetaStoreModel>
export interface MetaStore extends MetaStoreType {}
type MetaStoreSnapshotType = SnapshotOut<typeof MetaStoreModel>
export interface MetaStoreSnapshot extends MetaStoreSnapshotType {}
