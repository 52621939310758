import * as ImagePicker from "expo-image-picker"
import { Platform } from "react-native"

export async function cloudinaryUpload(photo, preset) {
  try {
    const data = new FormData()
    data.append("file", Platform.OS === "web" ? photo.uri : photo)
    data.append("upload_preset", preset)
    data.append("cloud_name", "universalapp")
    const response = await fetch("https://api.cloudinary.com/v1_1/universalapp/image/upload", {
      method: "post",
      body: data,
    })
    const result = await response.json()
    return result.secure_url
  } catch (error) {
    console.log("An Error Occured While Uploading: ", error)
    return error
  }
}

export const pickImage = async (handleChange, a?, b?) => {
  if (!a) {
    a = 1
  }
  if (!b) {
    b = 1
  }
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [a, b],
    quality: 1,
  })

  if (!result.cancelled) {
    console.log(result)
    const uri = result.uri
    const type = result.type.concat("/jpeg")
    const name = Math.random().toString(36).substr(2, 12)
    const source = {
      uri,
      type,
      name,
    }
    console.log(source)
    cloudinaryUpload(source, "ogbxkyjd")
      .then((url) => handleChange(url))
      .catch((e) => console.log(e))
  }
}
