import React, { useEffect } from "react"
import { Pressable, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing, typography } from "../../theme"
import { Text } from "../"
import { Button } from "../button/button"
import { Icon } from "../icon/icon"
import { palette } from "../../theme/palette"
import { TouchableOpacity } from "react-native-gesture-handler"

const CONTAINER: ViewStyle = {
  justifyContent: "center",
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 14,
  color: color.primary,
}

const TAG_BUTTON: ViewStyle = {
  flexDirection: "row",
  padding: spacing[2],
  backgroundColor: palette.lighterGrey,
  borderRadius: spacing[5],
  margin: spacing[1],
}

const BUTTON_GROUP: ViewStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: spacing[2],
  justifyContent: "center",
}

const SELECTED: ViewStyle = {
  backgroundColor: palette.purple,
}

const BUTTON_TEXT: TextStyle = {
  fontSize: typography.size[2],
}

export interface TagButtonProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: ViewStyle
  tags: { id: string; item: string }[]
  setFieldValue?: any
  onChange?: any
  values?
  handleSelected?: any
  selected: { id: string; item: string }[]
}

/**
 * Describe your component here
 */
export const TagButton = observer(function TagButton(props: TagButtonProps) {
  const { style, tags, selected, onChange } = props

  const [selectedTags, setSelectedTags] = React.useState(selected)

  const select = (tag) => {
    setSelectedTags([{ id: tag.id, item: tag.item }, ...selectedTags])
    // setFieldValue("tags", [{ id: tag.id, item: tag.item }, ...selected])
  }

  const deselect = (tag) => {
    setSelectedTags(selectedTags.filter((entry) => entry.id !== tag.id))
    // setFieldValue(
    //   "tags",
    //   selected.filter((entry) => entry.id !== tag.id),
    // )
  }

  useEffect(() => {
    onChange(selectedTags)
  }, [selectedTags])

  const toggleSelect = (tag) => {
    selectedTags.find((entry) => entry.id === tag.id) ? deselect(tag) : select(tag)
  }

  if (!selectedTags) return null

  return (
    <View style={BUTTON_GROUP}>
      {tags.map((tag) => {
        return (
          <Pressable key={tag.id} onPress={() => toggleSelect(tag)}>
            <View
              style={[TAG_BUTTON, selectedTags.find((entry) => entry.id === tag.id) && SELECTED]}
            >
              <Text style={[BUTTON_TEXT, { color: palette.white }]} tx={"tags." + tag.item} />
            </View>
          </Pressable>
        )
      })}
    </View>
  )
})
