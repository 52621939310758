import { ImageStyle, TextStyle, ViewStyle } from "react-native"
import { color, spacing, typography } from "../../theme"
import { palette } from "../../theme/palette"

export const TEXTFIELD_WRAP: ViewStyle = {
  borderBottomColor: palette.darkpurple,
  borderBottomWidth: 1,
  // padding: spacing[1],
  marginVertical: spacing[1],
  marginHorizontal: spacing[2],
}

export const TEXTFIELD: TextStyle = {
  fontSize: typography.size[3],
}

export const PLACE_NAME: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[3],
  color: color.primary,
}
export const PLACE_ADDRESS: TextStyle = {
  fontFamily: typography.primary,
  fontSize: typography.size[2],
  color: color.primary,
}

export const PLACE_ENTRY: ViewStyle = {
  flexDirection: "row",
  borderRadius: spacing[2],
  marginVertical: spacing[1],
  padding: spacing[1],
  backgroundColor: palette.lighterGrey,
}

export const PLACE_AVATAR: ViewStyle = {
  justifyContent: "center",
  alignItems: "center",
  marginVertical: spacing[1],
  padding: spacing[1],
  marginHorizontal: spacing[2],
}

export const PLACE_IMAGE: ImageStyle = {
  borderRadius: spacing[1],
  width: 55,
  height: 55,
  aspectRatio: 1,
}

export const RESET_BUTTON: ViewStyle = {
  marginLeft: "auto",
  justifyContent: "center",
  alignItems: "center",
}

export const SELECTED: ViewStyle = {
  backgroundColor: palette.paleorange,
}
