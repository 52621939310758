export default [
  {
    platform_product_id: "prod_JJuXNcpFcaelHf",
    platform: "stripe",
    degree: "earth",
    interval: "month",
  },
  {
    platform_product_id: "g_universalapp_abo_earth",
    platform: "android",
    degree: "earth",
    interval: "month",
  },
  {
    platform_product_id: "universalapp_abo_earth",
    platform: "ios",
    degree: "earth",
    interval: "month",
  },
  {
    platform_product_id: "prod_JJuYiMwbZtjsOY",
    platform: "stripe",
    degree: "sun",
    interval: "month",
  },
  {
    platform_product_id: "g_universalapp_abo_sun",
    platform: "android",
    degree: "sun",
    interval: "month",
  },
  {
    platform_product_id: "universalapp_abo_sun",
    platform: "ios",
    degree: "sun",
    interval: "month",
  },
  {
    platform_product_id: "prod_JJuZxrAKHL2WeO",
    platform: "stripe",
    degree: "universal",
    interval: "month",
  },
  {
    platform_product_id: "g_universalapp_abo_universal",
    platform: "android",
    degree: "universal",
    interval: "month",
  },
  {
    platform_product_id: "universalapp_abo_universal",
    platform: "ios",
    degree: "universal",
    interval: "month",
  },
  {
    platform_product_id: "prod_JJuZRDx01RtymZ",
    platform: "stripe",
    degree: "universal",
    interval: "year",
  },
  {
    platform_product_id: "g_universalapp_abo_universal_year",
    platform: "android",
    degree: "universal",
    interval: "year",
  },
  {
    platform_product_id: "universalapp_abo_universal_year",
    platform: "ios",
    degree: "universal",
    interval: "year",
  },
  {
    platform_product_id: "prod_JJuYialXgzf3PO",
    platform: "stripe",
    degree: "sun",
    interval: "year",
  },
  {
    platform_product_id: "g_universalapp_abo_sun_year",
    platform: "android",
    degree: "sun",
    interval: "year",
  },
  {
    platform_product_id: "universalapp_abo_sun_year",
    platform: "ios",
    degree: "sun",
    interval: "year",
  },
  {
    platform_product_id: "prod_JJuYUR3UlX7qxC",
    platform: "stripe",
    degree: "earth",
    interval: "year",
  },
  {
    platform_product_id: "g_universalapp_abo_earth_year",
    platform: "android",
    degree: "earth",
    interval: "year",
  },
  {
    platform_product_id: "universalapp_abo_earth_year",
    platform: "ios",
    degree: "earth",
    interval: "year",
  },
]
