import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const GeoPointModel = types
  .model("GeoPoint")
  .props({
    __type: "GeoPoint",
    latitude: types.number,
    longitude: types.number,
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type GeoPointType = Instance<typeof GeoPointModel>
export interface GeoPoint extends GeoPointType {}
type GeoPointSnapshotType = SnapshotOut<typeof GeoPointModel>
export interface GeoPointSnapshot extends GeoPointSnapshotType {}
